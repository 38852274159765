import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn } from '../custom-topic-routes'

export const getBusinessRouteInfo: GetMainSectionFn = (sectionBuilder) => {
    return [
        layout.component({
            type: 'business-developing-news',
            props: {
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'business-developing-news',
                    offset: 0,
                    pageSize: 8,
                },
            },
        }),
        ...sectionBuilder.getCuratedSection({
            lima: {
                dataDefinitionArgs: {
                    type: 'curation',
                    name: 'business',
                    offset: 2,
                    pageSize: 9,
                },

                cardLayout: [
                    ['teaserWithBackground'],
                    ['xsImage-mdNoImage-withBG', 'xsImage-mdNoImage-withBG'],
                    ['teaserWithBackground'],
                    { type: 'november', listLength: 5, minimumCards: 5 },
                ],
            },
        }),
        layout.nestedComposition({
            type: 'thor',
            feature: 'the-west-cnbc',
            props: {
                hasBackgroundFill: true,
                containerWidth: '100%',
                verticalGutters: false,
                horizontalGutters: true,
                themeOverride: 'cnbc',
                verticalSpacing: 'xl',
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'lima',
                        props: {
                            cardOrientation: {
                                type: 'Landscape-Portrait',
                            },
                            cardLayout: [
                                ['teaserWithBackground'],
                                ['teaserWithBackground'],
                                ['teaserWithBackground'],
                                ['teaserWithBackground'],
                            ],
                            intermediateColumns: 2,
                            finalColumns: 4,
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['business/cnbc'],
                                includeSubTopics: false,
                                paging: {
                                    page: 1,
                                    pageSize: 4,
                                },
                            },
                            hasBorder: false,
                        },
                    }),
                ],
            },
        }),
        layout.nestedComposition({
            type: 'thor',
            feature: 'the-west-cnbc',
            props: {
                hasBackgroundFill: true,
                containerWidth: '100%',
                verticalGutters: false,
                horizontalGutters: true,
                themeOverride: 'the-economist',
                verticalSpacing: 'xl',
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'lima',
                        props: {
                            cardOrientation: {
                                type: 'Landscape-Portrait',
                            },
                            cardLayout: [
                                ['teaserWithBackground'],
                                ['teaserWithBackground'],
                                ['teaserWithBackground'],
                                ['teaserWithBackground'],
                            ],
                            intermediateColumns: 2,
                            finalColumns: 4,
                            dataDefinitionArgs: {
                                type: 'listing',
                                topics: ['business/the-economist'],
                                includeSubTopics: false,
                                paging: {
                                    page: 1,
                                    pageSize: 4,
                                },
                            },
                            hasBorder: false,
                        },
                    }),
                ],
            },
        }),

        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Markets',
                    headingUrl: '/business/markets',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/markets'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Commercial Property',
                    headingUrl: '/business/commercial-property',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/commercial-property'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Mining',
                    headingUrl: '/business/mining',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/mining'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Energy',
                    headingUrl: '/business/energy',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/energy'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Your Money',
                    headingUrl: '/business/your-money',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/your-money'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Agriculture',
                    headingUrl: '/business/agriculture',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/agriculture'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                // The page isn't sponsored, but the collection is
                isSponsoredCollection: true,
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Bulls N’ Bears',
                    headingUrl: '/business/bulls-n-bears',
                    sponsor: 'bullsnbears',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/bulls-n-bears'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Events',
                    headingUrl: '/business/west-business-events',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['business/west-business-events'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
    ]
}
