import {
    AllEvents,
    EntitledToView,
    PublicationType,
    RequiredAccess,
} from '@news-mono/web-common'
import H from 'history'
import React from 'react'
import { Logger } from 'typescript-log'
import { createIsEntitledChangeHandler } from './createIsEntitledChangeHandler'
import { StyledFeatureArticleBreachNotification } from './FeatureArticleBreach.styled'
import { LazyBreachWallContentWrapper } from './components/BreachWallContent/BreachWallContentWrapper.lazy'

export interface FeatureArticleBreachProps {
    requiredAccess: RequiredAccess
    location: H.Location
    commonElements: React.ReactElement<any>
    comments?: React.ReactElement<any>
    onEvent: (event: AllEvents) => void
    article: PublicationType
    log: Logger
    forceLoad?: boolean
}

export const FeatureArticleBreach: React.FC<FeatureArticleBreachProps> = (
    props,
) => {
    const {
        commonElements,
        comments,
        requiredAccess,
        location,
        onEvent,
        article,
        log,
        forceLoad,
    } = props

    return (
        <EntitledToView
            requiredAccess={requiredAccess}
            articleSlug={article.slug}
            onIsEntitledChange={createIsEntitledChangeHandler(
                location,
                onEvent,
                article,
            )}
            entitled={() => {
                return (
                    <div>
                        {commonElements}
                        {props.children}
                        {comments}
                    </div>
                )
            }}
            notEntitled={() => {
                return (
                    <div>
                        {commonElements}
                        <StyledFeatureArticleBreachNotification>
                            <LazyBreachWallContentWrapper
                                publication={article}
                                requiredAccess={requiredAccess.level}
                                location={location}
                                onEvent={onEvent}
                                log={log}
                                forceLoad={forceLoad}
                            />
                        </StyledFeatureArticleBreachNotification>
                        {comments}
                    </div>
                )
            }}
        />
    )
}

FeatureArticleBreach.displayName = 'FeatureArticleBreach'
