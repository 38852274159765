import styled from '@emotion/styled'
import { metrics, ThemeMargins } from '../../__styling/settings/metrics'
import {
    breakpoint,
    breakpointMax,
    calcRem,
} from '../../__styling/style-functions'
import {
    horizontalDividers,
    verticalDividers,
} from '../../__styling/style-mixins'
import { SierraLayouts } from './Sierra'

export interface GridProps {
    verticalSpacing: keyof ThemeMargins | undefined
    cardLayout: SierraLayouts
    hasCollectionBorderBottom?: boolean
    gridGap?: number
}

export const StyledSierra = styled('div')<GridProps>(
    ({
        cardLayout,
        theme,
        verticalSpacing,
        hasCollectionBorderBottom,
        gridGap,
    }) => [
        {
            margin: '0 auto',
            width: '100%',
            position: 'relative',
            display: 'grid',
            gridGap: gridGap ? calcRem(gridGap) : theme.margins.gridGap,
            gridTemplateColumns: '100%',
            marginBottom: calcRem(theme.margins.gridGap / 2),
            paddingBottom:
                theme.kind === 'thewest'
                    ? metrics.thewest.margins.sm
                    : undefined,
            [breakpoint('sm')]: {
                marginBottom: verticalSpacing && theme.margins[verticalSpacing],
            },
            [breakpoint('md')]: {
                // For 2 items it's a 4 column grid, for 3 items it's a 5 column grid.
                gridTemplateColumns: `repeat(${cardLayout.length + 2}, 1fr)`,
            },
        },
        theme.kind === 'thewest' && {
            [breakpoint('md')]: {
                // Hacky work around to remove the vertical divider appearing out of place when using November
                '& >*:not(:first-child):not(.november-layout)::after': {
                    ...verticalDividers,
                    backgroundColor: theme.colors.borders.primary,
                },

                // '& >*:not(:first-child)::after': {
                //     ...verticalDividers,
                //     backgroundColor: theme.colors.borders.primary,
                // },
            },
        },
        theme.kind === 'thewest' &&
            hasCollectionBorderBottom && {
                paddingBottom: metrics.thewest.margins.md,
                borderBottom: `4px double ${theme.colors.borders.secondary}`,
            },
    ],
)
export const StyledHeader = styled('div')((props) => ({
    display: 'block',
    marginBottom: props.theme.margins.md,
}))

interface SierraCardContainerProps {
    cardLayout: SierraLayouts
    hasPortraitBorder?: boolean
    hasCollectionBorderBottom?: boolean
}

export const SierraCardContainer = styled('div')<SierraCardContainerProps>(
    ({ hasCollectionBorderBottom, theme }) => ({
        width: '100%',

        marginBottom: 0,

        [breakpoint('md')]: {
            '&.heroCardContainer': {
                width: 'auto',
                marginRight: 0,
                gridColumnStart: 'span 3',
            },

            '&.portraitCardContainer': {
                gridColumnStart: 'span 1',
                width: 'auto',
                marginRight: 0,
                borderBottom: hasCollectionBorderBottom
                    ? undefined
                    : `1px solid ${theme.colors.borders.primary}`,

                '+ .portraitCardContainer': {
                    marginLeft: 0,
                },
            },
        },
    }),

    ({ theme }) =>
        theme.kind === 'thewest' && {
            marginBottom: 0,
            position: 'relative',
        },
    ({ theme, hasCollectionBorderBottom }) =>
        theme.kind === 'thewest' && hasCollectionBorderBottom
            ? {
                  [breakpointMax('md')]: {
                      '&:not(:last-child)::before': {
                          ...horizontalDividers,
                          backgroundColor: theme.colors.borders.primary,
                      },
                  },
              }
            : {
                  // Horizontal dividers only on small viewports for bottom of columns
                  [breakpointMax('md')]: {
                      '&::before': {
                          ...horizontalDividers,
                          backgroundColor: theme.colors.borders.primary,
                      },
                  },
              },
)
