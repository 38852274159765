import {
    ConfigurationContext,
    ElectionDefinition,
    queries,
    useFeature,
} from '@news-mono/web-common'
import { useQuery } from '@tanstack/react-query'
import React, { useContext, useMemo } from 'react'
import { useLocation } from 'react-router'
import { FederalElection2025Logo } from '../../../content/FederalElection2025/Logo'
import { WAStateElection2025Logo } from '../../../content/WAStateElection2025/Logo'
import { ElectionTitleButton } from '../components'
import { SeatData, getSeatsData, transformAreaData } from '../data'
import { SeatCard } from '../SeatCard/SeatCard'
import {
    TheSeatEmbedWidgetContainer,
    TheSeatEmbedWidgetTitleContainer,
} from './TheSeatEmbedWidget.styled'

export interface TheSeatEmbedWidgetProps {
    electionDefinition: ElectionDefinition
    mainRef?: React.RefObject<HTMLDivElement>
    fireClickEvent?: () => void
    electorateName?: string | null
    providedData?: SeatData
    isState?: boolean
    imageBaseUrl?: string
}

export const TheSeatEmbedWidget = ({
    electionDefinition,
    mainRef,
    electorateName,
    isState,
    providedData,
    fireClickEvent,
    imageBaseUrl,
}: TheSeatEmbedWidgetProps) => {
    const electionEmbedsEnabled = useFeature('custom-election-embeds')

    const waElectoratesPageEnabled = useFeature(
        'wa-election-electorate-results-route',
    )
    const federalElectoratesPageEnabled = useFeature(
        'federal-election-electorate-results-route',
    )

    const areElectorateRoutesEnabled =
        waElectoratesPageEnabled || federalElectoratesPageEnabled

    const config = useContext(ConfigurationContext)
    const result = useQuery(
        queries['election-api'].definition({
            initialDefinition: electionDefinition,
            electionAPI: config.electionApi,
            caller: config.apiCallerHeader,
        }),
    )

    const { search } = useLocation()
    const params = new URLSearchParams(search)

    // Check if the current URL contains 'state' or 'federal' to determine logo to use
    if (isState === undefined) {
        isState = search.includes('state')
    }

    // no name or data is provided, so assume it's not an embed, and we want to provide the actual
    // name from the provided url.
    if (!electorateName && !providedData) {
        electorateName = params.get('electorate')
    }

    // Calculate seatData.
    const seatData = useMemo(() => {
        // Check against the loaded data now!
        if (result.isSuccess && result.data.electionData.data) {
            const interknowlogyData = transformAreaData(
                result.data.electionData.data,
            )
            const seats = getSeatsData(interknowlogyData, imageBaseUrl, 64)

            return seats.find(
                (seat) =>
                    seat.seatName.toLowerCase() ===
                    electorateName?.toLowerCase(),
            )
        }
    }, [
        imageBaseUrl,
        electorateName,
        result.isSuccess,
        result.data?.electionData.data,
    ])

    const ElectionTitleLogo = isState
        ? WAStateElection2025Logo
        : FederalElection2025Logo

    const electionId = result.isSuccess ? result.data.electionId : undefined

    const ViewElectoratesButton = ({
        desktopButton,
        seatName,
        electionId,
    }: {
        desktopButton: boolean
        seatName: string | undefined
        electionId: string
    }) => (
        <ElectionTitleButton
            to={
                seatName
                    ? `/politics/${electionId}/electorates?search=${seatName.toLowerCase()}&display=expanded`
                    : `/politics/${electionId}/electorates`
            }
            includeBorder={true}
            hideOnMobile={desktopButton}
            hideOnDesktop={!desktopButton}
            fireEvent={fireClickEvent}
            text={'View Electorates'}
        />
    )

    return electionEmbedsEnabled ? (
        <TheSeatEmbedWidgetContainer ref={mainRef}>
            <TheSeatEmbedWidgetTitleContainer>
                <ElectionTitleLogo
                    size="small"
                    fireClickEvent={fireClickEvent}
                />
                {areElectorateRoutesEnabled && electionId && (
                    <ViewElectoratesButton
                        desktopButton
                        seatName={seatData?.seatName}
                        electionId={electionId}
                    />
                )}
            </TheSeatEmbedWidgetTitleContainer>
            {seatData ? (
                <>
                    <SeatCard isEmbed seat={seatData} />
                </>
            ) : (
                <SeatCard isLoading isEmbed />
            )}
            {areElectorateRoutesEnabled && electionId && (
                <ViewElectoratesButton
                    desktopButton={false}
                    seatName={seatData?.seatName}
                    electionId={electionId}
                />
            )}
        </TheSeatEmbedWidgetContainer>
    ) : null
}
