import { calcRem } from '@news-mono/component-library'
import { layout } from '../../../App.routing'
import { billboardLeaderboardMrec } from '../../ad-units'
import { GetMainSectionFn, TopicSectionBuilder } from '../custom-topic-routes'
import {
    ElectionDefinition,
    getElectionFeatureValue,
    isElectionFeatureEnabled,
    TogglesReduxState,
} from '@news-mono/web-common'
import { toFeatureState } from '@etrigan/feature-toggles-client'
import { getUnloadedElectionDefinition } from '../../elections/routes'

const buildElectionCurationCollectionSegment = (
    showNavigation: boolean,
    showHeadToHead: boolean,
    electionDefinition: ElectionDefinition,
) => {
    return [
        ...(showHeadToHead
            ? [
                  layout.nestedComposition({
                      type: 'box',
                      props: {
                          verticalSpacing: 'md',
                      },
                      contentAreas: {
                          main: [
                              layout.component({
                                  type: 'election-head-to-head-seat-count-widget',
                                  props: {
                                      electionDefinition,
                                  },
                              }),
                          ],
                      },
                  }),
              ]
            : []),
        ...(showNavigation
            ? [
                  layout.nestedComposition({
                      type: 'box',
                      props: {
                          verticalSpacing: 'md',
                          breakpoints: {
                              lg: {
                                  verticalSpacing: 'xxxl',
                              },
                          },
                      },
                      contentAreas: {
                          main: [
                              layout.component({
                                  type: 'election-navigation',
                                  props: {
                                      electionDefinition,
                                  },
                              }),
                          ],
                      },
                  }),
              ]
            : []),
        layout.nestedComposition({
            type: 'box',
            props: {
                verticalSpacing: 'md',
                breakpoints: {
                    lg: {
                        verticalSpacing: 'xxxl',
                    },
                },
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'sierra',
                        props: {
                            cardLayout: [
                                'the-west-hero',
                                {
                                    type: 'november',
                                    listLength: 4,
                                    hasBackground: false, //So that the border appears on the last item ¯\_(ツ)_/¯
                                    paddingOverride: calcRem(16, 0, 0, 0),
                                },
                            ],
                            isLarge: true,
                            heroBottomPadding: 8,
                            dataDefinitionArgs: {
                                type: 'curation',
                                name: electionDefinition.electionId,
                                pageSize: 5,
                                offset: 0,
                            },
                            gridGap: 24,
                            breakpoints: {
                                lg: {
                                    gridGap: 32,
                                },
                            },
                        },
                    }),
                ],
            },
        }),
    ]
}

const buildCuratedSection = (
    sectionBuilder: TopicSectionBuilder,
    electionDefinition: ElectionDefinition,
    toggles?: TogglesReduxState,
) => {
    const politicsSection = sectionBuilder.getCuratedSection()
    if (!toggles) return politicsSection

    const isElectionCollectionEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-politics-collection',
    )
    const showNavigation = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-navigation',
    )
    const showHeadToHead = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-section-head-to-head',
    )
    const electionCollectionPosition = getElectionFeatureValue(
        toggles,
        electionDefinition.electionId,
        'election-politics-collection-position',
    )

    if (isElectionCollectionEnabled) {
        const curationCollection = buildElectionCurationCollectionSegment(
            showNavigation as boolean,
            showHeadToHead as boolean,
            electionDefinition,
        )
        return electionCollectionPosition === 'low'
            ? [...politicsSection, ...curationCollection]
            : [...curationCollection, ...politicsSection]
    } else {
        return politicsSection
    }
}

export const getPoliticsRoute: GetMainSectionFn = (sectionBuilder, store) => {
    const toggles = store?.getState().toggles
    let electionDefinition

    if (toggles) {
        const wanElectionId =
            toFeatureState(toggles)[
                'politics-curated-section-wan-election-id'
            ]?.value.string()
        if (wanElectionId) {
            electionDefinition = getUnloadedElectionDefinition(wanElectionId)
        }
    }

    return [
        ...(electionDefinition
            ? buildCuratedSection(
                  sectionBuilder,
                  electionDefinition,
                  store?.getState().toggles,
              )
            : []),

        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Federal Politics',
                    headingUrl: '/politics/federal-politics',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['politics/federal-politics'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'State Politics',
                    headingUrl: '/politics/state-politics',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['politics/state-politics'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('one'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'Local Government',
                    headingUrl: '/politics/local-government',
                },

                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['politics/local-government'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
        ...billboardLeaderboardMrec('two'),
        layout.component({
            type: 'lima',
            props: {
                verticalSpacing: 'xl',
                fontScale: 1.2,
                fixedRatios: ['16:9'],
                hasBackground: true,
                teaserMode: {
                    initial: 'visible',
                    xs: 'visible',
                    sm: 'hidden',
                    md: 'hidden',
                },
                initialColumns: 1,
                intermediateColumns: 2,
                finalColumns: 4,
                cardOrientation: { type: 'Landscape-Portrait' },

                sectionHeader: {
                    heading: 'World Politics',
                    headingUrl: '/politics/world-politics',
                },
                dataDefinitionArgs: {
                    type: 'listing',
                    topics: ['politics/world-politics'],
                    includeSubTopics: false,
                    paging: {
                        page: 1,
                        pageSize: 4,
                    },
                },
            },
        }),
    ]
}
