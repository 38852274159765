import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheWestSection } from '@news-mono/common'
import {
    BaseClientConfig,
    ElectionDefinition,
    GetRouteAdTargeting,
    isElectionFeatureEnabled,
    PageType,
    RouteResolution,
    Store,
} from '@news-mono/web-common'
import { createElectorateEmbedPage } from '../embeds/createElectorateEmbedPage'
import { createElectionElectoratePage } from './createElectionElectoratePage'
import { createElectionLandingPage } from './createElectionLandingPage'
import { createElectionPartyTotalsPage } from './createElectionPartyTotalsPage'
import { getTopicPageRouteInfo } from '../../topic/get-topic-page-route-info'
import { createElectionPollieRaterPage } from './createElectionPollieRaterPage'

export const createSpecificElectionLandingPage = (
    resolution: RouteResolution,
    electionDefinition: ElectionDefinition,
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
    config: BaseClientConfig,
): PageType<TheWestSection> | null => {
    try {
        if (
            resolution.type === 'static' &&
            resolution.resolution?.type === 'topic'
        ) {
            return getTopicPageRouteInfo({
                publicationKind: resolution.resolution.publicationKind,
                topic: resolution.resolution.topic,
                config,
                section: resolution.resolution.section as TheWestSection,
                getAdTargeting,
                classification: resolution.resolution.classification,
            })
        }

        return createElectionLandingPage({
            getAdTargeting,
            electionDefinition: electionDefinition,
            toggles: store.getState().toggles,
        })
    } catch (error) {
        console.error({ error }, 'Error creating election landing page!')
        return null
    }
}

export const createSpecificElectoratesPage = (
    electionDefinition: ElectionDefinition,
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
): PageType<TheWestSection> | null => {
    const isElectoratesEnabled = isElectionFeatureEnabled(
        store.getState().toggles,
        electionDefinition.electionId,
        'election-electorate-results-route',
    )

    if (!isElectoratesEnabled) {
        return null
    }

    return createElectionElectoratePage({
        getAdTargeting,
        electionDefinition: electionDefinition,
    })
}
export const createSpecificPartyTotalsPage = (
    electionDefinition: ElectionDefinition,
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
): PageType<TheWestSection> | null => {
    // Override meta with that from taxonomy if provided.
    const isPartyTotalsEnabled = isElectionFeatureEnabled(
        store.getState().toggles,
        electionDefinition.electionId,
        'election-party-totals-route',
    )

    if (!isPartyTotalsEnabled) {
        return null
    }

    return createElectionPartyTotalsPage({
        electionDefinition,
        getAdTargeting,
    })
}

export const createSpecificElectionEmbedPage = (
    electionDefinition: ElectionDefinition,
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
) => {
    const enableRoute = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        'election-widget-preview-page',
    )

    if (!enableRoute) {
        return null
    }

    return createElectorateEmbedPage({
        electionDefinition,
        getAdTargeting,
    })
}

export const createSpecificPollieRaterPage = (
    electionDefinition: ElectionDefinition,
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
): PageType<TheWestSection> | null => {
    // Override meta with that from taxonomy if provided.
    const isPollieRaterEnabled = isElectionFeatureEnabled(
        store.getState().toggles,
        electionDefinition.electionId,
        'election-pollie-rater', // This will 404 on non-federal elections
    )

    if (!isPollieRaterEnabled) {
        return null
    }

    return createElectionPollieRaterPage({
        electionDefinition,
        getAdTargeting,
    })
}
