import {
    ElectionDefinition,
    GetRouteAdTargeting,
    getSocialImageMeta,
    getElectionAdPageKindFromElectionId,
    PageType,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import {
    metrics,
    calcRem,
    theWestTextOnlySectionHeaderOverride,
} from '@news-mono/component-library'
import { TheWestSection } from '@news-mono/common'
import { getYear } from 'date-fns'
import { wrapWithElectionContext } from '../routes'

type CreateElectionPartyTotalsPageArgs = {
    getAdTargeting: GetRouteAdTargeting
    electionDefinition: ElectionDefinition
}

export const createElectionPartyTotalsPage = ({
    getAdTargeting,
    electionDefinition,
}: CreateElectionPartyTotalsPageArgs): PageType<TheWestSection> => {
    const meta = electionDefinition.meta
    const isFederal =
        electionDefinition.electionData?.config?.electionType.toLowerCase() ===
        'federal'
    const electionState =
        electionDefinition.electionData?.config?.state?.toUpperCase()
    const electionYear = getYear(
        new Date(electionDefinition.electionData?.config?.electionDate ?? ''),
    )

    let defaultTitle
    let defaultDescription
    let collectionHeading

    if (isFederal) {
        defaultTitle = `Party results Australian election ${electionYear}`
        defaultDescription = `All the party results for the ${electionYear} Australian federal election.`
        collectionHeading = `Latest Federal Election Updates`
    } else {
        defaultTitle = `Party results ${electionState} State election ${electionYear}`
        defaultDescription = `All the party results for the ${electionYear} ${electionState} State election.`
        collectionHeading = `Latest ${electionState} Election Updates`
    }

    const seoTitle = meta.partyTotals?.seoTitle ?? defaultTitle
    const seoDescription =
        meta.partyTotals?.seoDescription ?? defaultDescription

    const electionPageKind = getElectionAdPageKindFromElectionId(
        electionDefinition.electionId,
    )

    const adTargeting = getAdTargeting(
        electionPageKind ?? 'home',
        'default',
        electionDefinition.electionId,
    )

    // as per https://swmdigital-wa.slack.com/archives/C07TZDGREDA/p1741412941432569?thread_ts=1741411065.813789&cid=C07TZDGREDA
    // not sure if this is the best way to do it, so we should fix it up later
    adTargeting.ssAdUnits = adTargeting.ssAdUnits
        .flatMap((adUnit) => adUnit.split('/'))
        .map((adUnit) =>
            adUnit === 'WAelection2025' ? 'WA election 2025' : adUnit,
        )

    return {
        kind: 'page',
        heading: seoTitle,
        pageType: 'static',
        section: 'default',
        hideHeading: true,
        adTargeting,
        pageMeta: {
            title: seoTitle,
            description: seoDescription,
            link: meta.canonical
                ? [
                      {
                          rel: 'canonical',
                          href: `${meta.canonical}politics/${electionDefinition.electionId}/party-totals`,
                      },
                  ]
                : undefined,
            meta: meta.partyTotals?.socialImage
                ? getSocialImageMeta(
                      meta.partyTotals.socialImage,
                      '2400',
                      '1260',
                  )
                : undefined,
        },
        socialMeta: {
            title: seoTitle,
            description: seoDescription,
        },
        compositions: wrapWithElectionContext([
            layout.nestedComposition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    flex: {
                        default: {
                            flexDirection: 'column',
                            gap: calcRem(24),
                        },
                        sm: {
                            gap: calcRem(48),
                        },
                    },
                    verticalGutters: ['unset', 'xxl'],
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {},
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'breaking-news',
                                        props: {
                                            dataDefinitionArgs: {
                                                type: 'curation',
                                                name: 'breaking-news',
                                                offset: 0,
                                                pageSize: 1,
                                            },
                                            isElectionPageRoute: true,
                                            removeBottomMargin: true,
                                        },
                                    }),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            verticalSpacing: 'unset',
                                            breakpoints: {
                                                lg: {
                                                    verticalSpacing: 'unset',
                                                    horizontalGuttersOverride: 15,
                                                },
                                            },
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'election-head-to-head-seat-count-widget',
                                                    props: {
                                                        electionDefinition,
                                                    },
                                                }),
                                                layout.component({
                                                    type: 'election-navigation',
                                                    props: {
                                                        electionDefinition,
                                                        activePage:
                                                            'party-totals',
                                                        headingLevel: 'h2',
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'athena',
                            props: {
                                containerWidth:
                                    metrics.thewest.siteMetrics
                                        .mainContentWidth,
                                elementType: 'div',
                                sidebarOptions: 'hiddenMobile',
                                hasHorizontalGutters: false,
                                hasVerticalPadding: false,
                            },
                            contentAreas: {
                                sidebar: [
                                    layout.component({
                                        type: 'ad-unit',
                                        props: {
                                            noticePosition: 'above-center',
                                            padding: [
                                                0,
                                                0,
                                                metrics.thewest.margins.md,
                                                0,
                                            ],
                                            slot: {
                                                id: 'sidebar-top',
                                                size: 'desktopMrecHalfPage',
                                                pageType: 'static',
                                            },
                                            adType: 'inline',
                                        },
                                    }),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            stickyOffset: 155,
                                            fillContainer: true,
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'juliet',
                                                    props: {
                                                        cardType: {
                                                            type: 'landscape',
                                                            format: 'landscape-smallImage',
                                                        },
                                                        removeHorizontalGutters:
                                                            true,
                                                        kickerMode: 'hidden',
                                                        contentIsTangential:
                                                            true,
                                                        sectionHeader: {
                                                            heading:
                                                                collectionHeading,
                                                        },
                                                        dataDefinitionArgs: {
                                                            type: 'curation',
                                                            name: electionDefinition.electionId,
                                                            pageSize: 4,
                                                            offset: 0,
                                                        },
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],

                                main: [
                                    layout.nestedComposition({
                                        type: 'component-overrider',
                                        props: {
                                            override: () =>
                                                theWestTextOnlySectionHeaderOverride,
                                        },
                                        contentAreas: {
                                            children: [
                                                layout.component({
                                                    type: 'section-header',
                                                    props: {
                                                        heading: 'Party Totals',
                                                        isStandalonePage: true,
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                    layout.component({
                                        type: 'election-party-totals',
                                        props: {
                                            electionDefinition,
                                            description: seoDescription,
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ]),
    }
}
