import {
    AppState,
    AuthenticationState,
    CardItem,
    CollectionEvent,
    ComponentServices,
    createCollectionAvailableEvent,
    entitledToAll,
    FixedRatio,
} from '@news-mono/web-common'
import { MaybeLoaded } from 'json-react-layouts-data-loader'
import React from 'react'
import { useSelector } from 'react-redux'
import { HeroCard } from '../../cards/HeroCard/HeroCard'
import {
    HeroVideoCard,
    HeroVideoLayoutRatios,
    VideoPlaybackRestrictionTypes,
} from '../../cards/HeroVideoCard/HeroVideoCard'
import {
    canPlayRestrictedVideo,
    CardOrientationOptions,
    Portrait,
} from '../../cards/Portrait/Portrait'
import {
    getCardItemProps,
    LayoutName,
    NamedLayout,
} from '../../cards/Portrait/Portrait.layouts'
import { HeroCard as TheWestHero } from '../../cards/TheWest/HeroCard'
import { HeroSupportCard } from '../../cards/TheWest/HeroSupportCard'
import { LargeCard } from '../../cards/TheWest/LargeCard'
import { VideoTrailerCard } from '../../cards/VideoTrailerCard/VideoTrailerCard'
import {
    SierraCardContainer,
    StyledHeader,
    StyledSierra,
} from '../../collections/Sierra/Sierra.styled'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { ResponsivePictureLayout } from '../../content/Picture/ResponsivePictureLayouts'
import {
    SectionHeader,
    SectionHeaderProps,
} from '../../section-header/SectionHeader/SectionHeader'
import { CardSwitcher } from '../../__helpers/CardSwitcher'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { OverrideThemeSection } from '../../__helpers/override-theme-section'
import { useProduct } from '../../__product'
import { breakpoints } from '../../__styling/settings/breakpoints'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { Section } from '../../__styling/settings/sections'
import { invertMaybeLoadedItems } from '../helpers/loading'
import { November, NovemberLayout } from '../November/November'

export interface SierraHeroLayout {
    type:
        | 'hero'
        | 'the-west-hero'
        | 'the-west-hero-lgNoBorder'
        | 'the-west-hero-lgNoBorder-withPublicationDate'
        | 'the-west-hero-onRight'
    disableImageLazyLoad?: boolean
}

export interface SierraNewCardLayout {
    type: 'hero-support' | 'large-card-lgNoText'
}

export interface SierraHeroVideoLayout {
    type: 'hero-video' | 'hero-video-left'
}

export interface SierraVideoTrailerCardLayout {
    type: 'video-trailer-card-single' | 'video-trailer-card-double'
}

export interface SierraInlineVideoLayout {
    type: 'teaser-xsVisible-mdHidden-mdInlineVideo'
}

//Only objects which have `type:`
export type SierraLayout =
    | NamedLayout
    | SierraHeroLayout
    | SierraHeroVideoLayout
    | SierraVideoTrailerCardLayout
    | SierraInlineVideoLayout
    | SierraNewCardLayout
    | NovemberLayout
// Objects + shorthand name strings
export type SierraLayoutWithShorthand =
    | 'hero-support'
    | 'the-west-hero'
    | 'the-west-hero-onRight'
    | 'the-west-hero-lgNoBorder'
    | 'the-west-hero-lgNoBorder-withPublicationDate'
    | 'large-card-lgNoText'
    | 'hero'
    | 'hero-video'
    | 'hero-video-left'
    | 'video-trailer-card-single'
    | 'video-trailer-card-double'
    | 'teaser-xsVisible-mdHidden-mdInlineVideo'
    | LayoutName
    | SierraLayout

export type SierraLayouts =
    | [
          SierraLayoutWithShorthand,
          SierraLayoutWithShorthand,
          SierraLayoutWithShorthand,
      ]
    | [SierraLayoutWithShorthand, SierraLayoutWithShorthand]
    | [SierraLayoutWithShorthand]

interface SierraHeroVideoOptions {
    heroVideoRatio?: HeroVideoLayoutRatios
    largeVideoPlayButton?: boolean
}

export interface SierraProps extends ResponsiveContainer {
    className?: string
    section?: Section
    sectionHeader?: SectionHeaderProps
    fixedRatios?: FixedRatio[]
    onEvent: (event: CollectionEvent) => void
    verticalSpacing?: keyof ThemeMargins | undefined
    disableImageLazyLoad?: boolean
    cardOrientation?: CardOrientationOptions
    cardLayout: SierraLayouts
    heroImageLayout?: ResponsivePictureLayout
    expectedCards: number
    items: MaybeLoaded<CardItem[]>
    cardServices?: ComponentServices
    heroVideoOptions?: SierraHeroVideoOptions
    restrictedVideoPlaybackMode?: VideoPlaybackRestrictionTypes
    overrideRestrictedVideoPlayback?: boolean
    hideByline?: boolean
    hasCollectionBorderBottom?: boolean
    hasHeroBorder?: boolean
    hasBorder?: boolean
    isLarge?: boolean
    heroBottomPadding?: number
    overrideEntitledVideoPlayback?: boolean
    gridGap?: number
}

export function isNovemberLayout(
    layout: SierraLayoutWithShorthand,
): layout is NovemberLayout

export function isNovemberLayout(layout: any) {
    return (
        typeof layout === 'object' &&
        'type' in layout &&
        layout.type === 'november'
    )
}

export function calculateLayoutsLength(
    layouts: SierraLayoutWithShorthand[],
): number {
    return layouts.reduce((accumulator, layout) => {
        const addition = isNovemberLayout(layout) ? layout.listLength : 1 // Default to 1 for other layouts
        return accumulator + addition
    }, 0)
}

function getExpandedLayout(
    layout: SierraLayoutWithShorthand,
    item: MaybeLoaded<CardItem>,
): SierraLayout {
    // Fallback to 'hero' if layout is 'hero-video' but a video card doesnt load
    // DPT-2884 - was preventing click through due to preventdefault
    const isVideoCard = item.loaded && (item.result as any).video
    const videoLayoutTypes: Exclude<
        SierraLayoutWithShorthand,
        LayoutName | SierraLayout
    > =
        'hero-video' ||
        'hero-video-left' ||
        'video-trailer-card-single' ||
        'video-trailer-card-double' ||
        'teaser-xsVisible-mdHidden-mdInlineVideo'

    if (layout === 'hero' || (layout === videoLayoutTypes && !isVideoCard)) {
        return {
            type: 'hero',
            /**
             * Hero typically sits at the top but not always
             */
            disableImageLazyLoad: true,
        }
    }

    if (layout === 'hero-support') {
        return {
            type: 'hero-support',
        }
    }

    if (layout === 'the-west-hero') {
        return {
            type: 'the-west-hero',
        }
    }

    if (layout === 'the-west-hero-lgNoBorder') {
        return {
            type: 'the-west-hero-lgNoBorder',
        }
    }
    if (layout === 'the-west-hero-lgNoBorder-withPublicationDate') {
        return {
            type: 'the-west-hero-lgNoBorder-withPublicationDate',
        }
    }
    if (layout === 'large-card-lgNoText') {
        return {
            type: 'large-card-lgNoText',
        }
    }

    if (layout === 'hero-video') {
        return {
            type: 'hero-video',
        }
    }

    if (layout === 'hero-video-left') {
        return {
            type: 'hero-video-left',
        }
    }

    if (layout === 'video-trailer-card-single') {
        return {
            type: 'video-trailer-card-single',
        }
    }

    if (layout === 'video-trailer-card-double') {
        return {
            type: 'video-trailer-card-double',
        }
    }

    if (layout === 'teaser-xsVisible-mdHidden-mdInlineVideo') {
        return {
            type: 'teaser-xsVisible-mdHidden-mdInlineVideo',
        }
    }

    if (layout === 'the-west-hero-onRight') {
        return {
            type: 'the-west-hero-onRight',
        }
    }

    if (typeof layout === 'object' && layout.type === 'november') {
        return {
            type: 'november',
            listLength: layout.listLength,
            hasBackground: layout.hasBackground,
            paddingOverride: layout.paddingOverride,
        }
    }

    if (typeof layout === 'string') {
        return {
            type: 'named-layout',
            name: layout,
        }
    }

    return layout
}

export const StyledSierraHeroCardClassName = 'StyledSierraHeroCard'

export const Sierra: React.FC<SierraProps> = (props) => {
    const {
        className,
        sectionHeader,
        section,
        verticalSpacing,
        onEvent,
        heroImageLayout,
        cardLayout,
        expectedCards,
        heroVideoOptions,
        overrideRestrictedVideoPlayback,
        hideByline,
        hasCollectionBorderBottom,
        hasHeroBorder = true,
        isLarge,
        heroBottomPadding,
        gridGap,
    } = props
    const product = useProduct()
    const authenticationState = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )

    const isEntitled = entitledToAll(authenticationState)

    const viewportWidth =
        typeof document !== 'undefined' && document.documentElement.clientWidth

    const renderSierra = () => {
        const items = invertMaybeLoadedItems(props.items, expectedCards)

        // Pre-defined column setup
        const layoutLength = cardLayout.length
        const initialColumns = 1
        const intermediateColumns = 1
        const finalColumns = layoutLength

        const containerValues = props.containerWidthRatios
            ? props.containerWidthRatios
            : { desktop: 1, tablet: 1, mobile: 1 }

        return (
            <ImpressionAvailable
                loading={!props.items.loaded}
                available={() => {
                    if (!props.items.loaded) {
                        console.warn(
                            'Available should never be called when loading is true',
                        )
                        return
                    }
                    props.onEvent(
                        createCollectionAvailableEvent(
                            props.items.result,
                            'Sierra',
                            product,
                            props.onEvent,
                        ),
                    )
                }}
            >
                {(ref) => (
                    <div ref={ref}>
                        {sectionHeader && items && (
                            <StyledHeader>
                                <SectionHeader {...sectionHeader} />
                            </StyledHeader>
                        )}
                        <StyledSierra
                            className={className}
                            verticalSpacing={verticalSpacing}
                            cardLayout={cardLayout}
                            hasCollectionBorderBottom={
                                hasCollectionBorderBottom
                            }
                            gridGap={gridGap}
                        >
                            {cardLayout.map((layout, layoutIndex) => {
                                const cardNumber = layoutIndex + 1
                                const item = items[layoutIndex] || {
                                    loaded: false,
                                }
                                const expandedLayout = getExpandedLayout(
                                    layout,
                                    item,
                                )

                                const prevLayouts = cardLayout.slice(
                                    0,
                                    layoutIndex,
                                )

                                const prevLayoutsLength =
                                    calculateLayoutsLength(prevLayouts)

                                if (isNovemberLayout(expandedLayout)) {
                                    return (
                                        <November
                                            className="november-layout"
                                            key={layoutIndex}
                                            items={
                                                props.items.loaded
                                                    ? {
                                                          loaded: true,
                                                          result: props.items.result.slice(
                                                              prevLayoutsLength,
                                                              prevLayoutsLength +
                                                                  expandedLayout.listLength,
                                                          ),
                                                      }
                                                    : { loaded: false }
                                            }
                                            expectedCards={
                                                expandedLayout.listLength
                                            }
                                            onEvent={onEvent}
                                            hasBackground={
                                                expandedLayout.hasBackground
                                            }
                                            paddingOverride={
                                                expandedLayout.paddingOverride
                                            }
                                        />
                                    )
                                }

                                switch (expandedLayout.type) {
                                    case 'hero':
                                        return (
                                            <SierraCardContainer
                                                key={layoutIndex}
                                                cardLayout={cardLayout}
                                                className="heroCardContainer"
                                                hasPortraitBorder={
                                                    !hasCollectionBorderBottom
                                                }
                                                hasCollectionBorderBottom={
                                                    hasCollectionBorderBottom
                                                }
                                            >
                                                <CardSwitcher
                                                    item={item}
                                                    onEvent={onEvent}
                                                    cardContext="sierra-hero"
                                                    cardNumber={cardNumber}
                                                    publicationCard={(
                                                        publicationItem,
                                                    ) => (
                                                        <HeroCard
                                                            item={
                                                                publicationItem
                                                            }
                                                            fixedRatio="16:9"
                                                            hasBackground={true}
                                                            imageLayout={
                                                                heroImageLayout
                                                            }
                                                            className={
                                                                StyledSierraHeroCardClassName
                                                            }
                                                            onEvent={onEvent}
                                                            cardNumber={
                                                                cardNumber
                                                            }
                                                            hideByline={
                                                                hideByline
                                                            }
                                                            mediaMode="fullHeight"
                                                            teaserMode="visible"
                                                            hasHeroBorder={
                                                                hasHeroBorder
                                                            }
                                                            containerWidthRatios={{
                                                                mobile:
                                                                    (1 /
                                                                        initialColumns) *
                                                                    containerValues.mobile,
                                                                tablet:
                                                                    // Hero image is half the container width on tablet
                                                                    (1 /
                                                                        intermediateColumns /
                                                                        2) *
                                                                    containerValues.tablet,
                                                                desktop:
                                                                    // Column grid on desktop is either 4 of 5 as hero card spans 3 columns.
                                                                    // To determine the correct width ratio, we need to calculate the individual
                                                                    // width of each column, multipled by how many columns hero card spans.
                                                                    // the image itself is approximately half the card size (it's not perfect but close)
                                                                    // prettier-ignore
                                                                    (((1 / (finalColumns + 2)) * 3) / 2) * containerValues.desktop,
                                                            }}
                                                            disableImageLazyLoad={
                                                                expandedLayout.disableImageLazyLoad
                                                            }
                                                        />
                                                    )}
                                                />
                                            </SierraCardContainer>
                                        )
                                    case 'large-card-lgNoText':
                                        return (
                                            <SierraCardContainer
                                                key={layoutIndex}
                                                cardLayout={cardLayout}
                                                hasPortraitBorder={
                                                    !hasCollectionBorderBottom
                                                }
                                                hasCollectionBorderBottom={
                                                    hasCollectionBorderBottom
                                                }
                                            >
                                                <CardSwitcher
                                                    key={layoutIndex}
                                                    onEvent={onEvent}
                                                    item={item}
                                                    cardContext="sierra-hero"
                                                    cardNumber={cardNumber}
                                                    publicationCard={(
                                                        publicationItem,
                                                    ) => (
                                                        <LargeCard
                                                            item={
                                                                publicationItem
                                                            }
                                                            cardNumber={
                                                                cardNumber
                                                            }
                                                            onEvent={onEvent}
                                                            teaserMode={{
                                                                initial:
                                                                    'visible',
                                                                xs: 'visible',
                                                                sm: 'visible',
                                                                md: 'hidden',
                                                            }}
                                                            horizontalLayoutAtSize={{
                                                                size: 'md',
                                                            }}
                                                            showPublicationDate={
                                                                true
                                                            }
                                                        />
                                                    )}
                                                />
                                            </SierraCardContainer>
                                        )
                                    case 'the-west-hero':
                                        return (
                                            <CardSwitcher
                                                key={layoutIndex}
                                                onEvent={onEvent}
                                                item={item}
                                                cardContext="sierra-hero"
                                                cardNumber={cardNumber}
                                                publicationCard={(
                                                    publicationItem,
                                                ) => (
                                                    <TheWestHero
                                                        item={publicationItem}
                                                        cardNumber={cardNumber}
                                                        cardServices={
                                                            props.cardServices
                                                        }
                                                        onEvent={props.onEvent}
                                                        adUnitPath={
                                                            props.cardServices
                                                                ?.adState
                                                                .targeting
                                                                .adUnitPath
                                                        }
                                                        overrideRestrictedVideoPlayback={
                                                            props.overrideRestrictedVideoPlayback
                                                        }
                                                        isLarge={isLarge}
                                                        bottomPadding={
                                                            heroBottomPadding
                                                        }
                                                        containerWidthRatios={{
                                                            mobile:
                                                                1 *
                                                                containerValues.mobile,
                                                            tablet:
                                                                0.5 *
                                                                containerValues.tablet,
                                                            desktop:
                                                                (((1 /
                                                                    (finalColumns +
                                                                        2)) *
                                                                    3) /
                                                                    2) *
                                                                containerValues.desktop,
                                                        }}
                                                    />
                                                )}
                                            />
                                        )
                                    case 'the-west-hero-onRight':
                                        return (
                                            <CardSwitcher
                                                key={layoutIndex}
                                                onEvent={onEvent}
                                                item={item}
                                                cardContext="sierra-hero"
                                                cardNumber={cardNumber}
                                                publicationCard={(
                                                    publicationItem,
                                                ) => (
                                                    <TheWestHero
                                                        item={publicationItem}
                                                        cardNumber={cardNumber}
                                                        cardServices={
                                                            props.cardServices
                                                        }
                                                        onEvent={props.onEvent}
                                                        adUnitPath={
                                                            props.cardServices
                                                                ?.adState
                                                                .targeting
                                                                .adUnitPath
                                                        }
                                                        overrideRestrictedVideoPlayback={
                                                            props.overrideRestrictedVideoPlayback
                                                        }
                                                        isLarge={isLarge}
                                                        containerWidthRatios={{
                                                            mobile:
                                                                1 *
                                                                containerValues.mobile,
                                                            tablet:
                                                                0.5 *
                                                                containerValues.tablet,
                                                            desktop:
                                                                (((1 /
                                                                    (finalColumns +
                                                                        2)) *
                                                                    3) /
                                                                    2) *
                                                                containerValues.desktop,
                                                        }}
                                                        cardPosition={'right'}
                                                        hideBorder
                                                    />
                                                )}
                                            />
                                        )
                                    case 'the-west-hero-lgNoBorder':
                                        return (
                                            <CardSwitcher
                                                key={layoutIndex}
                                                onEvent={onEvent}
                                                item={item}
                                                cardContext="sierra-hero"
                                                cardNumber={cardNumber}
                                                publicationCard={(
                                                    publicationItem,
                                                ) => (
                                                    <TheWestHero
                                                        item={publicationItem}
                                                        cardNumber={cardNumber}
                                                        cardServices={
                                                            props.cardServices
                                                        }
                                                        onEvent={props.onEvent}
                                                        adUnitPath={
                                                            props.cardServices
                                                                ?.adState
                                                                .targeting
                                                                .adUnitPath
                                                        }
                                                        overrideRestrictedVideoPlayback={
                                                            props.overrideRestrictedVideoPlayback
                                                        }
                                                        hideBorder={true}
                                                        isLarge={isLarge}
                                                        containerWidthRatios={{
                                                            mobile:
                                                                1 *
                                                                containerValues.mobile,
                                                            tablet:
                                                                0.5 *
                                                                containerValues.tablet,
                                                            desktop:
                                                                (((1 /
                                                                    (finalColumns +
                                                                        2)) *
                                                                    3) /
                                                                    2) *
                                                                containerValues.desktop,
                                                        }}
                                                    />
                                                )}
                                            />
                                        )
                                    case 'the-west-hero-lgNoBorder-withPublicationDate':
                                        return (
                                            <CardSwitcher
                                                key={layoutIndex}
                                                onEvent={onEvent}
                                                item={item}
                                                cardContext="sierra-hero"
                                                cardNumber={cardNumber}
                                                publicationCard={(
                                                    publicationItem,
                                                ) => (
                                                    <TheWestHero
                                                        item={publicationItem}
                                                        cardNumber={cardNumber}
                                                        cardServices={
                                                            props.cardServices
                                                        }
                                                        onEvent={props.onEvent}
                                                        adUnitPath={
                                                            props.cardServices
                                                                ?.adState
                                                                .targeting
                                                                .adUnitPath
                                                        }
                                                        overrideRestrictedVideoPlayback={
                                                            props.overrideRestrictedVideoPlayback
                                                        }
                                                        showPublicationDate={
                                                            true
                                                        }
                                                        hideBorder={true}
                                                        isLarge={isLarge}
                                                        containerWidthRatios={{
                                                            mobile:
                                                                1 *
                                                                containerValues.mobile,
                                                            tablet:
                                                                0.5 *
                                                                containerValues.tablet,
                                                            desktop:
                                                                (((1 /
                                                                    (finalColumns +
                                                                        2)) *
                                                                    3) /
                                                                    2) *
                                                                containerValues.desktop,
                                                        }}
                                                    />
                                                )}
                                            />
                                        )
                                    case 'hero-support':
                                        return (
                                            <CardSwitcher
                                                key={layoutIndex}
                                                onEvent={onEvent}
                                                item={item}
                                                cardContext="sierra-hero"
                                                cardNumber={cardNumber}
                                                publicationCard={(
                                                    publicationItem,
                                                ) => (
                                                    <HeroSupportCard
                                                        item={publicationItem}
                                                        cardNumber={cardNumber}
                                                        cardServices={
                                                            props.cardServices
                                                        }
                                                        containerWidthRatios={{
                                                            mobile:
                                                                (1 /
                                                                    initialColumns) *
                                                                containerValues.mobile,
                                                            tablet:
                                                                (1 /
                                                                    intermediateColumns) *
                                                                containerValues.tablet,
                                                            desktop:
                                                                (1 /
                                                                    (finalColumns +
                                                                        2)) *
                                                                containerValues.desktop,
                                                        }}
                                                        onEvent={props.onEvent}
                                                        adUnitPath={
                                                            props.cardServices
                                                                ?.adState
                                                                .targeting
                                                                .adUnitPath
                                                        }
                                                    />
                                                )}
                                            />
                                        )

                                    case 'hero-video':
                                    case 'hero-video-left':
                                        return (
                                            <SierraCardContainer
                                                key={layoutIndex}
                                                cardLayout={cardLayout}
                                                className="heroCardContainer"
                                                hasPortraitBorder={
                                                    !hasCollectionBorderBottom
                                                }
                                                hasCollectionBorderBottom={
                                                    hasCollectionBorderBottom
                                                }
                                            >
                                                <CardSwitcher
                                                    item={item}
                                                    onEvent={onEvent}
                                                    cardContext="sierra-hero"
                                                    cardNumber={cardNumber}
                                                    publicationCard={(
                                                        publicationItem,
                                                    ) => (
                                                        <HeroVideoCard
                                                            item={
                                                                publicationItem
                                                            }
                                                            cardServices={
                                                                props.cardServices
                                                            }
                                                            fixedRatio="16:9"
                                                            hasBackground={true}
                                                            imageLayout={
                                                                heroImageLayout
                                                            }
                                                            onEvent={onEvent}
                                                            cardNumber={
                                                                cardNumber
                                                            }
                                                            hideByline={
                                                                hideByline
                                                            }
                                                            teaserMode="visible"
                                                            layoutRatio={
                                                                heroVideoOptions?.heroVideoRatio
                                                            }
                                                            videoPosition={
                                                                expandedLayout.type ===
                                                                'hero-video'
                                                                    ? 'video-on-right'
                                                                    : 'video-on-left'
                                                            }
                                                            largePlayButton={
                                                                heroVideoOptions?.largeVideoPlayButton
                                                            }
                                                            restrictedVideoPlaybackMode={
                                                                props.restrictedVideoPlaybackMode
                                                            }
                                                            adUnitPath={
                                                                props
                                                                    .cardServices
                                                                    ?.adState
                                                                    .targeting
                                                                    .adUnitPath
                                                            }
                                                            hasHeroBorder={
                                                                hasHeroBorder
                                                            }
                                                        />
                                                    )}
                                                />
                                            </SierraCardContainer>
                                        )
                                    case 'video-trailer-card-single':
                                    case 'video-trailer-card-double':
                                        return (
                                            <SierraCardContainer
                                                key={layoutIndex}
                                                cardLayout={props.cardLayout}
                                                className={
                                                    expandedLayout.type ===
                                                    'video-trailer-card-single'
                                                        ? 'portraitCardContainer'
                                                        : 'heroCardContainer'
                                                }
                                                hasCollectionBorderBottom={
                                                    hasCollectionBorderBottom
                                                }
                                            >
                                                <VideoTrailerCard
                                                    cardNumber={cardNumber}
                                                    onEvent={props.onEvent}
                                                    layoutMode={
                                                        expandedLayout.type ===
                                                        'video-trailer-card-single'
                                                            ? 'single'
                                                            : 'double'
                                                    }
                                                />
                                            </SierraCardContainer>
                                        )
                                    case 'teaser-xsVisible-mdHidden-mdInlineVideo':
                                        return (
                                            <SierraCardContainer
                                                key={layoutIndex}
                                                cardLayout={props.cardLayout}
                                                className="portraitCardContainer"
                                                hasCollectionBorderBottom={
                                                    hasCollectionBorderBottom
                                                }
                                            >
                                                <CardSwitcher
                                                    item={item}
                                                    onEvent={onEvent}
                                                    cardContext="sierra-portrait"
                                                    cardNumber={cardNumber}
                                                    publicationCard={(
                                                        publicationItem,
                                                    ) => (
                                                        <Portrait
                                                            hideByline={
                                                                hideByline
                                                            }
                                                            fixedRatio={'16:9'}
                                                            // As hero card spans multiple columns, the actual width of portrait is either a 4 or 5 column width
                                                            // depending on how many cards. E.g. 2 cards is 4 columns, 3 cards is 5 columns.
                                                            containerWidthRatios={{
                                                                mobile:
                                                                    (1 /
                                                                        initialColumns) *
                                                                    containerValues.mobile,
                                                                tablet:
                                                                    (1 /
                                                                        intermediateColumns) *
                                                                    containerValues.tablet,
                                                                desktop:
                                                                    (1 /
                                                                        (finalColumns +
                                                                            2)) *
                                                                    containerValues.desktop,
                                                            }}
                                                            {...getCardItemProps(
                                                                publicationItem,
                                                                expandedLayout.type,
                                                                {
                                                                    onEvent,
                                                                },
                                                                cardNumber,
                                                                props.cardOrientation,
                                                            )}
                                                            canPlayVideoInline={
                                                                canPlayRestrictedVideo(
                                                                    props.restrictedVideoPlaybackMode,
                                                                    isEntitled,
                                                                ) &&
                                                                viewportWidth >=
                                                                    breakpoints.md
                                                            }
                                                            adUnitPath={
                                                                props
                                                                    .cardServices
                                                                    ?.adState
                                                                    .targeting
                                                                    .adUnitPath
                                                            }
                                                            overrideRestrictedVideoPlayback={
                                                                overrideRestrictedVideoPlayback
                                                            }
                                                        />
                                                    )}
                                                />
                                            </SierraCardContainer>
                                        )
                                    default:
                                        return (
                                            <SierraCardContainer
                                                key={layoutIndex}
                                                cardLayout={props.cardLayout}
                                                className="portraitCardContainer"
                                                hasCollectionBorderBottom={
                                                    hasCollectionBorderBottom
                                                }
                                            >
                                                <CardSwitcher
                                                    item={item}
                                                    onEvent={onEvent}
                                                    cardContext="sierra-portrait"
                                                    cardNumber={cardNumber}
                                                    publicationCard={(
                                                        publicationItem,
                                                    ) => (
                                                        <Portrait
                                                            fixedRatio={'16:9'}
                                                            // As hero card spans multiple columns, the actual width of portrait is either a 4 or 5 column width
                                                            // depending on how many cards. E.g. 2 cards is 4 columns, 3 cards is 5 columns.
                                                            containerWidthRatios={{
                                                                mobile:
                                                                    (1 /
                                                                        initialColumns) *
                                                                    containerValues.mobile,
                                                                tablet:
                                                                    (1 /
                                                                        intermediateColumns) *
                                                                    containerValues.tablet,
                                                                desktop:
                                                                    (1 /
                                                                        (finalColumns +
                                                                            2)) *
                                                                    containerValues.desktop,
                                                            }}
                                                            {...getCardItemProps(
                                                                publicationItem,
                                                                expandedLayout.name,
                                                                {
                                                                    onEvent,
                                                                },
                                                                cardNumber,
                                                                props.cardOrientation,
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </SierraCardContainer>
                                        )
                                }
                            })}
                        </StyledSierra>
                    </div>
                )}
            </ImpressionAvailable>
        )
    }

    return section ? (
        <OverrideThemeSection section={section}>
            {renderSierra()}
        </OverrideThemeSection>
    ) : (
        renderSierra()
    )
}

Sierra.displayName = 'Sierra'
