import {
    createRegisterableComponent,
    ElectionDefinition,
} from '@news-mono/web-common'
import React from 'react'
import { FullElectorateWidget } from './FullElectorateWidget'

export interface FullElectorateWidgetRegistrationProps {
    electionDefinition: ElectionDefinition
}
export const FullElectorateWidgetRegistration = createRegisterableComponent(
    'election-full-electorate-widget',
    (props: FullElectorateWidgetRegistrationProps, services) => {
        return (
            <FullElectorateWidget
                electionDefinition={props.electionDefinition}
                onEvent={services.onEvent}
                imageBaseUrl={services.config.imageBaseUrl}
            />
        )
    },
)
