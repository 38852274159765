import {
    BreakingNewsCard,
    CollectionEvent,
    createCardClickedEvent,
    createCollectionAvailableEvent,
    PublicationCardItem,
    useFeature,
} from '@news-mono/web-common'
import React from 'react'
import {
    BreakingNewsThemeName,
    BreakingNewsThemeNames,
    StyledAnnouncementMark,
    StyledBreakingNews,
    StyledBreakingNewsMark,
    StyledBreakingNewsTitle,
    StyledCricketPlayerImage,
    StyledCricketPlayerWrapper,
    StyledFederalElection2022Logo,
    StyledGameOnParisLogo,
    StyledGameOnParisWrapper,
    StyledGrandFinalText,
    StyledGrandfinalWrapper,
    StyledIconArrowCircle,
    StyledIconChevronRight,
    StyledLogoContainer,
    StyledPick11Logo,
    StyledSevenCricketLogo,
    StyledTheGameLogo,
    StyledTheWest190SmallLogo,
    StyledWrapper,
} from '../../content/BreakingNews/BreakingNews.styled'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product'
import WaElection2025Theme from './Themes/WaElection2025Theme'
import FederalElection2025Theme from './Themes/FederalElection2025Theme'

export interface BreakingNewsProps {
    titlePrefix?: string
    breakingNewsTheme: string | undefined
    item: PublicationCardItem
    removeBottomMargin?: boolean
    /** themes specified in this list hide their logos. */
    hiddenLogoThemes?: BreakingNewsThemeName[]
    isElectionPageRoute?: boolean
    isHomePageRoute?: boolean
    onEvent: (event: CollectionEvent) => void
}

function getTheme(
    theme: string | undefined,
    // {TODO} clean this up after afl grand finals
    didFreoWin?: boolean,
): BreakingNewsThemeName {
    if (BreakingNewsThemeNames.indexOf(theme as BreakingNewsThemeName) > -1) {
        if (didFreoWin && theme === 'grand-final') {
            return 'grand-final-freo' as BreakingNewsThemeName
        }

        return theme as BreakingNewsThemeName
    }
    return 'default'
}

export const renderTitlePrefix = (
    titlePrefix: string | undefined,
    newsTheme: BreakingNewsThemeName | undefined,
) => {
    if (!titlePrefix && newsTheme) {
        switch (newsTheme) {
            case 'announcement':
            case 'thegame':
            case 'wa-election-2025':
            case 'federal-election-2025':
            case 'blackfriday':
            case 'federal-election-2022':
                return ''
            case 'cricket':
                return 'Cricket: Live and Free on Seven'
            case 'csk':
                return 'Latest:'
            case 'paris-2024':
                return 'Olympic News:'
            default:
                return 'Breaking News:'
        }
    }

    return titlePrefix || 'Breaking News:'
}

export const BreakingNews: React.FC<BreakingNewsProps> = ({
    item,
    breakingNewsTheme,
    titlePrefix,
    onEvent,
    removeBottomMargin,
    hiddenLogoThemes = [],
    isElectionPageRoute = false,
    isHomePageRoute = false,
}) => {
    const product = useProduct()

    const hideOnMobileAndApp = useFeature('hide-mobile-breaking-news-banner')
    const isPollieRaterDriver = useFeature(
        'federal-election-2022-pollie-rater-strap-driver',
    )
    const didFreoWin = useFeature('the-west-grand-final-freo-collection-theme')

    if (!item.shortHeadline) {
        return null
    }

    const isAnnouncement = breakingNewsTheme === 'announcement'

    const newItem: BreakingNewsCard = {
        ...item,
        cardType: 'breaking-news',
        listId: 'breaking-news',
    }

    const cardFormat = isAnnouncement ? 'Announcement' : 'BreakingNews'

    const linkClicked = () => {
        onEvent(
            createCardClickedEvent(
                onEvent,
                newItem,
                'BreakingNews',
                cardFormat,
                1,
            ),
        )
    }

    const renderMark = (newsTheme: BreakingNewsThemeName | undefined) => {
        if (!newsTheme) {
            return <StyledBreakingNewsMark breakingNewsTheme={'default'} />
        }

        const theme = getTheme(newsTheme)

        switch (theme) {
            case 'default':
                return <StyledBreakingNewsMark breakingNewsTheme={'default'} />
            case 'grand-final':
            case 'grand-final-freo':
            case 'blackfriday':
            case 'federal-election-2022':
            case '190-years-of-the-west':
                return
            case 'paris-2024':
                return
            case 'wa-election-2025':
            case 'federal-election-2025':
            default:
                return <StyledAnnouncementMark breakingNewsTheme={newsTheme} />
        }
    }

    const renderLogo = (newsTheme: BreakingNewsThemeName | undefined) => {
        if (!newsTheme) {
            return <StyledIconChevronRight />
        }

        const cricketPlayerImage = require<string>('./assets/pick11-cricketplayer.png')
        const cricketPlayerImage2x = require<string>('./assets/pick11-cricketplayer@2x.png')

        switch (newsTheme) {
            case 'cricket':
                return (
                    <StyledLogoContainer>
                        <StyledSevenCricketLogo
                            src={require<string>('./assets/7cricketlogo.png')}
                            alt="Seven News Cricket Live and in HD"
                        />
                    </StyledLogoContainer>
                )
            case 'thegame':
                return <StyledTheGameLogo />
            case 'wa-election-2025':
                return (
                    <WaElection2025Theme
                        isElectionPageRoute={isElectionPageRoute}
                        isHomePageRoute={isHomePageRoute}
                    />
                )
            case 'federal-election-2025':
                return (
                    <FederalElection2025Theme
                        isElectionPageRoute={isElectionPageRoute}
                        isHomePageRoute={isHomePageRoute}
                    />
                )
            case 'csk':
                return
            case 'pick11':
                return (
                    <StyledCricketPlayerWrapper>
                        <StyledCricketPlayerImage
                            srcSet={`${cricketPlayerImage},
                                ${cricketPlayerImage2x} 2x,`}
                            src={cricketPlayerImage}
                        />
                        <StyledPick11Logo />
                    </StyledCricketPlayerWrapper>
                )
            case 'grand-final-freo':
            case 'grand-final':
                return (
                    <StyledGrandfinalWrapper>
                        <StyledGrandFinalText>
                            AFL Finals 2022
                        </StyledGrandFinalText>
                        <StyledIconArrowCircle />
                    </StyledGrandfinalWrapper>
                )
            case 'blackfriday':
                return

            case 'federal-election-2022':
                return (
                    <StyledFederalElection2022Logo>
                        Federal Election 2022
                    </StyledFederalElection2022Logo>
                )

            case '190-years-of-the-west':
                return (
                    <StyledTheWest190SmallLogo>
                        The West Australian - For 190 Years
                    </StyledTheWest190SmallLogo>
                )

            case 'paris-2024':
                return (
                    <StyledGameOnParisWrapper>
                        <StyledGameOnParisLogo />
                    </StyledGameOnParisWrapper>
                )

            default:
                return <StyledIconChevronRight />
        }
    }

    return (
        <ImpressionAvailable
            loading={false}
            available={() => {
                onEvent(
                    createCollectionAvailableEvent(
                        [
                            `Breaking News: ${renderTitlePrefix(
                                titlePrefix,
                                breakingNewsTheme,
                            )} ${item.shortHeadline}`,
                        ],
                        `Breaking News`,
                        product,
                        onEvent,
                        {
                            cardFormat: breakingNewsTheme ?? 'default',
                        },
                    ),
                )
            }}
        >
            {(ref) => (
                // TODO You can't pass a ref down to a custom component. We should be able
                // to fix this with emotion 10. Wrapping in a div for now
                <div ref={ref}>
                    <StyledBreakingNews
                        to={
                            isPollieRaterDriver &&
                            breakingNewsTheme === 'federal-election-2022'
                                ? `/politics/federal-election/pollie-rater`
                                : item.link
                        }
                        onClick={linkClicked}
                        breakingNewsTheme={getTheme(
                            breakingNewsTheme,
                            didFreoWin,
                        )}
                        removeBottomMargin={removeBottomMargin}
                        hideOnMobileAndApp={hideOnMobileAndApp}
                    >
                        <StyledWrapper>
                            {renderMark(breakingNewsTheme)}
                            <StyledBreakingNewsTitle
                                breakingNewsTheme={getTheme(breakingNewsTheme)}
                                fontScale={1}
                            >
                                {`${renderTitlePrefix(
                                    titlePrefix,
                                    breakingNewsTheme,
                                )} ${item.shortHeadline}`}
                            </StyledBreakingNewsTitle>
                        </StyledWrapper>
                        {renderLogo(breakingNewsTheme)}
                    </StyledBreakingNews>
                </div>
            )}
        </ImpressionAvailable>
    )
}
BreakingNews.displayName = 'BreakingNews'
