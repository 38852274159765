import styled from '@emotion/styled'
import { calcRem, breakpoint } from '../../../__styling'

export const StyledElectionTitleContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: calcRem(24),
    alignSelf: 'stretch',

    padding: calcRem(16),

    [breakpoint('lg')]: {
        padding: calcRem(0),
    },
})
