import styled from '@emotion/styled'
import {
    AllEvents,
    ArticleLikePublication,
    CardInformation,
    createCollectionAvailableEvent,
    DataLayerEventName,
    isArticle,
    raiseSelectItemEvent,
    Topic,
} from '@news-mono/web-common'
import React, { FC, useCallback } from 'react'
import { InlineTheGameBanner } from '..'
import { breakpoint, calcRem } from '../../..'
import { ImageBanner } from '../../../banners/ImageBanner/ImageBanner'
import { InlineContentTypes } from '../SharedPublication.routing'
import {
    ImpressionAvailable,
    useImpressionAvailable,
} from '../../../__helpers/impression-available-helper'
import debug from 'debug'

const image = require('./assets/the-game-afl-2025-mazda-prize.png')
const imageV2 = require('./assets/the-game-afl-2025-v2.png')

const StyledInlineTheGameBanner = styled('div')(({ theme }) => ({
    div: {
        margin: '0 auto',
        maxWidth: calcRem(680),
    },
    [breakpoint('md')]: {
        padding: calcRem(0, theme.margins.xxl),
    },
}))

export interface getInlinePositionedGeneralBannerProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    onEvent: (event: AllEvents) => void
}

export function isInlineTheGameBanner(
    content: InlineContentTypes,
): content is InlineTheGameBanner {
    return content.kind === 'inline-the-game-banner'
}

function matchSportsTopic(primaryTopic: Topic) {
    return /^sport/.test(primaryTopic.id)
}

export function getInlinePositionedTheGameBanner({
    publication,
    inlinePublicationContent,
    onEvent,
}: getInlinePositionedGeneralBannerProps) {
    if (
        !isArticle(publication) ||
        !matchSportsTopic(publication.primaryTopic)
    ) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineTheGameBanner)
        .filter((content) => content.props.toggle)
        .map((content) => {
            return {
                position: content.insertAfter,
                element: (
                    <InlineTheGameBannerWrapper
                        url={content.props.url || ''}
                        image={content.props.useImgV2 ? imageV2 : image}
                        onEvent={onEvent}
                    />
                ),
            }
        })
}

const InlineTheGameBannerWrapper: FC<{
    url: string
    image: any
    onEvent: (event: AllEvents) => void
}> = ({ url, image, onEvent }) => {
    const ITEM_NAME = 'The Game AFL 2025'
    const DEBUG_NAME = 'the-game-banner'
    const LIST_NAME = 'in-article-curated-promotion-card'
    const partialCard: Partial<CardInformation> = {
        index: 0,
        item_brand: undefined,
        item_category: 'in-article/promotions',
        item_category2: 'redirect',
        item_id: ITEM_NAME,
        item_name: ITEM_NAME,
        item_heading: ITEM_NAME,
        item_variant: false,
        price: 0.0,
    }

    const itemListCard: any = {
        ...partialCard,
        item_list_category: 'news',
        item_list_category2: 'active-promotion',
        item_list_id: 'in_article_promotional_card',
        item_list_name: LIST_NAME,
    }

    const bannerDebug = debug(DEBUG_NAME)

    const availableFunction = useCallback(() => {
        bannerDebug(
            DEBUG_NAME,
            `in frame and has been viewed with a size of ${1}`,
        )

        onEvent({
            type: DataLayerEventName.viewItemList,
            originator: LIST_NAME,
            payload: {
                cardInformation: [itemListCard],
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannerDebug, onEvent])

    const impressionAvailableRef = useImpressionAvailable({
        loading: false,
        available: availableFunction,
        debug: bannerDebug,
    })

    return (
        <StyledInlineTheGameBanner
            ref={impressionAvailableRef}
            onClick={() =>
                onEvent({
                    type: DataLayerEventName.selectItemEvent,
                    originator: ITEM_NAME,
                    payload: {
                        ...partialCard,
                        link_text: ITEM_NAME,
                        link_url: url,
                    },
                })
            }
        >
            <div>
                <ImageBanner
                    altText="The Game AFL 2025"
                    defaultSrc={image}
                    url={url}
                    verticalGutters={['unset', 'md']}
                />
            </div>
        </StyledInlineTheGameBanner>
    )
}
