import {
    createRegisterableComponent,
    ElectionDefinition,
} from '@news-mono/web-common'
import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PollieRater } from './PollieRater'

const queryClient = new QueryClient()

type PollieRaterRouteProps = {
    electionDefinition: ElectionDefinition
}
export const PollieRaterRegistration = createRegisterableComponent(
    'pollie-rater',
    (props: PollieRaterRouteProps, services) => {
        return (
            <QueryClientProvider client={queryClient}>
                <PollieRater onEvent={services.onEvent} {...props} />
            </QueryClientProvider>
        )
    },
)
