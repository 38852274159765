import { AppName } from './common'
import {
    PerthNowSections,
    SevenNewsSections,
    TheWestSections,
    TheNightlySections,
} from './sections'

export type Section =
    | keyof PerthNowSections
    | keyof TheWestSections
    | keyof SevenNewsSections
    | keyof TheNightlySections

type SectionMap = {
    perthnow: keyof PerthNowSections
    thewest: keyof TheWestSections
    sevennews: keyof SevenNewsSections
    thenightly: keyof TheNightlySections
    neutral: string
}

export const isValidSection = <T extends AppName>(
    appName: T,
    section: string,
): section is T extends 'neutral' ? string : SectionMap[T] => {
    switch (appName) {
        case 'perthnow':
            return section in PerthNowSections
        case 'sevennews':
            return section in SevenNewsSections
        case 'thewest':
            return section in TheWestSections
        case 'thenightly':
            return section in TheNightlySections
        default:
            return false
    }
}

/**
 * Returns true if section is `default` or `genwest`.
 *
 * @warning Only suitable for use on The West (and regionals)&mdash;check
 * using `config.appName === 'thewest` before calling.
 */
export const isWestRegionalSection = (section: string) => {
    if (section !== 'default' && section !== 'genwest') {
        return true
    }

    return false
}
