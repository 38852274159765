import { TheWestSection } from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    GetRouteAdTargeting,
    ElectionDefinition,
    PageType,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'

type CreateElectorateEmbedPageArgs = {
    electionDefinition: ElectionDefinition
    getAdTargeting: GetRouteAdTargeting
}

export const createElectorateEmbedPage = ({
    electionDefinition,
    getAdTargeting,
}: CreateElectorateEmbedPageArgs): PageType<TheWestSection> => {
    return {
        kind: 'page',
        heading: 'Election Embed Page',
        pageType: 'static',
        section: 'default',
        hideHeading: true,
        adTargeting: getAdTargeting('home', 'default', 'home'),
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'election-seat-embed-widget',
                            props: {
                                electionDefinition,
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
