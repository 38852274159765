import React, { ReactElement } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
    AppState,
    AuthenticationState,
    getLoginProvider,
    Product,
} from '@news-mono/web-common'
import { useSelector } from 'react-redux'
import { UserSettingProps } from '../../../user-setting'
import { useProduct } from '../../../__product'

export const queryClient = new QueryClient()

export interface CreateAccountPageCtaProps {
    userSettings?: UserSettingProps
    isFeatureEnabled: boolean

    LoginContent: () => ReactElement
    VerifyEmailContent: (props: { userId: string }) => ReactElement
    SaveNameContent: (props: {
        userId: string
        userEmail?: string
    }) => ReactElement
}

export const CreateAccountCTA: React.FC<CreateAccountPageCtaProps> = (
    props,
) => {
    return (
        <QueryClientProvider client={queryClient}>
            <CreateAccountCTAInternal {...props} />
        </QueryClientProvider>
    )
}

const CreateAccountCTAInternal = ({
    userSettings,
    isFeatureEnabled,
    LoginContent,
    VerifyEmailContent,
    SaveNameContent,
}: CreateAccountPageCtaProps) => {
    const {
        isLoggedIn,
        isLoading,
        emailVerified,
        hasUserName,
        wanUserId,
        auth0UserId,
        userEmail,
    } = useSelector<AppState, AuthenticationState>(
        (state) => state.authentication,
    )

    const userId = auth0UserId || wanUserId
    const isExternalAuthProvider = getLoginProvider(userId) !== 'Auth0'
    const product = useProduct()
    const isVerifyAccountCTAEnabled =
        product === Product.PerthNow ? isFeatureEnabled : true

    if (isLoading) return null

    if (!isLoggedIn) {
        return <LoginContent />
    }

    if (!emailVerified && isVerifyAccountCTAEnabled) {
        return <VerifyEmailContent userId={userId} />
    }

    if (!hasUserName && userSettings && !isExternalAuthProvider) {
        return <SaveNameContent userId={userId} userEmail={userEmail} />
    }

    return null
}
