import { createRenderTarget } from '../../render-target'
import { SevenNewsSection } from '../../__styling/settings/sections/sevennews'
import { TermsFooterWeb } from './TermsFooter.web'
import { Features } from '@news-mono/web-common'

export interface TermLink {
    name: string
    link: string
    feature?: Features
}

export interface TermsFooterProps {
    footerPartnerLinks?: TermLink[]
    footerCompanyLinks?: TermLink[]
    footerTermsLinks?:
        | TermLink[]
        | { [section in SevenNewsSection]: TermLink[] }
    connectLinks?: TermLink[]
    additionalTerms?: JSX.Element
    newslettersLinks?: TermLink[]
}

export const TermsFooter = createRenderTarget('TermsFooter', {
    web: TermsFooterWeb,
    amp: TermsFooterWeb,
    rss: TermsFooterWeb,
    preview: TermsFooterWeb,
    app: null,
})
TermsFooter.displayName = 'TermsFooter'
