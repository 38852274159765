import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import React from 'react'
import { HideOnMobile, HideOnDesktop } from './Logo.styled'
import { DataLayerEventName, GtmEvent, WebLink } from '@news-mono/web-common'
import styled from '@emotion/styled'

const horizontal = require('../../logos/FederalElection2025Logo/FederalElection2025LogoLarge.svg')
const stacked = require('../../logos/FederalElection2025Logo/FederalElection2025LogoStacked.svg')
export interface FederalElection2025LogoProps {
    className?: string
    large?: boolean
    size: 'small' | 'large' | 'stacked' | 'switch'
    fireClickEvent?: () => void
}

export function FederalElection2025Logo({
    size,
    fireClickEvent,
}: FederalElection2025LogoProps) {
    const altText = '2025 Federal Election'
    if (size === 'switch') {
        return (
            <>
                <HideOnMobile>
                    <img
                        style={{ width: '221px' }}
                        alt={altText}
                        src={horizontal}
                    ></img>
                </HideOnMobile>
                <HideOnDesktop>
                    <img alt={altText} src={stacked}></img>
                </HideOnDesktop>
            </>
        )
    }
    let logo: ReactJSXElement
    switch (size) {
        case 'small':
            logo = (
                <img
                    style={{ width: '100%', maxWidth: '221px' }}
                    alt={altText}
                    src={horizontal}
                ></img>
            )
            break
        case 'large':
            logo = (
                <img
                    style={{ width: '100%', maxWidth: '443px' }}
                    alt={altText}
                    src={horizontal}
                ></img>
            )
            break
        case 'stacked':
            logo = <img alt={altText} src={stacked}></img>
            break
    }
    return (
        <StyledWebLink
            aria-label="Go to the Federal Elections 2025 Homepage"
            to={'/politics/federal-election-2025'}
            onClick={fireClickEvent}
        >
            {logo}
        </StyledWebLink>
    )
}

const StyledWebLink = styled(WebLink)({
    display: 'flex',
    height: 'fit-content',
    width: 'fit-content',
})
