import { TheWestSection } from '@news-mono/common'
import { StaticRoute, StaticRoutes } from '@news-mono/web-common'
import { getSwgLandingPage } from '../regionals/get-swg-landing-page'

const bannerImage = {
    x1: require('./assets/2023-SWG-Uni-Student-Offer-Tablet-700x400px-min.jpg'),
    x2: require('./assets/2023-SWG-Uni-Student-Comp-Banner-min.jpg'),
}

const createUniversityOfferPage: StaticRoute<TheWestSection> = (services) => {
    if (!services.store.getState().toggles['university-offering']) return null
    return getSwgLandingPage({
        section: 'default',
        getAdTargeting: services.getAdTargeting,
        toggles: services.store.getState().toggles,
        options: {
            heading:
                'The West Australian is proud to offer you this exclusive offer of a 12 month digital subscription for only $1 per month thanks to our partnership with Google.',
            bannerImage,
            modifier: 'university-offer',
            headerFontSize: 59,
            noIndex: true,
        },
    })
}

export const getUniversityOfferRouteInfo: StaticRoutes<TheWestSection> = {
    '/university-offer': createUniversityOfferPage,
}
