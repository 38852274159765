import styled from '@emotion/styled'
import { breakpoint } from '../../__styling'

export const HideOnDesktop = styled('div')({
    [breakpoint('xxs')]: {
        display: 'none',
    },
})
export const HideOnMobile = styled('div')({
    display: 'none',
    [breakpoint('xxs')]: {
        display: 'block',
    },
})
