import {
    createRegisterableComponent,
    ElectionDefinition,
} from '@news-mono/web-common'
import React from 'react'
import { TheSeatEmbedCollectionWidget } from './TheSeatEmbedCollectionWidget'

export interface TheSeatEmbedCollectionWidgetRouteProps {
    electionDefinition: ElectionDefinition
}

export const SeatEmbedWidgetCollectionRegistration =
    createRegisterableComponent(
        'election-seat-embed-collection-widget',
        (props: TheSeatEmbedCollectionWidgetRouteProps, services) => {
            return (
                <TheSeatEmbedCollectionWidget
                    imageBaseUrl={services.config.imageBaseUrl}
                    {...props}
                />
            )
        },
    )
