import loadable from '@loadable/component'
import { AllEvents } from '@news-mono/web-common'
import React from 'react'
import { FeatureToggle } from '../../feature-toggling'

const LazyFederalElectionInArticleBannerComponent = loadable(
    () => import('./FederalElectionInArticleBanner'),
)

export const LazyFederalElectionInArticleBanner = (props: {
    onEvent: (event: AllEvents) => void
}) => {
    return (
        <FeatureToggle
            feature="federal-election-2022-live-blog-drivers"
            on={() => (
                <LazyFederalElectionInArticleBannerComponent
                    onEvent={props.onEvent}
                />
            )}
        />
    )
}
