import { createRegisterableComposition } from '@news-mono/web-common'
import React from 'react'
import { ElectionFilterContextProvider } from './ElectionFilterContext'

export const ElectionFilterContextRegistration =
    createRegisterableComposition<'children'>()(
        'election-filter-data-context',
        ({ children }) => (
            <ElectionFilterContextProvider>
                {children}
            </ElectionFilterContextProvider>
        ),
    )
