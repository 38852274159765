import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { textMixin } from '../../../../mixins/TextMixin/TextMixin'
import { breakpoint, PerthNowThemeConfig } from '../../../../__styling'
import { WardenButton } from '../../../warden'

export const CreateAccountButton = styled(WardenButton)({
    [breakpoint('sm')]: {
        alignSelf: 'center',
    },
})

export const LoginLink = styled.a(({ theme }) => [
    {
        color: tokens.perthnow.colors.brand,

        [':hover']: {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    textMixin<PerthNowThemeConfig>(theme, 'Paragraph/M/Sans/Medium'),
])
