import { useTheme } from '@emotion/react'
import { AppState, entitledToViewContent, Issue } from '@news-mono/web-common'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { addListener, removeListener } from '../../../__helpers'
import { EditionGroupModal } from './EditionGroupModal'
import { EpaperCollection } from './EpaperCollection'
import { StyledLatestEditionHeading } from './LatestEdition.styled'
import { SubscriberModal } from './SubscriberModal'

export interface LatestEditionProps {
    latestEdition: Issue
    allEditions: Issue[]
    onEvent: (event: any) => void
}

export const LatestEdition: React.FC<LatestEditionProps> = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [showEditionGroupModal, setShowEditionGroupModal] = useState(false)
    const [editionData, setEditionData] = useState<Issue>()
    const innerModalRef = useRef(null)

    const isEntitled = entitledToViewContent(
        {
            level: 'subscriber',
        },
        useSelector((state: AppState) => state.authentication),
    )

    const handleClickOutsideModal = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.currentTarget !== e.target) return
        if (e.target !== innerModalRef.current) {
            setShowModal(false)
            setShowEditionGroupModal(false)
        }
    }

    const handleEscapeKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.type === 'keydown' && e.key === 'Escape') {
            setShowModal(false)
            setShowEditionGroupModal(false)
        }
    }

    const handleTabKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
        if (
            e.type === 'keydown' &&
            e.key === 'Tab' &&
            e.target === faqLinkRef.current
        ) {
            e.preventDefault()
            crossIconRef.current?.focus()
        }
    }

    const crossIconRef = useRef<HTMLButtonElement>(null)
    const editionGroupCrossIconRef = useRef<HTMLButtonElement>(null)
    const faqLinkRef = useRef<HTMLAnchorElement>(null)

    useEffect(() => {
        crossIconRef.current?.focus()
    }, [showModal])

    useEffect(() => {
        editionGroupCrossIconRef.current?.focus()
    }, [showEditionGroupModal])

    useEffect(() => {
        addListener('keydown', handleEscapeKeyPress)
        addListener('keydown', handleTabKeyPress)
        return () => {
            removeListener('keydown', handleEscapeKeyPress)
            removeListener('keydown', handleTabKeyPress)
        }
    }, [])

    const handleOnCardClick = (link?: string, editionData?: Issue) => {
        //if paper is premium and user is not entitled, show modal
        if (props.latestEdition.isPremium && !isEntitled) {
            setShowModal(true)
            return
        }
        // If entitled, then go to the epaper
        if (editionData) {
            //open the edition group modal
            setEditionData(editionData)
            setShowEditionGroupModal(true)
            return
        } else {
            window.open(link, '_blank')
        }
    }

    const theme = useTheme()

    return (
        <>
            <SubscriberModal
                showModal={showModal}
                setShowModal={setShowModal}
                handleClickOutsideModal={handleClickOutsideModal}
                innerModalRef={innerModalRef}
            />

            {editionData && (
                <EditionGroupModal
                    showEditionGroupModal={showEditionGroupModal}
                    setShowEditionGroupModal={setShowEditionGroupModal}
                    handleClickOutsideModal={handleClickOutsideModal}
                    innerModalRef={innerModalRef}
                    crossIconRef={editionGroupCrossIconRef}
                    editionData={editionData}
                />
            )}

            <StyledLatestEditionHeading>
                {'Latest editions'}
            </StyledLatestEditionHeading>
            <EpaperCollection
                openEReaderOnCardClick={true}
                showMastheadName={false}
                issues={props.allEditions}
                onCardClick={(link, editionData) =>
                    handleOnCardClick(link, editionData)
                }
                border={true}
                readersAlsoLove={false}
                onEvent={props.onEvent}
            />
        </>
    )
}
