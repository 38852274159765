import { useTheme } from '@emotion/react'
import loadable from '@loadable/component'
import {
    coralTNCreateAccountCTAProps,
    isCorporateAccount,
    TNPageCreateAccountCTA,
    UserSettingProps,
} from '@news-mono/component-library'
import {
    AppState,
    CommentEvent,
    ConfigurationContext,
    EntitledToView,
    getLoginProvider,
    Product,
    THEWEST_ALL,
    THEWEST_COMMENTS,
    useFeature,
} from '@news-mono/web-common'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MessageBanner } from '../banners/MessageBanner/MessageBanner'
import { ContentSeparator } from '../content/ContentSeparator/ContentSeparator.styled'
import { StyledVideoErrorBoundary } from '../content/Video/Video.styled'
import { LoggerContext } from '../diagnostics/LoggerContext'
import { ErrorBoundary } from '../errors/ErrorBoundary/ErrorBoundary'
import { createRenderTarget } from '../render-target'
import { TextLink } from '../typography/TextLink/TextLink'
import { PNCommentsCTA } from '../user-registration/pn-warden/pn-comments-cta/PNCommentsCTA'
import { ThemeMargins } from '../__styling/settings/metrics'
import { StyledActionBox, StyledContainer, StyledHeading } from './Coral.styled'
import CoralComments from './Coral.web'

export const LazyCoral = loadable(() => import('./Coral.web'))

const CoralRender = createRenderTarget('Coral', {
    web: LazyCoral,
    amp: null,
    rss: null,
    preview: null,
    app: LazyCoral,
})

export interface CoralProps {
    storyID?: string
    storyURL?: string
    articleCommentsEnabled?: boolean
    horizontalSpacing?: keyof ThemeMargins
    onCommentEvent: (event: CommentEvent) => void
    userSettings: UserSettingProps
}

export function getCoralStoryId(
    section: string,
    publicHostname: string,
    articleId: string,
) {
    return section !== 'default'
        ? `${publicHostname}-${section}-${articleId}`
        : `${publicHostname}-${articleId}`
}

export const Coral: React.FC<CoralProps> = ({
    storyID,
    storyURL,
    articleCommentsEnabled,
    horizontalSpacing,
    onCommentEvent,
    userSettings,
}) => {
    const { coralUrl, publicUrl } = useContext(ConfigurationContext)
    const logger = useContext(LoggerContext)

    const { subscriptionType, isEntitledChecked, entitlements } = useSelector(
        (state: AppState) => state.authentication,
    )

    const authState = useSelector((state: AppState) => state.authentication)

    const { kind } = useTheme()
    const areCommentsEnabled = useFeature('comments')
    const isNewPNAuth0Enabled = useFeature('perthnow-new-auth-tenant')

    const isExternalAuthProvider =
        getLoginProvider(authState.auth0UserId || authState.wanUserId) !==
        'Auth0'

    const canShowComments = areCommentsEnabled && articleCommentsEnabled

    const shouldShowComments = (product: Product) => {
        if (product === Product.PerthNow || product === Product.TheNightly) {
            return canShowComments
        }

        if (product === Product.TheWest) {
            return (
                canShowComments &&
                !isCorporateAccount(subscriptionType) &&
                (!authState.isLoggedIn ||
                    entitlements.includes(THEWEST_ALL) ||
                    entitlements.includes(THEWEST_COMMENTS))
            )
        }

        return false
    }

    const [storyUrlLoaded, setStoryUrlLoaded] = useState(false)
    const [storyUrl, setStoryUrl] = useState('')

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setStoryUrl(window.location.href.split('?')[0].split('#')[0])
            setStoryUrlLoaded(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEntitledChecked])

    if (!shouldShowComments(kind) || typeof coralUrl === 'undefined') {
        return null
    }

    switch (kind) {
        case Product.TheNightly: {
            return (
                <>
                    <StyledHeading>Comments</StyledHeading>
                    <StyledActionBox>
                        <TNPageCreateAccountCTA
                            {...coralTNCreateAccountCTAProps}
                            userSettings={userSettings}
                        />
                    </StyledActionBox>
                    <StyledContainer>
                        <CoralRender
                            coralScriptUrl={coralUrl}
                            storyID={storyID}
                            storyURL={storyURL}
                            horizontalSpacing={horizontalSpacing}
                            onCommentEvent={onCommentEvent}
                            hostName={publicUrl}
                            useContentSeperator={false}
                            canComment={
                                authState.hasUserName || isExternalAuthProvider
                            }
                        />
                    </StyledContainer>
                </>
            )
        }
        case Product.PerthNow: {
            return (
                <>
                    <PNCommentsCTA
                        userSettings={userSettings}
                        isFeatureEnabled={isNewPNAuth0Enabled}
                    />
                    <CoralRender
                        coralScriptUrl={coralUrl}
                        storyID={storyID}
                        storyURL={storyURL}
                        horizontalSpacing={horizontalSpacing}
                        onCommentEvent={onCommentEvent}
                        hostName={publicUrl}
                        useContentSeperator={false}
                        canComment={
                            authState.hasUserName || isExternalAuthProvider
                        }
                    />
                </>
            )
        }
        default: {
            return storyUrlLoaded ? (
                <EntitledToView
                    requiredAccess={{ level: 'subscriber' }}
                    entitled={() => (
                        <ErrorBoundary
                            log={logger}
                            renderError={() => (
                                <StyledVideoErrorBoundary>
                                    <p>
                                        An error has occurred with loading
                                        comments for this article.
                                    </p>
                                </StyledVideoErrorBoundary>
                            )}
                        >
                            {/* Opt to not lazily load this for debugging INC-251 */}
                            <CoralComments
                                coralScriptUrl={coralUrl}
                                storyID={storyID}
                                onCommentEvent={onCommentEvent}
                                horizontalSpacing={horizontalSpacing}
                                hostName={publicUrl}
                                useContentSeperator={true}
                                storyURL={storyURL ? storyURL : storyUrl}
                            />
                        </ErrorBoundary>
                    )}
                    notEntitled={() => (
                        <React.Fragment>
                            <MessageBanner
                                icon="comment"
                                id="coral_thread"
                                signupOrigin="article-comments"
                                onEvent={onCommentEvent}
                            >
                                <p>
                                    To comment on this story and join the
                                    conversation, subscribe to The West
                                    Australian’s{' '}
                                    <TextLink href="https://subscriber.thewest.com.au/subscribenow/digital?utm_source=TheWest&utm_medium=Article-Block&utm_campaign=Comments&utm_term=subscribe&utm_content=login">
                                        Everyday Digital package
                                    </TextLink>
                                    .
                                </p>
                            </MessageBanner>
                            <ContentSeparator verticalBottomSpacing="lg" />
                        </React.Fragment>
                    )}
                />
            ) : (
                <></>
            )
        }
    }
}
