import { cx } from '@emotion/css'
import {
    DataLayerEventName,
    ElectionDefinition,
    NavClickedEvent,
    useElectionToggle,
} from '@news-mono/web-common'
import React, { Fragment, useCallback } from 'react'
import { breakpoint } from '../../__styling'
import {
    NavigationContainer,
    StyledPicture,
    StyledLink,
    LogoLink,
    Heading,
} from './TNElectionNavigation.styled'
import { useProduct } from '../../__product'

export type TNElectionNavigationProps = {
    electionDefinition: ElectionDefinition
    activePage?:
        | 'updates'
        | 'electorate-results'
        | 'party-totals'
        | 'pollie-rater'
    headingLevel?: 'h1' | 'h2'
    onEvent?: (event: NavClickedEvent) => void
    className?: string
}

export const TNElectionNavigation = ({
    electionDefinition,
    activePage,
    headingLevel,
    onEvent,
    className,
}: TNElectionNavigationProps) => {
    const isEnabled = useElectionToggle(
        electionDefinition.electionId,
        'election-navigation',
    )
    const isLandingPageEnabled = useElectionToggle(
        electionDefinition.electionId,
        'election-landing-page-route',
    )
    const isElectorateResultsPageEnabled = useElectionToggle(
        electionDefinition.electionId,
        'election-electorate-results-route',
    )
    const isPartyTotalsPageEnabled = useElectionToggle(
        electionDefinition.electionId,
        'election-party-totals-route',
    )

    const isPollieRaterPageEnabled = useElectionToggle(
        electionDefinition.electionId,
        'election-pollie-rater',
    )

    const { electionId, logo } = electionDefinition
    const updatesLink = `/politics/${electionId}`
    const electoratesLink = `${updatesLink}/electorates`
    const partyTotalsLink = `${updatesLink}/party-totals`
    const pollieRaterLink = `${updatesLink}/pollie-rater`

    const handleUpdatesNavigation = useCallback(
        () =>
            onEvent?.(
                getNavEvent({ navText: 'Updates', navLink: updatesLink }),
            ),
        [onEvent, updatesLink],
    )

    const handleElectorateResultsNavigation = useCallback(
        () =>
            onEvent?.(
                getNavEvent({
                    navText: 'Electorate Results',
                    navLink: electoratesLink,
                }),
            ),
        [onEvent, electoratesLink],
    )

    const handlePartyTotalsNavigation = useCallback(
        () =>
            onEvent?.(
                getNavEvent({
                    navText: 'Party Totals',
                    navLink: partyTotalsLink,
                }),
            ),
        [onEvent, partyTotalsLink],
    )

    if (!isEnabled) {
        return null
    }

    const picture = (
        <StyledPicture aria-label={'TheNightly Election Logo'}>
            <source
                media={breakpoint('sm').replace('@media ', '')}
                srcSet={logo.desktop}
            />
            <img src={logo.mobile} alt={logo.alt} />
        </StyledPicture>
    )

    return (
        <NavigationContainer className={className}>
            <Heading as={headingLevel ?? Fragment}>
                {isLandingPageEnabled ? (
                    <LogoLink
                        to={updatesLink}
                        aria-label={'Go to the election landing page.'}
                        onClick={handleUpdatesNavigation}
                    >
                        {picture}
                    </LogoLink>
                ) : (
                    picture
                )}
            </Heading>
            {isLandingPageEnabled && (
                <StyledLink
                    to={updatesLink}
                    className={cx([activePage === 'updates' && 'active'])}
                    onClick={handleUpdatesNavigation}
                >
                    Updates
                </StyledLink>
            )}
            {isElectorateResultsPageEnabled && (
                <StyledLink
                    to={electoratesLink}
                    className={cx([
                        activePage === 'electorate-results' && 'active',
                    ])}
                    onClick={handleElectorateResultsNavigation}
                >
                    Electorate Results
                </StyledLink>
            )}
            {isPartyTotalsPageEnabled && (
                <StyledLink
                    to={partyTotalsLink}
                    className={cx([activePage === 'party-totals' && 'active'])}
                    onClick={handlePartyTotalsNavigation}
                >
                    Party Totals
                </StyledLink>
            )}
            {isPollieRaterPageEnabled && (
                <StyledLink
                    to={pollieRaterLink}
                    className={cx([activePage === 'pollie-rater' && 'active'])}
                    onClick={handlePartyTotalsNavigation}
                    forceInternalSSR
                >
                    Pollie Rater
                </StyledLink>
            )}
        </NavigationContainer>
    )
}

type GetNavEventArgs = { navText: string; navLink: string }

/**
 * Creates a nav event for TNElectionNavigation.
 */
const getNavEvent = ({
    navText,
    navLink,
}: GetNavEventArgs): NavClickedEvent => ({
    type: DataLayerEventName.navClicked,
    originator: 'TNElectionNavigation',
    payload: {
        navName: 'ElectionNavigation',
        navLocation: 'OnPage',
        navText,
        navLink,
    },
})
