import {
    createRegisterableComponent,
    ElectionDefinition,
} from '@news-mono/web-common'
import React from 'react'
import { TheSeatEmbedWidget } from './TheSeatEmbedWidget'
import { SeatData } from '../data'

export interface TheSeatEmbedWidgetRouteProps {
    electionDefinition: ElectionDefinition
    mainRef?: React.RefObject<HTMLDivElement>
    fireClickEvent?: () => void
    electorateName?: string | null
    providedData?: SeatData
    isState?: boolean
    imageBaseUrl?: string
}

export const SeatEmbedWidgetRegistration = createRegisterableComponent(
    'election-seat-embed-widget',
    (props: TheSeatEmbedWidgetRouteProps, services) => {
        return (
            <TheSeatEmbedWidget
                {...props}
                imageBaseUrl={services.config.imageBaseUrl}
            />
        )
    },
)
