import {
    AllEvents,
    ConfigurationContext,
    DataLayerEventName,
    ElectionDefinition,
    LoadedElectionDefinition,
    queries,
    useQueryParams,
} from '@news-mono/web-common'
import { useQuery } from '@tanstack/react-query'
import React, { PropsWithChildren, useContext, useMemo } from 'react'
import { CustomPageSharing } from '../../../buttons/SharingCustomPage/CustomPageSharing'
import {
    ElectionDataFetchError,
    ElectionFilter,
    ElectionText,
} from '../components'
import { ElectionSearchInput } from '../components/ElectionSearch'
import {
    filterSeatsByState,
    getSeatsData,
    searchSeatsOrCandidates,
    SeatData,
    sortSeats,
    transformAreaData,
} from '../data'
import { FullElectorateCard } from '../FullElectorateCard/FullElectorateCard'
import {
    TheSeatsDivider,
    TheSeatsNoResultsText,
} from '../TheSeatsWidget/TheSeatsWidget.styled'
import {
    StyledElectorateTitle,
    StyledElectorateWidgetContainer,
    StyledTitleContainer,
} from './FullElectorateWidget.styled'
import { ElectionPredictionDisclaimer } from '../components/ElectionPredictionDisclaimer'
import { ElectionFilterContext } from '../../../contexts'
import { useProduct } from '../../../__product'

export interface FullElectorateWidgetProps {
    electionDefinition: ElectionDefinition
    onEvent: (event: AllEvents) => void
    imageBaseUrl?: string
}

const getSeatDataFromDefinition = (
    loadedDefinition: LoadedElectionDefinition,
    imageBaseURL: string | undefined,
) => {
    // Check for search query param on load

    if (loadedDefinition.electionData.data) {
        const transformedData = transformAreaData(
            loadedDefinition.electionData.data,
        )
        const seatData = getSeatsData(transformedData, imageBaseURL, 144)

        return seatData
    } else {
        console.warn(
            'No data was found in the loaded election definition for FullElectorateWidget!',
        )
    }
}

export const FullElectorateWidget = ({
    electionDefinition,
    onEvent,
    imageBaseUrl,
}: FullElectorateWidgetProps): JSX.Element => {
    const { search, sort, state } = useContext(ElectionFilterContext)
    const { pathname, queryParams } = useQueryParams()
    const { value: sortValue } = sort
    const { value: searchValue } = search
    const { value: stateValue } = state

    const config = useContext(ConfigurationContext)
    const result = useQuery(
        queries['election-api'].definition({
            initialDefinition: electionDefinition,
            electionAPI: config.electionApi,
            caller: config.apiCallerHeader,
        }),
    )

    const seatData = result.isSuccess
        ? getSeatDataFromDefinition(result.data, imageBaseUrl)
        : undefined

    let filteredSeatData: SeatData[] | undefined
    if (seatData) {
        //filter for search
        filteredSeatData = searchSeatsOrCandidates(seatData, searchValue)
        //filter for state
        filteredSeatData = filterSeatsByState(filteredSeatData, stateValue)
    }

    // Sort data
    const sortedData = useMemo(
        () => sortSeats(filteredSeatData, sortValue) || [],
        [filteredSeatData, sortValue],
    )

    // Loading State!
    if (!seatData) {
        return (
            <FullElectorateWidgetWrapper headingComponents={[]}>
                {Array(5) // Render 5 skeletons while loading
                    .fill(0)
                    .map((_, index) => (
                        <FullElectorateCard
                            key={index}
                            isLoading
                            onEvent={onEvent}
                        />
                    ))}
            </FullElectorateWidgetWrapper>
        )
    }

    // Error State! (data has loaded, but there is no data)
    if (result.isError || !result.data) {
        return (
            <FullElectorateWidgetWrapper headingComponents={[]}>
                <ElectionDataFetchError />
            </FullElectorateWidgetWrapper>
        )
    }

    const electionType = result.data.electionData.config?.electionType

    const headingComponents = [
        <CustomPageSharing
            text={`${result.data.electionData.config?.electionTitle} electorate results | The West Australian`}
            shareOptions={[
                'facebook',
                'linkedin',
                'twitter',
                'email',
                'clipboard',
            ]}
            loading={false}
            onEvent={onEvent}
        />,
        <ElectionSearchInput onEvent={onEvent} electionFilter={search} />,
        <ElectionFilter
            headingText="Sort electorates by"
            dataLayerEventOriginator="ElectionSort"
            onEvent={onEvent}
            dataLayerEventName={DataLayerEventName.searchFilter}
            electionFilter={sort}
        />,
        electionType === 'federal' ? (
            <ElectionFilter
                headingText="State"
                dataLayerEventOriginator="ElectionStateFilter"
                onEvent={onEvent}
                dataLayerEventName={DataLayerEventName.searchFilter}
                electionFilter={state}
            />
        ) : (
            <></>
        ),
        <TheSeatsDivider hideOnDesktop hasMargin />,
    ]

    // No Search Results State!
    if (searchValue && sortedData && sortedData.length <= 0) {
        return (
            <FullElectorateWidgetWrapper headingComponents={headingComponents}>
                <TheSeatsNoResultsText>
                    No results for ‘{searchValue}’
                </TheSeatsNoResultsText>
                <TheSeatsDivider />
            </FullElectorateWidgetWrapper>
        )
    }

    return (
        <FullElectorateWidgetWrapper headingComponents={headingComponents}>
            {sortedData.map((seat) => (
                <FullElectorateCard
                    key={seat.seatId}
                    seat={seat}
                    onEvent={onEvent}
                    isExpanded={showExpanded(queryParams, seat.seatName)}
                />
            ))}
        </FullElectorateWidgetWrapper>
    )
}

const FullElectorateWidgetWrapper = ({
    headingComponents,
    children,
}: PropsWithChildren<{ headingComponents: JSX.Element[] }>) => {
    const product = useProduct()
    return (
        <StyledElectorateWidgetContainer>
            {product !== 'thenightly' && (
                <StyledTitleContainer>
                    <StyledElectorateTitle>
                        Electorate Results
                    </StyledElectorateTitle>
                    <ElectionText>
                        Live tracking of the electorate results as they are
                        called.
                    </ElectionText>
                    {headingComponents}
                </StyledTitleContainer>
            )}
            {children}
            <ElectionPredictionDisclaimer />
        </StyledElectorateWidgetContainer>
    )
}

/** Returns true if the `search` query param is a match against the `seatName` query param & the `display` query param is equal to `expanded` */
const showExpanded = (queryParams: URLSearchParams, seatName: string) => {
    const search = queryParams.get('search')
    const display = queryParams.get('display')
    if (!search || !display) return false
    if (search === seatName.toLowerCase() && display === 'expanded') {
        return true
    }
    return false
}
