import {
    regionalSiteConfig,
    TheWestRegionalSections,
    TheWestSection,
} from '@news-mono/common'
import { metrics } from '@news-mono/component-library'
import {
    noIndexMeta,
    pageIsUnavailable,
    PremiumPublicationsSet,
    StaticRoute,
    StaticRoutes,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { createEPaperPage, createEPaperSubscribePage } from '../ePaper/index'
import { recommended } from '../errors'
import { getOurPapersRoute } from './get-our-papers'
import { getRegionalContactUsPage } from './get-regional-contact-us-page'
import { getRegionalHomepage } from './get-regional-homepage'
import { getSwgLandingPage } from './get-swg-landing-page'

export interface RegionalRouteConfig {
    mastheadTopic: string
    regionalTopic: string
    title: string
    description: string
    socialDescription: string
    publicationSource: string
}

function getRegionalSiteStaticRoutes(
    getStaticRoutes: (
        regionalSection: Exclude<TheWestSection, 'default'>,
        config: RegionalRouteConfig,
    ) => StaticRoutes<TheWestSection>,
): StaticRoutes<TheWestSection> {
    return Object.keys(regionalSiteConfig).reduce<StaticRoutes<TheWestSection>>(
        (routes, regionalSection) => {
            const section = regionalSection as TheWestRegionalSections
            const config = regionalSiteConfig[section]
            const siteRoutes = getStaticRoutes(section, config)
            return {
                ...routes,
                ...siteRoutes,
            }
        },
        {},
    )
}

export const regionalRoutes: StaticRoutes<TheWestSection> =
    getRegionalSiteStaticRoutes((regionalSection, regionalConfig) => {
        const {
            mastheadTopic,
            regionalTopic,
            title,
            description,
            socialDescription,
        } = regionalConfig

        const homepage: StaticRoute<TheWestSection> = (services) => {
            return getRegionalHomepage(services, {
                section: regionalSection,
                mastheadTopic,
                regionalTopic,
                title,
                description,
                socialDescription,
            })
        }

        const contactUsPage: StaticRoute<TheWestSection> = (services) => {
            return getRegionalContactUsPage(services, {
                section: regionalSection,
                mastheadName: title,
            })
        }

        const ourPapersPage: StaticRoute<TheWestSection> = (services) => {
            return getOurPapersRoute({
                section: regionalSection,
                getAdTargeting: services.getAdTargeting,
            })
        }

        const subscribeWithGoogleLandingPage: StaticRoute<TheWestSection> = (
            services,
        ) => {
            const toggles = services.store.getState().toggles

            if (toggles['subscribe-with-google']) {
                return getSwgLandingPage({
                    section: regionalSection,
                    getAdTargeting: services.getAdTargeting,
                    toggles: toggles,
                })
            }
            return null
        }

        const regionalPageUnavailable: StaticRoute<TheWestSection> = (
            services,
        ) => {
            return {
                kind: 'page',
                heading: 'Page is Unavailable',
                pageMeta: {
                    // Should fix the crawler problems
                    meta: [noIndexMeta],
                },
                // TODO: Look into how optimised SSR affects this
                pageType: 'error',
                hideHeading: true,
                adTargeting: services.getAdTargeting(
                    'error',
                    regionalSection,
                    'page-is-unavailable',
                ),
                section: regionalSection,
                compositions: [
                    layout.composition({
                        type: 'thor',
                        props: {
                            containerWidth:
                                metrics.thewest.siteMetrics.mainContentWidth,
                            horizontalGutters: true,
                        },
                        contentAreas: {
                            main: [
                                layout.component({
                                    type: 'breadcrumb',
                                    props: {
                                        title: 'Page Unavailable',
                                        verticalSpacing: 'md',
                                    },
                                }),
                                layout.component({
                                    type: 'west-page-is-unavailable',
                                    props: {},
                                }),
                                recommended,
                            ],
                        },
                    }),
                ],
            }
        }

        const ePaperPage: StaticRoute<TheWestSection> = (services) => {
            return createEPaperPage(
                services,
                regionalSection as TheWestRegionalSections,
                regionalConfig.title,
            )
        }

        const ePaperSubscribePage: StaticRoute<TheWestSection> = (services) => {
            const isPremium =
                PremiumPublicationsSet[
                    regionalSection === 'genwest' ? 'default' : regionalSection
                ]
            if (isPremium) {
                return createEPaperSubscribePage(
                    services,
                    regionalSection as TheWestRegionalSections,
                    regionalConfig.title,
                )
            }
            return null
        }

        return {
            [`/${regionalSection}`]: homepage,
            [`/${regionalSection}/contact`]: contactUsPage,
            [`/${regionalSection}/our-papers`]: ourPapersPage,
            [`/${regionalSection}/subscribewithgoogle`]:
                subscribeWithGoogleLandingPage,
            [`/${regionalSection}${pageIsUnavailable}`]:
                regionalPageUnavailable,
            [`/${regionalSection}/editions`]: ePaperPage,
            [`/albany-advertiser/editions/the-extra`]: (services) =>
                createEPaperPage(services, 'the-extra', 'The Extra'),
            [`/${regionalSection}/editions/subscribe`]: ePaperSubscribePage,
        }
    })
