import styled from '@emotion/styled'
import { WebLink } from '@news-mono/web-common'
import { Fragment } from 'react'
import { textMixin } from '../../mixins/TextMixin/TextMixin'
import { breakpoint, calcRem, TheNightlyThemeConfig } from '../../__styling'

export const NavigationContainer = styled.nav(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',

    width: '100%',

    gap: calcRem(16),
    padding: calcRem(8, 24),

    borderWidth: 0,
    borderBottomWidth: calcRem(1),
    borderStyle: 'solid',
    borderColor: theme.colors.borders.secondary,

    overflowX: 'auto',

    transition: 'padding 0.25s, margin 0.25s, gap 0.25s',

    [breakpoint('sm')]: {
        gap: 24,
        padding: calcRem(16, 32),
    },

    [breakpoint('lg')]: {
        padding: calcRem(16, 0),
    },
}))

export const StyledLink = styled(WebLink)(({ theme }) => [
    textMixin<TheNightlyThemeConfig>(theme, 'body-alt'),
    {
        color: 'inherit',
        textDecoration: 'none',
        whiteSpace: 'nowrap',

        ':hover': {
            textDecoration: 'underline',
        },

        '&.active': {
            fontWeight: 'bold',

            ':hover': {
                textDecoration: 'none',
            },
        },
    },
])

export const LogoLink = styled(WebLink)({
    display: 'flex',
})

export const StyledPicture = styled.picture({
    display: 'flex',
})

export const Heading = styled.h1({
    display: 'flex',

    margin: 0,
})
