import {
    createRegisterableComponentWithData,
    CurationAndCurationMetadataLoader,
} from '@news-mono/web-common'
import React from 'react'
import { BreakingNews } from '../../content/BreakingNews/BreakingNews'
import { BreakingNewsThemeName } from './BreakingNews.styled'

export interface BreakingNewsRouteProps {
    removeBottomMargin?: boolean
    isElectionPageRoute?: boolean
    isHomePageRoute?: boolean
}

export const BreakingNewsRegistration = createRegisterableComponentWithData(
    'breaking-news',
    CurationAndCurationMetadataLoader,
    (
        {
            removeBottomMargin,
            isElectionPageRoute = false,
            isHomePageRoute = false,
        }: BreakingNewsRouteProps,
        data,
        services,
    ) => {
        // Ensure that data result length is > 0 as it can be either 0 or 1
        if (data.loaded && data.result.articles.length > 0) {
            return (
                <BreakingNews
                    item={data.result.articles[0]}
                    titlePrefix={data.result.metadata['breaking-news-title']}
                    breakingNewsTheme={
                        data.result.metadata['breaking-news-theme']
                    }
                    onEvent={services.onEvent}
                    removeBottomMargin={removeBottomMargin}
                    isElectionPageRoute={isElectionPageRoute}
                    isHomePageRoute={isHomePageRoute}
                />
            )
        }
        return null
    },
)
