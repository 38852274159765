import {
    regionalSectionLiveUrls,
    TheWestRegionalSections,
    TheWestSection,
} from '@news-mono/common'
import {
    Section,
    TheWestNavigation,
    TheWestNavItem,
} from '@news-mono/component-library'
import { NavItem, QuickLinks, StaticNavRouteHints } from '@news-mono/web-common'
import { TheWestSections } from '@west-australian-newspapers/publication-types'

const theWestSection = TheWestSections.default

/** These are route hints for items in the static navigation (nav & sections currently), so they can render a skeleton
 *
 * It is separate to the navigation because it's only needed for items which are not also
 * in our staticRoutes
 *
 * Future: When we load a page, we could look at it's section nav and load this info on the fly?
 */
export const staticNavRouteHints: StaticNavRouteHints = {
    '/news/wa': {
        routeKind: 'topic',
        topic: {
            id: 'news/wa',
            title: 'WA News',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'WA News',
        },
        publicationKind: 'article',
    },
    '/news/australia': {
        routeKind: 'topic',
        topic: {
            id: 'news/australia',
            title: 'Australia',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Australia',
        },
        publicationKind: 'article',
    },
    '/news/world': {
        routeKind: 'topic',
        topic: {
            id: 'news/world',
            title: 'World News',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'World News',
        },
        publicationKind: 'article',
    },
    '/news/7-news-perth': {
        routeKind: 'topic',
        topic: {
            id: 'news/7-news-perth',
            title: '7 News Perth',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: '7 News Perth',
        },
        publicationKind: 'article',
    },
    '/news/offbeat': {
        routeKind: 'topic',
        topic: {
            id: 'news/offbeat',
            title: 'Offbeat',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Offbeat',
        },
        publicationKind: 'article',
    },
    '/news/aviation': {
        routeKind: 'topic',
        topic: {
            id: 'news/aviation',
            title: 'Aviation',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Aviation',
        },
        publicationKind: 'article',
    },
    '/news/crime': {
        routeKind: 'topic',
        topic: {
            id: 'news/crime',
            title: 'Crime',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Crime',
        },
        publicationKind: 'article',
    },
    '/news/court-justice': {
        routeKind: 'topic',
        topic: {
            id: 'news/court-justice',
            title: 'Courts & Justice',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Courts & Justice',
        },
        publicationKind: 'article',
    },
    '/news/health': {
        routeKind: 'topic',
        topic: {
            id: 'news/health',
            title: 'Health',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Health',
        },
        publicationKind: 'article',
    },
    '/news/education': {
        routeKind: 'topic',
        topic: {
            id: 'news/education',
            title: 'Education',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Education',
        },
        publicationKind: 'article',
    },
    '/news/weather': {
        routeKind: 'topic',
        topic: {
            id: 'news/weather',
            title: 'Weather',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Weather',
        },
        publicationKind: 'article',
    },
    '/sport/west-coast-eagles': {
        routeKind: 'topic',
        topic: {
            id: 'sport/west-coast-eagles',
            title: 'West Coast Eagles',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'West Coast Eagles',
        },
        publicationKind: 'article',
    },
    '/sport/fremantle-dockers': {
        routeKind: 'topic',
        topic: {
            id: 'sport/fremantle-dockers',
            title: 'Fremantle Dockers',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'Fremantle Dockers',
        },
        publicationKind: 'article',
    },
    '/sport/wafl': {
        routeKind: 'topic',
        topic: {
            id: 'sport/wafl',
            title: 'WAFL',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'WAFL',
        },
        publicationKind: 'article',
    },
    '/sport/aflw': {
        routeKind: 'topic',
        topic: {
            id: 'sport/aflw',
            title: 'AFLW',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'AFLW',
        },
        publicationKind: 'article',
    },
    '/sport/commonwealth-games': {
        routeKind: 'topic',
        topic: {
            id: 'sport/commonwealth-games',
            title: 'Commonwealth Games',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'Commonwealth Games',
        },
        publicationKind: 'article',
    },
    '/sport/tennis': {
        routeKind: 'topic',
        topic: {
            id: 'sport/tennis',
            title: 'Tennis',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'Tennis',
        },
        publicationKind: 'article',
    },
    '/sport/soccer': {
        routeKind: 'topic',
        topic: {
            id: 'sport/soccer',
            title: 'Soccer',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'Soccer',
        },
        publicationKind: 'article',
    },
    '/sport/basketball': {
        routeKind: 'topic',
        topic: {
            id: 'sport/basketball',
            title: 'Basketball',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'Basketball',
        },
        publicationKind: 'article',
    },
    '/sport/rugby-union': {
        routeKind: 'topic',
        topic: {
            id: 'sport/rugby-union',
            title: 'Rugby Union',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'Rugby Union',
        },
        publicationKind: 'article',
    },
    '/sport/rugby-league': {
        routeKind: 'topic',
        topic: {
            id: 'sport/rugby-league',
            title: 'Rugby League',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'Rugby League',
        },
        publicationKind: 'article',
    },
    '/sport/horse-racing': {
        routeKind: 'topic',
        topic: {
            id: 'sport/horse-racing',
            title: 'Racing',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'Racing',
        },
        publicationKind: 'article',
    },
    '/sport/golf': {
        routeKind: 'topic',
        topic: {
            id: 'sport/golf',
            title: 'Golf',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'Golf',
        },
        publicationKind: 'article',
    },
    '/sport/motorsport': {
        routeKind: 'topic',
        topic: {
            id: 'sport/motorsport',
            title: 'Motorsport',
            parent: {
                id: 'sport',
                title: 'Sport',
                metadata: {},
                seoTitle: 'Sport',
            },
            metadata: {},
            seoTitle: 'Motorsport',
        },
        publicationKind: 'article',
    },
    '/business/markets': {
        routeKind: 'topic',
        topic: {
            id: 'business/markets',
            title: 'Markets',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: 'Business',
            },
            metadata: {},
            seoTitle: 'Markets',
        },
        publicationKind: 'article',
    },
    '/business/property': {
        routeKind: 'topic',
        topic: {
            id: 'business/property',
            title: 'Property',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: 'Business',
            },
            metadata: {},
            seoTitle: 'Property',
        },
        publicationKind: 'article',
    },
    '/business/herd-on-the-terrace': {
        routeKind: 'topic',
        topic: {
            id: 'business/herd-on-the-terrace',
            title: 'Herd on the Terrace',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: 'Business',
            },
            metadata: {},
            seoTitle: 'Herd on the Terrace',
        },
        publicationKind: 'article',
    },
    '/business/commercial-property': {
        routeKind: 'topic',
        topic: {
            id: 'business/commercial-property',
            title: 'Commercial Property',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: 'Business',
            },
            metadata: {},
            seoTitle: 'Commercial Property',
        },
        publicationKind: 'article',
    },
    '/business/mining': {
        routeKind: 'topic',
        topic: {
            id: 'business/mining',
            title: 'Mining',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: 'Business',
            },
            metadata: {},
            seoTitle: 'Mining',
        },
        publicationKind: 'article',
    },
    '/business/energy': {
        routeKind: 'topic',
        topic: {
            id: 'business/energy',
            title: 'Energy',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: 'Business',
            },
            metadata: {},
            seoTitle: 'Energy',
        },
        publicationKind: 'article',
    },
    '/business/personal-finance': {
        routeKind: 'topic',
        topic: {
            id: 'business/personal-finance',
            title: 'Personal Finance',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: 'Business',
            },
            metadata: {},
            seoTitle: 'Personal Finance',
        },
        publicationKind: 'article',
    },
    '/business/agriculture': {
        routeKind: 'topic',
        topic: {
            id: 'business/agriculture',
            title: 'Agriculture',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: 'Business',
            },
            metadata: {},
            seoTitle: 'Agriculture',
        },
        publicationKind: 'article',
    },
    '/business/bulls-n-bears': {
        routeKind: 'topic',
        topic: {
            id: 'business/bulls-n-bears',
            title: 'Bulls N’ Bears',
            parent: {
                id: 'business',
                title: 'Business',
                metadata: {},
                seoTitle: 'Business',
            },
            metadata: {
                isSponsored: 'TRUE',
            },
            seoTitle: 'Bulls N’ Bears',
        },
        publicationKind: 'article',
    },
    '/politics/federal-politics': {
        routeKind: 'topic',
        topic: {
            id: 'politics/federal-politics',
            title: 'Federal Politics',
            parent: {
                id: 'politics',
                title: 'Politics',
                metadata: {},
                seoTitle: 'Politics',
            },
            metadata: {},
            seoTitle: 'Federal Politics',
        },
        publicationKind: 'article',
    },
    '/politics/state-politics': {
        routeKind: 'topic',
        topic: {
            id: 'politics/state-politics',
            title: 'State Politics',
            parent: {
                id: 'politics',
                title: 'Politics',
                metadata: {},
                seoTitle: 'Politics',
            },
            metadata: {},
            seoTitle: 'State Politics',
        },
        publicationKind: 'article',
    },
    '/politics/local-government': {
        routeKind: 'topic',
        topic: {
            id: 'politics/local-government',
            title: 'Local Government',
            parent: {
                id: 'politics',
                title: 'Politics',
                metadata: {},
                seoTitle: 'Politics',
            },
            metadata: {},
            seoTitle: 'Local Government',
        },
        publicationKind: 'article',
    },
    '/politics/regional-politics': {
        routeKind: 'topic',
        topic: {
            id: 'politics/regional-politics',
            title: 'Regional Politics',
            parent: {
                id: 'politics',
                title: 'Politics',
                metadata: {},
                seoTitle: 'Politics',
            },
            metadata: {},
            seoTitle: 'Regional Politics',
        },
        publicationKind: 'article',
    },
    '/politics/world-politics': {
        routeKind: 'topic',
        topic: {
            id: 'politics/world-politics',
            title: 'World Politics',
            parent: {
                id: 'politics',
                title: 'Politics',
                metadata: {},
                seoTitle: 'Politics',
            },
            metadata: {},
            seoTitle: 'World Politics',
        },
        publicationKind: 'article',
    },
    '/news/south-west': {
        routeKind: 'topic',
        topic: {
            id: 'news/south-west',
            title: 'South West',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'South West',
        },
        publicationKind: 'article',
    },
    '/news/great-southern': {
        routeKind: 'topic',
        topic: {
            id: 'news/great-southern',
            title: 'Great Southern',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Great Southern',
        },
        publicationKind: 'article',
    },
    '/news/goldfields': {
        routeKind: 'topic',
        topic: {
            id: 'news/goldfields',
            title: 'Goldfields',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Goldfields',
        },
        publicationKind: 'article',
    },
    '/news/peel-rockingham': {
        routeKind: 'topic',
        topic: {
            id: 'news/peel-rockingham',
            title: 'Peel/Rockingham',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Peel/Rockingham',
        },
        publicationKind: 'article',
    },
    '/news/gascoyne': {
        routeKind: 'topic',
        topic: {
            id: 'news/gascoyne',
            title: 'Gascoyne',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Gascoyne',
        },
        publicationKind: 'article',
    },
    '/news/mid-west': {
        routeKind: 'topic',
        topic: {
            id: 'news/mid-west',
            title: 'Mid West',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Mid West',
        },
        publicationKind: 'article',
    },
    '/news/kimberley': {
        routeKind: 'topic',
        topic: {
            id: 'news/kimberley',
            title: 'Kimberley',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Kimberley',
        },
        publicationKind: 'article',
    },
    '/news/pilbara': {
        routeKind: 'topic',
        topic: {
            id: 'news/pilbara',
            title: 'Pilbara',
            parent: {
                id: 'news',
                title: 'News',
                metadata: {},
                seoTitle: 'News',
            },
            metadata: {},
            seoTitle: 'Pilbara',
        },
        publicationKind: 'article',
    },
    '/opinion/emma-garlett': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/emma-garlett',
            title: 'Emma Garlett',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Emma Garlett',
        },
        publicationKind: 'article',
    },
    '/opinion/sarah-jane-tasker': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/sarah-jane-tasker',
            title: 'Sarah-Jane Tasker',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Sarah-Jane Tasker',
        },
        publicationKind: 'article',
    },
    '/opinion/josh-zimmerman': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/josh-zimmerman',
            title: 'Josh Zimmerman',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Josh Zimmerman',
        },
        publicationKind: 'article',
    },
    '/opinion/dean-alston': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/dean-alston',
            title: 'Dean Alston',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Dean Alston',
        },
        publicationKind: 'article',
    },
    '/opinion/rangi-hirini': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/rangi-hirini',
            title: 'Rangi Hirini',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Rangi Hirini',
        },
        publicationKind: 'article',
    },
    '/opinion/kate-emery': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/kate-emery',
            title: 'Kate Emery',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Kate Emery',
        },
        publicationKind: 'article',
    },
    '/opinion/basil-zempilas': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/basil-zempilas',
            title: 'Basil Zempilas',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Basil Zempilas',
        },
        publicationKind: 'article',
    },
    '/opinion/joe-spagnolo': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/joe-spagnolo',
            title: 'Joe Spagnolo',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Joe Spagnolo',
        },
        publicationKind: 'article',
    },
    '/opinion/gareth-hutchens': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/gareth-hutchens',
            title: 'Gareth Hutchens',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Gareth Hutchens',
        },
        publicationKind: 'article',
    },
    '/lifestyle/house-that': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/house-that',
            title: 'House That!',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: 'Lifestyle',
            },
            metadata: {},
            seoTitle: 'House That!',
        },
        publicationKind: 'article',
    },
    '/lifestyle/stm': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/stm',
            title: 'STM',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: 'Lifestyle',
            },
            metadata: {},
            seoTitle: 'STM',
        },
        publicationKind: 'article',
    },
    '/lifestyle/play': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/play',
            title: 'PLAY',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: 'Lifestyle',
            },
            metadata: {},
            seoTitle: 'PLAY',
        },
        publicationKind: 'article',
    },
    '/opinion/gary-adshead': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/gary-adshead',
            title: 'Gary Adshead',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Gary Adshead',
        },
        publicationKind: 'article',
    },
    '/opinion/mark-riley': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/mark-riley',
            title: 'Mark Riley',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Mark Riley',
        },
        publicationKind: 'article',
    },
    '/opinion/peter-law': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/peter-law',
            title: 'Peter Law',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Peter Law',
        },
        publicationKind: 'article',
    },
    '/opinion/gemma-tognini': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/gemma-tognini',
            title: 'Gemma Tognini',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Gemma Tognini',
        },
        publicationKind: 'article',
    },

    '/opinion/sarah-martin': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/sarah-martin',
            title: 'Sarah Martin',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Sarah Martin',
        },
        publicationKind: 'article',
    },

    '/opinion/paul-murray': {
        routeKind: 'topic',
        topic: {
            id: 'opinion/paul-murray',
            title: 'Paul Murray',
            parent: {
                id: 'opinion',
                title: 'Opinion',
                metadata: {},
                seoTitle: 'Opinion',
            },
            metadata: {},
            seoTitle: 'Paul Murray',
        },
        publicationKind: 'article',
    },
    '/lifestyle/food': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/food',
            title: 'Food',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: 'Lifestyle',
            },
            metadata: {},
            seoTitle: 'Food',
        },
        publicationKind: 'article',
    },
    '/lifestyle/drink': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/drink',
            title: 'Drink',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: 'Lifestyle',
            },
            metadata: {},
            seoTitle: 'Drink',
        },
        publicationKind: 'article',
    },
    '/lifestyle/health-wellbeing': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/health-wellbeing',
            title: 'Health & Wellbeing',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: 'Lifestyle',
            },
            metadata: {},
            seoTitle: 'Health & Wellbeing',
        },
        publicationKind: 'article',
    },
    '/lifestyle/garden': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/garden',
            title: 'Garden',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: 'Lifestyle',
            },
            metadata: {},
            seoTitle: 'Garden',
        },
        publicationKind: 'article',
    },
    '/lifestyle/retirement': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/retirement',
            title: 'Retirement',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: 'Lifestyle',
            },
            metadata: {},
            seoTitle: 'Retirement',
        },
        publicationKind: 'article',
    },
    '/lifestyle/fashion': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/fashion',
            title: 'Fashion',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: 'Lifestyle',
            },
            metadata: {},
            seoTitle: 'Fashion',
        },
        publicationKind: 'article',
    },
    '/lifestyle/motoring': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/motoring',
            title: 'Motoring',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: 'Lifestyle',
            },
            metadata: {},
            seoTitle: 'Motoring',
        },
        publicationKind: 'article',
    },
    '/lifestyle/real-estate': {
        routeKind: 'topic',
        topic: {
            id: 'lifestyle/real-estate',
            title: 'Real Estate',
            parent: {
                id: 'lifestyle',
                title: 'Lifestyle',
                metadata: {},
                seoTitle: 'Lifestyle',
            },
            metadata: {},
            seoTitle: 'Real Estate',
        },
        publicationKind: 'article',
    },
    '/entertainment/two-pommie-shielas': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/two-pommie-shielas',
            title: 'Two Pommie Sheilas',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: 'Entertainment',
            },
            metadata: {},
            seoTitle: 'Two Pommie Sheilas',
        },
        publicationKind: 'article',
    },
    '/entertainment/competitions': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/competitions',
            title: 'Competitions & Puzzles',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: 'Entertainment',
            },
            metadata: {},
            seoTitle: 'Competitions & Puzzles',
        },
        publicationKind: 'article',
    },
    '/entertainment/piaf': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/piaf',
            title: 'Perth Festival',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: 'Entertainment',
            },
            metadata: {},
            seoTitle: 'Perth Festival',
        },
        publicationKind: 'article',
    },
    '/entertainment/fringe-festival': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/fringe-festival',
            title: 'Fringe World',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: 'Entertainment',
            },
            metadata: {},
            seoTitle: 'Fringe World',
        },
        publicationKind: 'article',
    },
    '/entertainment/celebrity-gossip': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/celebrity-gossip',
            title: 'Celebrity Gossip',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: 'Entertainment',
            },
            metadata: {},
            seoTitle: 'Celebrity Gossip',
        },
        publicationKind: 'article',
    },
    '/entertainment/movies': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/movies',
            title: 'Movies',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: 'Entertainment',
            },
            metadata: {},
            seoTitle: 'Movies',
        },
        publicationKind: 'article',
    },
    '/entertainment/tv': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/tv',
            title: 'TV',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: 'Entertainment',
            },
            metadata: {},
            seoTitle: 'TV',
        },
        publicationKind: 'article',
    },
    '/entertainment/music': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/music',
            title: 'Music',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: 'Entertainment',
            },
            metadata: {},
            seoTitle: 'Music',
        },
        publicationKind: 'article',
    },
    '/entertainment/theatre': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/theatre',
            title: 'Theatre',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: 'Entertainment',
            },
            metadata: {},
            seoTitle: 'Theatre',
        },
        publicationKind: 'article',
    },
    '/entertainment/books': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/books',
            title: 'Books',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: 'Entertainment',
            },
            metadata: {},
            seoTitle: 'Books',
        },
        publicationKind: 'article',
    },
    '/entertainment/art': {
        routeKind: 'topic',
        topic: {
            id: 'entertainment/art',
            title: 'Arts',
            parent: {
                id: 'entertainment',
                title: 'Entertainment',
                metadata: {},
                seoTitle: 'Entertainment',
            },
            metadata: {},
            seoTitle: 'Arts',
        },
        publicationKind: 'article',
    },
    '/travel/wa': {
        routeKind: 'topic',
        topic: {
            id: 'travel/wa',
            title: 'Western Australia',
            parent: {
                id: 'travel',
                title: 'Travel',
                metadata: {},
                seoTitle: 'Travel',
            },
            metadata: {},
            seoTitle: 'Western Australia',
        },
        publicationKind: 'article',
    },
    '/travel/australasia': {
        routeKind: 'topic',
        topic: {
            id: 'travel/australasia',
            title: 'Australasia',
            parent: {
                id: 'travel',
                title: 'Travel',
                metadata: {},
                seoTitle: 'Travel',
            },
            metadata: {},
            seoTitle: 'Australasia',
        },
        publicationKind: 'article',
    },
    '/travel/asia': {
        routeKind: 'topic',
        topic: {
            id: 'travel/asia',
            title: 'Asia',
            parent: {
                id: 'travel',
                title: 'Travel',
                metadata: {},
                seoTitle: 'Travel',
            },
            metadata: {},
            seoTitle: 'Asia',
        },
        publicationKind: 'article',
    },
    '/travel/europe': {
        routeKind: 'topic',
        topic: {
            id: 'travel/europe',
            title: 'Europe & UK',
            parent: {
                id: 'travel',
                title: 'Travel',
                metadata: {},
                seoTitle: 'Travel',
            },
            metadata: {},
            seoTitle: 'Europe & UK',
        },
        publicationKind: 'article',
    },
    '/travel/americas': {
        routeKind: 'topic',
        topic: {
            id: 'travel/americas',
            title: 'Americas',
            parent: {
                id: 'travel',
                title: 'Travel',
                metadata: {},
                seoTitle: 'Travel',
            },
            metadata: {},
            seoTitle: 'Americas',
        },
        publicationKind: 'article',
    },
    '/travel/cruising': {
        routeKind: 'topic',
        topic: {
            id: 'travel/cruising',
            title: 'Cruising Holidays',
            parent: {
                id: 'travel',
                title: 'Travel',
                metadata: {},
                seoTitle: 'Travel',
            },
            metadata: {},
            seoTitle: 'Cruising Holidays',
        },
        publicationKind: 'article',
    },
    '/travel/photography': {
        routeKind: 'topic',
        topic: {
            id: 'travel/photography',
            title: 'Photography',
            parent: {
                id: 'travel',
                title: 'Travel',
                metadata: {},
                seoTitle: 'Travel',
            },
            metadata: {},
            seoTitle: 'Photography',
        },
        publicationKind: 'article',
    },
    '/travel/travel-tips': {
        routeKind: 'topic',
        topic: {
            id: 'travel/travel-tips',
            title: 'Travel Tips',
            parent: {
                id: 'travel',
                title: 'Travel',
                metadata: {},
                seoTitle: 'Travel',
            },
            metadata: {},
            seoTitle: 'Travel Tips',
        },
        publicationKind: 'article',
    },
    '/travel/seven-west-travel-club': {
        routeKind: 'topic',
        topic: {
            id: 'travel/seven-west-travel-club',
            title: 'West Travel Club',
            parent: {
                id: 'travel',
                title: 'Travel',
                metadata: {},
                seoTitle: 'Travel',
            },
            metadata: {},
            seoTitle: 'West Travel Club',
        },
        publicationKind: 'article',
    },
    '/technology/gadgets': {
        routeKind: 'topic',
        topic: {
            id: 'technology/gadgets',
            title: 'Gadgets',
            parent: {
                id: 'technology',
                title: 'Technology',
                metadata: {},
                seoTitle: 'Technology',
            },
            metadata: {},
            seoTitle: 'Gadgets',
        },
        publicationKind: 'article',
    },
    '/technology/gaming': {
        routeKind: 'topic',
        topic: {
            id: 'technology/gaming',
            title: 'Gaming',
            parent: {
                id: 'technology',
                title: 'Technology',
                metadata: {},
                seoTitle: 'Technology',
            },
            metadata: {},
            seoTitle: 'Gaming',
        },
        publicationKind: 'article',
    },
    '/technology/internet': {
        routeKind: 'topic',
        topic: {
            id: 'technology/internet',
            title: 'Internet',
            parent: {
                id: 'technology',
                title: 'Technology',
                metadata: {},
                seoTitle: 'Technology',
            },
            metadata: {},
            seoTitle: 'Internet',
        },
        publicationKind: 'article',
    },
    '/technology/apps': {
        routeKind: 'topic',
        topic: {
            id: 'technology/apps',
            title: 'Apps',
            parent: {
                id: 'technology',
                title: 'Technology',
                metadata: {},
                seoTitle: 'Technology',
            },
            metadata: {},
            seoTitle: 'Apps',
        },
        publicationKind: 'article',
    },
    '/technology/smartphones': {
        routeKind: 'topic',
        topic: {
            id: 'technology/smartphones',
            title: 'Smartphones',
            parent: {
                id: 'technology',
                title: 'Technology',
                metadata: {},
                seoTitle: 'Technology',
            },
            metadata: {},
            seoTitle: 'Smartphones',
        },
        publicationKind: 'article',
    },
    '/technology/hardware': {
        routeKind: 'topic',
        topic: {
            id: 'technology/hardware',
            title: 'Hardware',
            parent: {
                id: 'technology',
                title: 'Technology',
                metadata: {},
                seoTitle: 'Technology',
            },
            metadata: {},
            seoTitle: 'Hardware',
        },
        publicationKind: 'article',
    },
    '/technology/science': {
        routeKind: 'topic',
        topic: {
            id: 'technology/science',
            title: 'Science',
            parent: {
                id: 'technology',
                title: 'Technology',
                metadata: {},
                seoTitle: 'Technology',
            },
            metadata: {},
            seoTitle: 'Science',
        },
        publicationKind: 'article',
    },
    '/technology/innovation': {
        routeKind: 'topic',
        topic: {
            id: 'technology/innovation',
            title: 'Innovation',
            parent: {
                id: 'technology',
                title: 'Technology',
                metadata: {},
                seoTitle: 'Technology',
            },
            metadata: {},
            seoTitle: 'Innovation',
        },
        publicationKind: 'article',
    },
    '/technology/telecommunications': {
        routeKind: 'topic',
        topic: {
            id: 'technology/telecommunications',
            title: 'Telecommunications',
            parent: {
                id: 'technology',
                title: 'Technology',
                metadata: {},
                seoTitle: 'Technology',
            },
            metadata: {},
            seoTitle: 'Telecommunications',
        },
        publicationKind: 'article',
    },
    '/technology/security': {
        routeKind: 'topic',
        topic: {
            id: 'technology/security',
            title: 'Security',
            parent: {
                id: 'technology',
                title: 'Technology',
                metadata: {},
                seoTitle: 'Technology',
            },
            metadata: {},
            seoTitle: 'Security',
        },
        publicationKind: 'article',
    },
}

export const commonNavItems: TheWestNavItem[] = [
    {
        name: 'Subscriber Exclusive',
        link: '/features',
        section: theWestSection,
    },
    {
        name: 'Latest',
        link: '/latest',
        section: theWestSection,
    },
]

export const subscriberNavItems = (publicUrl: string): TheWestNavItem[] => [
    {
        name: `Today's Paper`,
        link: `/editions?utm_source=TheWest&utm_medium=Referral&utm_campaign=digital-edition&utm_term=todays-paper&utm_content=mobile-flyout-nav`,
        section: theWestSection,
        forceInternalSSR: true,
    },

    {
        name: 'Puzzles',
        link: '/puzzles',
        section: theWestSection,
        feature: 'puzzles',
    },

    {
        name: 'Timespool',
        link: '/timespool',
        section: theWestSection,
        feature: 'timespool-navigation',
    },

    {
        name: 'West Rewards',
        link: `https://rewards.thewest.com.au/?utm_source=TheWest&utm_medium=Referral&utm_campaign=WestRewards&utm_term=mobile-text&utm_content=mobile-flyout-nav`,
        section: theWestSection,
    },
]

export const mainNavigation = (baseUrl: string, renderTarget: string) => {
    if (renderTarget === 'app') {
        return commonNavItems.concat(theWestMainNavigation)
    } else {
        return commonNavItems.concat(
            subscriberNavItems(baseUrl),
            theWestMainNavigation,
        )
    }
}

export const theWestMainNavigation: TheWestNavItem[] = [
    {
        name: 'Vanishing Cousins',
        link: '/features/vanishing-cousins',
        section: theWestSection,
    },
    {
        name: 'Up Late',
        link: '/news/up-late',
        section: theWestSection,
        feature: 'route-up-late',
    },
    {
        name: 'The West Live',
        link: '/the-west-live',
        section: theWestSection,
    },
    {
        name: 'Court in the Act',
        link: '/podcasts/court-in-the-act',
        section: theWestSection,
        feature: 'court-in-the-act',
    },
    {
        name: 'News',
        link: '/news',
        section: theWestSection,
        subNavLinks: [
            {
                name: 'WA News',
                link: '/news/wa',
            },
            {
                name: 'Australia',
                link: '/news/australia',
            },
            {
                name: 'World',
                link: '/news/world',
            },
            {
                name: 'Regional',
                link: '/news/regional',
            },
            {
                name: 'Crime',
                link: '/news/crime',
            },
            {
                name: 'Courts & Justice',
                link: '/news/court-justice',
            },
            {
                name: 'Health',
                link: '/news/health',
            },
            {
                name: 'Education',
                link: '/news/education',
            },
        ],
    },
    {
        name: 'Sport',
        link: '/sport',
        section: theWestSection,
        subNavLinks: [
            {
                name: 'AFL',
                link: '/sport/afl',
            },
            {
                name: 'Cricket',
                link: '/sport/cricket',
            },
            {
                name: 'West Coast Eagles',
                link: '/sport/west-coast-eagles',
            },
            {
                name: 'Fremantle Dockers',
                link: '/sport/fremantle-dockers',
            },
            {
                name: 'AFLW',
                link: '/sport/aflw',
            },
            {
                name: 'WAFL',
                link: '/sport/wafl',
            },
            {
                name: 'Basketball',
                link: '/sport/basketball',
            },
            {
                name: 'Soccer',
                link: '/sport/soccer',
            },
            {
                name: 'NRL',
                link: '/sport/rugby-league',
            },
            {
                name: 'Rugby Union',
                link: '/sport/rugby-union',
            },
            {
                name: 'Horse Racing',
                link: '/sport/horse-racing',
            },
            {
                name: 'Netball',
                link: '/sport/netball',
            },
            {
                name: 'Tennis',
                link: '/sport/tennis',
            },
            {
                name: 'Motorsport',
                link: '/sport/motorsport',
            },
            {
                name: 'Golf',
                link: '/sport/golf',
            },
            {
                name: 'AFL Match Centre',
                feature: 'the-west-afl-fixture-scoreboard',
                link: '/sport/afl/fixtures',
            },
        ],
    },
    {
        name: 'Business',
        link: '/business',
        section: theWestSection,
        subNavLinks: [
            {
                name: 'Markets',
                link: '/business/markets',
            },
            {
                name: 'Property',
                link: '/business/property',
            },
            {
                name: 'Mining',
                link: '/business/mining',
            },
            {
                name: 'Energy',
                link: '/business/energy',
            },
            {
                name: 'Herd On The Terrace',
                link: '/business/herd-on-the-terrace',
            },
            {
                name: 'Your Money',
                link: '/business/your-money',
            },
            {
                name: 'Agriculture',
                link: '/business/agriculture',
            },
            {
                name: 'Bulls N’ Bears',
                link: '/business/bulls-n-bears',
            },
        ],
    },
    {
        name: 'Politics',
        link: '/politics',
        section: theWestSection,
        subNavLinks: [
            {
                name: 'Federal Election 2025',
                link: '/politics/federal-election-2025',
                feature: 'federal-election-sitewide-navigation',
            },
            {
                name: 'WA Election 2025',
                link: '/politics/wa-election-2025',
                feature: 'wa-election-sitewide-navigation',
            },
            {
                name: 'Federal Politics',
                link: '/politics/federal-politics',
            },
            {
                name: 'State Politics',
                link: '/politics/state-politics',
            },
            {
                name: 'Local Government',
                link: '/politics/local-government',
            },
            {
                name: 'World Politics',
                link: '/politics/world-politics',
            },
        ],
    },
    {
        name: 'Regional',
        link: '/news/regional',
        hideFromMainNav: true,
        section: theWestSection,

        subNavLinks: [
            {
                name: 'South West',
                link: '/news/south-west',
            },
            {
                name: 'Great Southern',
                link: '/news/great-southern',
            },
            {
                name: 'Goldfields',
                link: '/news/goldfields',
            },
            {
                name: 'Peel/Rockingham',
                link: '/news/peel-rockingham',
            },
            {
                name: 'Gascoyne',
                link: '/news/gascoyne',
            },
            {
                name: 'Mid West',
                link: '/news/mid-west',
            },
            {
                name: 'Kimberley',
                link: '/news/kimberley',
            },
            {
                name: 'Pilbara',
                link: '/news/pilbara',
            },
        ],
    },
    {
        name: 'Opinion',
        link: '/opinion',
        section: theWestSection,
        subNavLinks: [
            {
                name: 'Editorials',
                link: '/opinion/editorials',
            },
            {
                name: 'Ben Harvey',
                link: '/opinion/ben-harvey',
            },
            {
                name: 'Jessica Page',
                link: '/opinion/jessica-page',
            },
            {
                name: 'Kate Emery',
                link: '/opinion/kate-emery',
            },
            {
                name: 'Katina Curtis',
                link: '/opinion/katina-curtis',
            },
            {
                name: 'Basil Zempilas',
                link: '/opinion/basil-zempilas',
            },
            {
                name: 'Emma Garlett',
                link: '/opinion/emma-garlett',
            },
            {
                name: 'Kim MacDonald',
                link: '/opinion/kim-macdonald',
            },
            {
                name: 'Paul Murray',
                link: '/opinion/paul-murray',
            },
            {
                name: 'Justin Langer',
                link: '/sport/justin-langer',
            },
            {
                name: 'Belle Taylor',
                link: '/opinion/belle-taylor',
            },
            {
                name: 'Laura Newell',
                link: '/opinion/laura-newell',
            },
            {
                name: 'Anxiety Aunt',
                link: '/opinion/anxiety-aunt',
            },
        ],
    },
    {
        name: 'Lifestyle',
        link: '/lifestyle',
        section: theWestSection,
        subNavLinks: [
            {
                name: 'Food',
                link: '/lifestyle/food',
            },
            {
                name: 'Drink',
                link: '/lifestyle/drink',
            },
            {
                name: 'Health & Wellbeing',
                link: '/lifestyle/health-wellbeing',
            },
            {
                name: 'Garden',
                link: '/lifestyle/garden',
            },
            {
                name: 'Retirement',
                link: '/lifestyle/retirement',
            },
            {
                name: 'Fashion',
                link: '/lifestyle/fashion',
            },
            {
                name: 'Motoring',
                link: '/lifestyle/motoring',
            },
            {
                name: 'New Homes',
                link: '/lifestyle/new-homes',
            },
            {
                name: 'Real Estate',
                link: '/lifestyle/real-estate',
            },
            {
                name: 'House That!',
                link: '/lifestyle/house-that',
            },
            {
                name: 'STM',
                link: '/lifestyle/stm',
            },
            {
                name: 'PLAY',
                link: '/lifestyle/play',
            },
        ],
    },
    {
        name: 'Entertainment',
        link: '/entertainment',
        section: theWestSection,
        subNavLinks: [
            {
                name: 'Celebrity Gossip',
                link: '/entertainment/celebrity-gossip',
            },
            {
                name: 'Movies',
                link: '/entertainment/movies',
            },
            {
                name: 'TV',
                link: '/entertainment/tv',
            },
            {
                name: 'Music',
                link: '/entertainment/music',
            },
            {
                name: 'Theatre',
                link: '/entertainment/theatre',
            },
            {
                name: 'Books',
                link: '/entertainment/books',
            },
            {
                name: 'Arts',
                link: '/entertainment/art',
            },
            {
                name: 'Competitions & Puzzles',
                link: '/entertainment/competitions',
            },
        ],
    },
    {
        name: 'Travel',
        link: '/travel',
        hideFromMainNav: true,
        section: theWestSection,
        subNavLinks: [
            {
                name: 'Western Australia',
                link: '/travel/wa',
            },
            {
                name: 'Australia',
                link: '/travel/australasia',
            },
            {
                name: 'Asia',
                link: '/travel/asia',
            },
            {
                name: 'West Travel Club',
                link: '/travel/seven-west-travel-club',
            },
            {
                name: 'Photography',
                link: '/travel/photography',
            },
            {
                name: 'Travel Tips',
                link: '/travel/travel-tips',
            },
            {
                name: 'Europe',
                link: '/travel/europe',
            },
        ],
    },
    {
        name: 'Technology',
        link: '/technology',
        hideFromMainNav: true,
        section: theWestSection,
        subNavLinks: [
            {
                name: 'Gadgets',
                link: '/technology/gadgets',
            },
            {
                name: 'Gaming',
                link: '/technology/gaming',
            },
            {
                name: 'Internet',
                link: '/technology/internet',
            },
            {
                name: 'Apps',
                link: '/technology/apps',
            },
            {
                name: 'Smartphones',
                link: '/technology/smartphones',
            },
            {
                name: 'Hardware',
                link: '/technology/hardware',
            },
            {
                name: 'Science',
                link: '/technology/science',
            },
            {
                name: 'Innovation',
                link: '/technology/innovation',
            },
            {
                name: 'Telecommunications',
                link: '/technology/telecommunications',
            },
            {
                name: 'Security',
                link: '/technology/security',
            },
        ],
    },
]

// Duplicate utm arguments.
const digitalEditionUtmArgs =
    '&utm_medium=Referral&utm_campaign=digital-edition&utm_term=todays-paper&utm_content=nav'

const digitalEditionLink = `/editions?utm_source=TheWest${digitalEditionUtmArgs}`
export const regionalEditionLinks: Record<TheWestRegionalSections, string> = {
    'kalgoorlie-miner': `/editions?utm_source=kalgoorlie-miner${digitalEditionUtmArgs}`,
    'south-western-times': `/editions?utm_source=south-western-times${digitalEditionUtmArgs}`,
    countryman: `/editions?utm_source=countryman&utm_medium=Referral${digitalEditionUtmArgs}`,
    'albany-advertiser': `/editions?utm_source=Albany-Advertiser${digitalEditionUtmArgs}`,
    'geraldton-guardian': `/editions?utm_source=Geraldton-Guardian${digitalEditionUtmArgs}`,
    'manjimup-bridgetown-times': `/editions?utm_source=Manjimup-Bridgetown-Times${digitalEditionUtmArgs}`,
    'narrogin-observer': `/editions?utm_source=Narrogin-Observer${digitalEditionUtmArgs}`,
    'the-kimberley-echo': `/editions?utm_source=The-Kimberley-Echo${digitalEditionUtmArgs}`,
    'great-southern-herald': `/editions?utm_source=Great-Southern-Herald${digitalEditionUtmArgs}`,
    'augusta-margaret-river-times': `/editions?utm_source=Augusta-Margaret-River-Times${digitalEditionUtmArgs}`,
    'broome-advertiser': `/editions?utm_source=Broome-Advertiser${digitalEditionUtmArgs}`,
    'bunbury-herald': `/editions?utm_source=Bunbury-Herald${digitalEditionUtmArgs}`,
    'busselton-dunsborough-times': `/editions?utm_source=Busselton-Dunsborough-Times${digitalEditionUtmArgs}`,
    'harvey-waroona-reporter': `/editions?utm_source=Harvey-Waroona-Reporter${digitalEditionUtmArgs}`,
    'midwest-times': `/editions?utm_source=Midwest-Times${digitalEditionUtmArgs}`,
    'north-west-telegraph': `/editions?utm_source=NorthWest-Telegraph${digitalEditionUtmArgs}`,
    'pilbara-news': `/editions?utm_source=PilbaraNews${digitalEditionUtmArgs}`,
    'sound-telegraph': `/editions?utm_source=Sound-Telegraph${digitalEditionUtmArgs}`,
}

const getDigitalEditionLink = (section: Section) => {
    if (section in regionalEditionLinks) {
        return regionalEditionLinks[section as TheWestRegionalSections]
    } else {
        return digitalEditionLink
    }
}

/**
 * @param baseUrl needs to be the baseUrl calculated via getBaseUrl(publicUrl, sectioMeta)
 */
export const getStaticNavInHouseLinks = (
    section: Section,
    isCorporate?: boolean,
) => {
    const digitalEditionLink = getDigitalEditionLink(section)
    const showAdLink = isCorporate === false

    const placeAnAdLink =
        section === 'default'
            ? 'https://placeanad.thewest.com.au/WebOE/?utm_source=thewest&utm_medium=referral&utm_campaign=placeanad&utm_content=headerlink'
            : '/contact'
    const placeAnAdTarget = section === 'default' ? '_blank' : undefined

    return [
        ...(digitalEditionLink
            ? [
                  {
                      text: "Today's Paper",
                      link: digitalEditionLink,
                      forceInternalSSR: true,
                      dataAttributeId: 'todays-paper',
                  },
              ]
            : []),
        ...(section === 'albany-advertiser'
            ? [
                  {
                      text: 'The Extra',
                      link: '/editions/the-extra',
                      target: '_blank',
                      forceInternalSSR: true,
                  },
              ]
            : []),
        ...(section !== 'genwest' && showAdLink
            ? [
                  {
                      text: 'Place an Ad',
                      link: placeAnAdLink,
                      target: placeAnAdTarget,
                  },
              ]
            : []),
    ]
}
export const quickLinks: QuickLinks = [
    {
        name: 'Federal Election 2025',
        link: '/politics/federal-election-2025',
        feature: 'federal-election-sitewide-navigation',
    },
    {
        name: 'WA Election 2025',
        link: '/politics/wa-election-2025',
        feature: 'wa-election-sitewide-navigation',
    },
    {
        name: 'WA News',
        link: '/news/wa',
        newWindow: false,
    },
    {
        name: '190 Years of The West',
        link: '/news/190-years-of-the-west-australian',
        newWindow: false,
        feature: '190-years-of-the-west',
    },
    {
        name: 'Timespool',
        link: '/timespool',
        newWindow: false,
        feature: 'timespool-navigation',
    },
    {
        name: 'Courts and Crime',
        link: '/news/crime',
        newWindow: false,
    },
    {
        name: 'Business',
        link: '/business',
        newWindow: false,
    },
    {
        name: 'Politics',
        link: '/politics',
        newWindow: false,
    },
    {
        name: 'Food',
        link: '/lifestyle/food',
        newWindow: false,
    },
    {
        name: 'Property',
        link: '/business/property',
        newWindow: false,
    },
    {
        name: 'Opinion',
        link: '/opinion',
        newWindow: false,
    },
    {
        name: 'AFL',
        link: '/sport/afl',
        newWindow: false,
    },
    {
        name: 'Subscriber Exclusive',
        link: '/features',
        newWindow: false,
    },
    {
        name: 'Puzzles',
        link: '/puzzles',
        newWindow: false,
    },
]

export const genWestQuickLinks: QuickLinks = [
    {
        name: 'News',
        link: '/genwest/news',
        newWindow: false,
    },
    {
        name: 'Features',
        link: '/genwest/features',
        newWindow: false,
    },
    {
        name: 'Sport',
        link: '/genwest/sport',
        newWindow: false,
    },
    {
        name: 'Arts',
        link: '/genwest/arts',
        newWindow: false,
    },
    {
        name: 'Books',
        link: '/genwest/books',
        newWindow: false,
    },
    {
        name: 'Careers',
        link: '/genwest/careers',
        newWindow: false,
    },
    {
        name: 'Snapshot',
        link: '/genwest/snapshot',
        newWindow: false,
    },
    {
        name: `What's Hot`,
        link: '/genwest/what-s-hot',
        newWindow: false,
    },
    {
        name: 'Puzzles',
        link: '/genwest/puzzles',
        newWindow: false,
    },
    {
        name: 'Best Australian Yarn',
        link: '/genwest/best-australian-yarn',
        newWindow: false,
        feature: 'best-australian-yarn-gen-west',
    },
]

// If this type needs to change, then make sure that Taboola is updated as well

export const regionalLinks: NavItem[] = [
    {
        name: 'Albany Advertiser (incl. The Extra)',
        link: regionalSectionLiveUrls('albany-advertiser'),
    },
    {
        name: 'Augusta-Margaret River Times',
        link: regionalSectionLiveUrls('augusta-margaret-river-times'),
    },
    {
        name: 'Broome Advertiser',
        link: regionalSectionLiveUrls('broome-advertiser'),
    },
    {
        name: 'Bunbury Herald',
        link: regionalSectionLiveUrls('bunbury-herald'),
    },
    {
        name: 'Busselton-Dunsborough Times',
        link: regionalSectionLiveUrls('busselton-dunsborough-times'),
    },
    {
        name: 'Countryman',
        link: regionalSectionLiveUrls('countryman'),
    },
    {
        name: 'Geraldton Guardian',
        link: regionalSectionLiveUrls('geraldton-guardian'),
    },
    {
        name: 'Great Southern Herald',
        link: regionalSectionLiveUrls('great-southern-herald'),
    },
    {
        name: 'Harvey Waroona Reporter',
        link: regionalSectionLiveUrls('harvey-waroona-reporter'),
    },
    {
        name: 'Kalgoorlie Miner',
        link: regionalSectionLiveUrls('kalgoorlie-miner'),
    },
    {
        name: 'The Kimberley Echo',
        link: regionalSectionLiveUrls('the-kimberley-echo'),
    },
    {
        name: 'Manjimup Bridgetown Times',
        link: regionalSectionLiveUrls('manjimup-bridgetown-times'),
    },
    {
        name: 'Midwest Times',
        link: regionalSectionLiveUrls('midwest-times'),
    },
    {
        name: 'Narrogin Observer',
        link: regionalSectionLiveUrls('narrogin-observer'),
    },
    {
        name: 'North West Telegraph',
        link: regionalSectionLiveUrls('north-west-telegraph'),
    },
    {
        name: 'Pilbara News',
        link: regionalSectionLiveUrls('pilbara-news'),
    },
    {
        name: 'South Western Times',
        link: regionalSectionLiveUrls('south-western-times'),
    },
    {
        name: 'Sound Telegraph',
        link: regionalSectionLiveUrls('sound-telegraph'),
    },
]

export const regionalSharedNav = (
    section: TheWestSection,
): TheWestNavItem[] => {
    const digitalEditionLink = getDigitalEditionLink(section)

    return [
        ...(digitalEditionLink
            ? [
                  {
                      name: "Today's Paper",
                      link: digitalEditionLink,
                      newWindow: false,
                      section,
                      forceInternalSSR: true,
                  },
              ]
            : []),
        {
            name: 'Place an Ad',
            link: '/contact',
            section,
        },
        {
            name: 'Regional Publications',
            link: '/our-papers',
            section: theWestSection,
            subNavLinks: regionalLinks,
        },
    ]
}

export const navigation = (
    baseUrl: string,
    renderTarget: string,
): TheWestNavigation => ({
    default: mainNavigation(baseUrl, renderTarget),
    genwest: mainNavigation(baseUrl, renderTarget),
    'albany-advertiser': regionalSharedNav(
        TheWestSections['albany-advertiser'],
    ),
    'great-southern-herald': regionalSharedNav(
        TheWestSections['great-southern-herald'],
    ),
    'augusta-margaret-river-times': regionalSharedNav(
        TheWestSections['augusta-margaret-river-times'],
    ),
    'busselton-dunsborough-times': regionalSharedNav(
        TheWestSections['busselton-dunsborough-times'],
    ),
    'broome-advertiser': regionalSharedNav(
        TheWestSections['broome-advertiser'],
    ),
    'the-kimberley-echo': regionalSharedNav(
        TheWestSections['the-kimberley-echo'],
    ),
    'geraldton-guardian': regionalSharedNav(
        TheWestSections['geraldton-guardian'],
    ),
    'midwest-times': regionalSharedNav(TheWestSections['midwest-times']),
    'bunbury-herald': regionalSharedNav(TheWestSections['bunbury-herald']),
    'harvey-waroona-reporter': regionalSharedNav(
        TheWestSections['harvey-waroona-reporter'],
    ),
    'manjimup-bridgetown-times': regionalSharedNav(
        TheWestSections['manjimup-bridgetown-times'],
    ),
    'south-western-times': regionalSharedNav(
        TheWestSections['south-western-times'],
    ),
    'kalgoorlie-miner': regionalSharedNav(TheWestSections['kalgoorlie-miner']),
    'narrogin-observer': regionalSharedNav(
        TheWestSections['narrogin-observer'],
    ),
    'north-west-telegraph': regionalSharedNav(
        TheWestSections['north-west-telegraph'],
    ),
    'pilbara-news': regionalSharedNav(TheWestSections['pilbara-news']),
    'sound-telegraph': regionalSharedNav(TheWestSections['sound-telegraph']),
    countryman: regionalSharedNav(TheWestSections.countryman),
})
