import React from 'react'
import { IconLock } from '../../icons'
import { useAuthActions } from '../../user-registration'
import {
    StyledPollieRaterCTAButton,
    StyledPollieRaterCTAButtonsContainer,
    StyledPollieRaterSignupBanner,
    StyledPollieRaterSignupText,
    StyledPollieRaterSignupTextContainer,
} from './PollieRaterSignup.styled'

interface PollieRaterSignupProps {
    politicianName: string
}

export function PollieRaterSignup({ politicianName }: PollieRaterSignupProps) {
    const { onLoginClick, onRegisterClick } = useAuthActions()

    return (
        <StyledPollieRaterSignupBanner>
            <StyledPollieRaterSignupTextContainer>
                <IconLock title="Registered User Feature" />
                <StyledPollieRaterSignupText>
                    Rate <span>{politicianName}</span> for FREE!
                </StyledPollieRaterSignupText>
            </StyledPollieRaterSignupTextContainer>
            <StyledPollieRaterCTAButtonsContainer>
                <StyledPollieRaterCTAButton
                    onClick={() => onLoginClick('pollie-rater-button')}
                >
                    Login
                </StyledPollieRaterCTAButton>
                <span>{' or '}</span>
                <StyledPollieRaterCTAButton
                    onClick={() => onRegisterClick('pollie-rater-button')}
                >
                    Register for FREE
                </StyledPollieRaterCTAButton>
            </StyledPollieRaterCTAButtonsContainer>
        </StyledPollieRaterSignupBanner>
    )
}
