import { ResponsivePictureLayout } from '@news-mono/component-library'
import { layout } from '../../App.routing'
import {
    CardExtensionProps,
    Extensions,
    returnEightCardExtensionProps,
    returnFourCardExtensionProps,
    returnOverrideCardProps,
    returnSevenCardExtensionProps,
} from './get-themed-segments-helpers'
import { Features } from '@news-mono/web-common'

type curations =
    | 'afl-grand-final'
    | 'commonwealth-games'
    | 'kings-coronation'
    | '190-years-of-the-west'
    | 'go-matildas'

export type themeOverrides =
    | 'grand-final'
    | 'grand-final-freo'
    | 'commonwealth-games'
    | 'kings-coronation'
    | '190-years-of-the-west'
    | 'go-matildas'

export type layouts = '4-card' | '8-card' | '7-card' | '6-card'

interface cardLayoutProps {
    featureToggle?: Features
    curation: curations
    themeOverride: themeOverrides
    hideHeadingLink?: boolean
    cardExtensionProps?: Extensions
}

const fourCardLayout = ({
    featureToggle,
    curation,
    themeOverride,
    cardExtensionProps,
}: cardLayoutProps) => {
    const extensions = returnFourCardExtensionProps(cardExtensionProps)

    return layout.nestedComposition({
        type: 'thor',
        feature: featureToggle,
        props: {
            ...returnOverrideCardProps(
                '4-card',
                'thor',
                curation,
                themeOverride,
                extensions.thorProps,
            ),
        },
        contentAreas: {
            main: [
                layout.component({
                    type: 'lima',
                    props: {
                        ...returnOverrideCardProps(
                            '4-card',
                            'lima',
                            curation,
                            themeOverride,
                            extensions.limaProps,
                        ),
                    },
                }),
            ],
        },
    })
}

const sevenCardLayout = ({
    featureToggle,
    curation,
    themeOverride,
    cardExtensionProps,
}: cardLayoutProps) => {
    const props = returnSevenCardExtensionProps(cardExtensionProps)

    return layout.nestedComposition({
        type: 'thor',
        feature: featureToggle,
        props: {
            ...returnOverrideCardProps(
                '7-card',
                'thor',
                curation,
                themeOverride,
                props.thorProps,
            ),
        },
        contentAreas: {
            main: [
                layout.component({
                    type: 'sierra',
                    props: {
                        ...returnOverrideCardProps(
                            '7-card',
                            'sierra',
                            curation,
                            themeOverride,
                            props.sierraProps,
                        ),
                    },
                }),
                layout.component({
                    type: 'lima',
                    props: {
                        ...returnOverrideCardProps(
                            '7-card',
                            'lima',
                            curation,
                            themeOverride,
                            props.limaProps,
                        ),
                    },
                }),
            ],
        },
    })
}

const eightCardLayout = ({
    featureToggle,
    curation,
    themeOverride,
    cardExtensionProps,
}: cardLayoutProps) => {
    const cardProps = returnEightCardExtensionProps(cardExtensionProps)

    return layout.nestedComposition({
        type: 'thor',
        feature: featureToggle,
        props: {
            ...returnOverrideCardProps(
                '8-card',
                'thor',
                curation,
                themeOverride,
                cardProps.thorProps,
            ),
        },
        contentAreas: {
            main: [
                layout.component({
                    type: 'sierra',
                    props: {
                        ...returnOverrideCardProps(
                            '8-card',
                            'sierra',
                            curation,
                            themeOverride,
                            cardProps.sierraProps,
                        ),
                    },
                }),
                layout.nestedComposition({
                    type: 'thor',
                    props: {
                        hasBackgroundFill: false,
                        containerWidth: '100%',
                        verticalGutters: false,
                        horizontalGutters: false,
                    },
                    contentAreas: {
                        main: [
                            layout.nestedComposition({
                                type: 'janus',
                                props: {
                                    ...returnOverrideCardProps(
                                        '8-card',
                                        'janus',
                                        curation,
                                        themeOverride,
                                        cardProps.janusProps,
                                    ),
                                },
                                contentAreas: {
                                    left: [
                                        layout.component({
                                            type: 'lima',
                                            props: {
                                                ...returnOverrideCardProps(
                                                    '8-card',
                                                    'lima-left',
                                                    curation,
                                                    themeOverride,
                                                    cardProps.leftLimaProps,
                                                ),
                                            },
                                        }),
                                    ],
                                    right: [
                                        layout.component({
                                            type: 'lima',
                                            props: {
                                                ...returnOverrideCardProps(
                                                    '8-card',
                                                    'lima-right',
                                                    curation,
                                                    themeOverride,
                                                    cardProps.rightLimaProps,
                                                ),
                                            },
                                        }),
                                    ],
                                },
                            }),
                        ],
                    },
                }),
            ],
        },
    })
}

export const sixCardLayout = ({
    featureToggle,
    curation,
    themeOverride,
}: cardLayoutProps) => {
    return layout.nestedComposition({
        type: 'thor',
        feature: featureToggle,
        props: {
            hasBackgroundFill: true,
            containerWidth: '100%',
            verticalGutters: false,
            horizontalGutters: true,
            verticalSpacing: 'lg',
            themeOverride: themeOverride,
        },
        contentAreas: {
            main: [
                layout.component({
                    type: 'sierra',
                    props: {
                        verticalSpacing: 'gridGap',
                        fixedRatios: ['16:9'],
                        heroImageLayout:
                            ResponsivePictureLayout.ObjectFitContain,
                        cardOrientation: {
                            type: 'Landscape-Portrait',
                            maxBreakpoint: 'md',
                        },
                        cardLayout: [
                            'the-west-hero',
                            'teaser-xsVisible-mdHidden-Image',
                        ],
                        overrideRestrictedVideoPlayback: true,
                        dataDefinitionArgs: {
                            type: 'curation',
                            name: curation,
                            offset: 0,
                            pageSize: 2,
                        },
                    },
                }),

                layout.component({
                    type: 'lima',
                    props: {
                        cardOrientation: {
                            type: 'Landscape-Portrait',
                        },
                        cardLayout: [
                            ['teaserWithBackground'],
                            ['teaserWithBackground'],
                            ['teaserWithBackground'],
                            ['teaserWithBackground'],
                        ],
                        intermediateColumns: 2,
                        finalColumns: 4,
                        dataDefinitionArgs: {
                            type: 'curation',
                            name: curation,
                            offset: 2,
                            pageSize: 4,
                        },
                        hasBorder: false,
                    },
                }),
            ],
        },
    })
}

export function getThemedSegment(props: {
    featureToggle?: Features
    layoutVersion: layouts
    curation: curations
    themeOverride: themeOverrides
    cardExtensionProps?: CardExtensionProps
}) {
    switch (props.layoutVersion) {
        case '7-card':
            return sevenCardLayout({
                featureToggle: props.featureToggle,
                curation: props.curation,
                themeOverride: props.themeOverride,
                cardExtensionProps:
                    props.cardExtensionProps?.sevenCardExtension,
            })
        case '8-card':
            return eightCardLayout({
                featureToggle: props.featureToggle,
                curation: props.curation,
                themeOverride: props.themeOverride,
                cardExtensionProps:
                    props.cardExtensionProps?.eightCardExtension,
            })
        case '6-card':
            return sixCardLayout({
                featureToggle: props.featureToggle,
                curation: props.curation,
                themeOverride: props.themeOverride,
                cardExtensionProps:
                    props.cardExtensionProps?.eightCardExtension,
            })

        case '4-card':
        default:
            return fourCardLayout({
                featureToggle: props.featureToggle,
                curation: props.curation,
                themeOverride: props.themeOverride,
                cardExtensionProps: props.cardExtensionProps?.fourCardExtension,
            })
    }
}
