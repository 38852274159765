import { AdPageKind } from '../../advertising'

/**
 * Build the election page kind from the election id.
 *
 * @param electionId - election id to determien from
 * @returns
 */
export const getElectionAdPageKindFromElectionId = (
    electionId: string,
): AdPageKind | null => {
    switch (electionId) {
        case 'wa-election-2025':
            return 'Politics/WAelection2025/home'
        case 'federal-election-2025':
            return 'Politics/FederalElection2025/home'
        default:
            return null
    }
}
