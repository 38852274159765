import {
    CardBreakpointRatios,
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
    FixedRatio,
} from '@news-mono/web-common'
import React from 'react'
import { TeaserMode } from '../../cards/CardText/CardTeaser'
import { KickerMode } from '../../cards/Kicker/Kicker'
import { CardOrientationOptions } from '../../cards/Portrait/Portrait'
import {
    LoadMore,
    LoadMoreRouteProps,
} from '../../data-controllers/LoadMore/LoadMore'
import {
    calculateExpectedCardCountFromContentData,
    CollectionRouteProps,
} from '../../collections/collections-props.routing'
import {
    Lima,
    LimaLayouts,
    RemainingLayouts,
} from '../../collections/Lima/Lima'
import { TimestampType } from '../../content/CardTimestamp/CardTimestamp'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { useProduct } from '../../__product/useProduct'
import { FontScales } from '../../__styling/settings/fontScale'

export interface LimaRouteProps
    extends CollectionRouteProps,
        ResponsiveContainer,
        LoadMoreRouteProps {
    fixedRatios?: FixedRatio[] | CardBreakpointRatios
    teaserMode?: TeaserMode
    hideByline?: boolean
    kickerMode?: KickerMode
    timestamp?: TimestampType
    initialColumns?: number
    intermediateColumns?: number
    finalColumns?: number
    disableImageLazyLoad?: boolean
    hasBackground?: boolean
    fontScale?: FontScales
    isSponsoredCollection?: boolean
    cardOrientation?: CardOrientationOptions
    cardLayout?: LimaLayouts
    remainingCardLayout?: RemainingLayouts
    stretchSelf?: boolean
    hasSeparator?: boolean
    minimumNumberOfCards?: number
    viewMoreLinkUrl?: string
    hasBorder?: boolean
    hideBottomBorder?: boolean
    hideEditorialType?: boolean
    canPlayVideoInline?: boolean
    marginBottom?: number
    hideSeparators?: boolean
    disclaimerText?: string
    anchorId?: string
    allowLiveBlogMilestoneTeaser?: boolean
}

export const LimaRegistration = createRegisterableComponentWithData(
    'lima',
    ContentDataDefinitionLoader,
    (props: LimaRouteProps, data, services) => {
        const product = useProduct()
        return (
            <LoadMore
                dataDefinition={data.dataDefinitionArgs}
                services={services}
                loadMoreEnabled={props.loadMoreEnabled === true}
                data={data}
                onLoadMore={(loadMoreData) => {
                    services.onEvent(
                        createCollectionAvailableEvent(
                            loadMoreData.result.publications,
                            'Lima',
                            product,
                            services.onEvent,
                        ),
                    )
                }}
                render={(loadMoreData) => {
                    return (
                        <Lima
                            items={
                                loadMoreData.loaded
                                    ? {
                                          loaded: true,
                                          result: loadMoreData.result
                                              .publications,
                                      }
                                    : { loaded: false }
                            }
                            expectedCards={calculateExpectedCardCountFromContentData(
                                data.dataDefinitionArgs,
                            )}
                            sectionHeader={props.sectionHeader}
                            hideByline={props.hideByline}
                            kickerMode={props.kickerMode}
                            timestamp={props.timestamp}
                            teaserMode={props.teaserMode}
                            initialColumns={props.initialColumns}
                            intermediateColumns={props.intermediateColumns}
                            finalColumns={props.finalColumns}
                            verticalSpacing={props.verticalSpacing}
                            fixedRatios={props.fixedRatios}
                            disableImageLazyLoad={props.disableImageLazyLoad}
                            hasBackground={props.hasBackground}
                            hasSeparator={props.hasSeparator}
                            fontScale={props.fontScale}
                            onEvent={services.onEvent}
                            isSponsoredCollection={props.isSponsoredCollection}
                            cardOrientation={props.cardOrientation}
                            cardLayout={props.cardLayout}
                            remainingCardLayout={props.remainingCardLayout}
                            stretchSelf={props.stretchSelf}
                            minimumNumberOfCards={props.minimumNumberOfCards}
                            containerWidthRatios={props.containerWidthRatios}
                            viewMoreLinkUrl={props.viewMoreLinkUrl}
                            hasBorder={props.hasBorder}
                            hideBottomBorder={props.hideBottomBorder}
                            hideEditorialType={props.hideEditorialType}
                            canPlayVideoInline={props.canPlayVideoInline}
                            marginBottom={props.marginBottom}
                            hideSeparators={props.hideSeparators}
                            disclaimerText={props.disclaimerText}
                            anchorId={props.anchorId}
                            allowLiveBlogMilestoneTeaser={
                                props.allowLiveBlogMilestoneTeaser
                            }
                        />
                    )
                }}
            />
        )
    },
)
