import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import {
    Theme,
    breakpoint,
    calcRem,
    colors,
    fluidTypography,
    themedValue,
    transition,
    breakpointMax,
} from '../../../..'
import { zIndex } from '../../../../__styling/settings/z-index'
import { TextLink } from '../../../../typography/TextLink/TextLink'
import { BreachWallStyle } from '../../helpers/BreachWallTypes'

export const StyledBreachWallContent = styled('div')(
    ({
        theme,
        backgroundColor,
    }: {
        theme: Theme
        backgroundColor?: string
    }) => ({
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
        zIndex: zIndex.common.regWall.box,
        fontFamily: theme.fonts.subscriber.text,
        fontSize: calcRem(18),
        color: colors.white,
        padding: calcRem(theme.margins.md, 0),
        backgroundBlendMode: 'overlay, normal',

        backgroundColor:
            backgroundColor ||
            themedValue(theme, {
                thewest: theme.sectionValues.primaryColor,
                perthnow: colors.perthnow.greyBasalt90,
                sevennews: colors.sevennews.charade,
                fallback: undefined,
            }),
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% auto',
        backgroundPosition: 'top',
        [breakpoint('xxs')]: {
            paddingTop: calcRem(theme.margins.lg),
            paddingBottom: calcRem(theme.margins.md),
        },

        [breakpoint('md')]: {
            backgroundPosition: 'right',
            backgroundSize: 'cover',
        },

        '&.breach-190-year': {
            backgroundImage: 'unset',
            background:
                tokens.thewest.colors.palette.anniversary190YearGradient,
            '@media screen and (max-width:500px)': {
                paddingTop: calcRem(20),
            },
        },
    }),
)

interface StyledBreachWallContent2022Props {
    largeImage?: string
    smallImage?: string
}
export const StyledBreachWallContent2022 = styled(StyledBreachWallContent)(
    ({ largeImage, smallImage }: StyledBreachWallContent2022Props) => ({
        padding: calcRem(120, 24, 24, 24),
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundBlendMode: 'revert',
        backgroundImage: smallImage ? `url('${smallImage}')` : undefined,
        backgroundSize: '100% auto',
        overflow: 'hidden',

        '&.default-breachwall-2024': {
            padding: calcRem(18, 24, 24, 18),
        },

        [breakpoint('xxs')]: {
            padding: calcRem(30, 24, 24, 18),
            '&.default-breachwall-2024': {
                padding: calcRem(30, 24, 24, 18),
            },
        },

        [breakpoint('sm')]: {
            backgroundImage: largeImage ? `url('${largeImage}')` : undefined,
            backgroundPosition: 'right',
            backgroundSize: 'cover',
        },

        '&.abtest-container': {
            backgroundImage: 'unset',
            background: tokens.thewest.colors.palette.newWestGradient.blue,
            paddingTop: calcRem(40),
        },

        '&.abtest2-container': {
            backgroundImage: smallImage ? `url('${smallImage}')` : undefined,
            backgroundSize: 'cover',
            backgroundPosition: 'left',
            padding: calcRem(30, 24, 24, 24),
        },

        '&.vanishing-cousins': {
            padding: calcRem(30, 24, 24, 24),
            backgroundSize: 'cover',
        },

        '&.black-friday': {
            padding: calcRem(30, 24, 24, 24),
            backgroundSize: 'cover',
            backgroundPosition: 'bottom',
        },

        '&.breach-190-year': {
            backgroundImage: 'unset',
            background:
                tokens.thewest.colors.palette.anniversary190YearGradient,
            '@media screen and (max-width:500px)': {
                paddingTop: calcRem(20),
            },
        },

        '&.afl50': {
            padding: calcRem(30, 24, 24, 24),
            background: `linear-gradient(to bottom,rgba(0,0,0,0.1) 0%, rgba(0, 0, 0, 0.1) 70%, rgba(0, 0, 0, 0.5) 95%),${
                smallImage ? `url('${smallImage}')` : `url('${largeImage}')`
            }`,
            backgroundPosition: 'top',
            backgroundRepeat: 'repeat',
            [breakpoint('sm')]: {
                backgroundImage: `${
                    largeImage ? `url('${largeImage}')` : undefined
                }`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                paddingBottom: '60px',
                backgroundSize: 'cover',
            },
        },
    }),
)

export const StyledRegistrationInfo = styled('div')(({ theme }) => ({
    width: '100%',
}))

export const StyledABTestBannerWrap = styled('div')<{
    breachStyle?: BreachWallStyle
}>(({ theme, breachStyle }) => ({
    width: '100%',
    marginBottom: calcRem(theme.margins.lg),
    color: tokens.thewest.colors.palette.white,

    h2: {
        margin: 0,
        lineHeight: 1.1,
        fontSize:
            breachStyle === 'vanishing-cousins' ? calcRem(72) : calcRem(56),
        letterSpacing:
            breachStyle === 'vanishing-cousins' ? calcRem(0.5) : undefined,
        wordSpacing:
            breachStyle === 'vanishing-cousins' ? calcRem(5) : undefined,

        [breakpoint('sm')]: {
            fontSize:
                breachStyle === 'vanishing-cousins'
                    ? calcRem(128)
                    : calcRem(80),
        },
    },
    p: {
        fontSize: calcRem(24),
        fontWeight: 600,
        lineHeight: 1.1,
        marginTop: calcRem(theme.margins.sm),

        '&.abtest-terms': {
            marginTop:
                breachStyle === 'vanishing-cousins'
                    ? 0
                    : calcRem(theme.margins.md),
            fontSize: calcRem(16),
            fontWeight: breachStyle === 'vanishing-cousins' ? 100 : 400,
            letterSpacing: 'normal',
        },

        [breakpoint('sm')]: {
            fontSize: calcRem(40),
            marginTop: 0,
        },
    },
}))

export const StyledTerms2022 = styled.span<{ breachStyle: BreachWallStyle }>(
    ({ theme, breachStyle }) => ({
        flex: '0 1 100%',
        width: 0,
        border: 'none',
        borderRadius: calcRem(5),
        fontSize: calcRem(16),
        textAlign: 'center',
        textTransform: 'initial',
        padding: '1rem',
        justifySelf: 'center',
        maxWidth:
            breachStyle === 'breach-190-year' ? calcRem(652) : calcRem(636),

        backgroundColor:
            breachStyle === 'vanishing-cousins' ||
            breachStyle === 'black-friday' ||
            breachStyle === 'afl50'
                ? 'none'
                : theme.colors.background.primary,
        color:
            breachStyle === 'vanishing-cousins' ||
            breachStyle === 'black-friday' ||
            breachStyle === 'afl50'
                ? tokens.thewest.colors.palette.white
                : theme.colors.text.primary,
        fontFamily: theme.fonts.serif,

        [breakpoint('sm')]: {
            fontSize: calcRem(16),
            padding: '1rem',
        },

        '& a': {
            color:
                breachStyle === 'black-friday' ||
                breachStyle === 'vanishing-cousins' ||
                breachStyle === 'afl50'
                    ? tokens.thewest.colors.palette.white
                    : tokens.thewest.colors.actions.link.default,
        },

        '&.optimise-blue': {
            maxWidth: calcRem(668),
        },

        '&.afl50-terms': {
            background: 'none',
            color: tokens.thewest.colors.palette.white,
            a: {
                color: tokens.thewest.colors.palette.white,
            },
        },
    }),
)

const fluidTitle = (theme: Theme) =>
    themedValue(theme, {
        sevennews: fluidTypography(0, 1000, 24, 30),
        perthnow: fluidTypography(0, 1020, 24, 30),
        thewest: fluidTypography(0, 1220, 48, 56),
        fallback: undefined,
    })

export const StyledTitle = styled('h2')(({ theme }) => ({
    position: 'relative',
    margin: calcRem(theme.margins.sm),
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: 1.267,
    '&.sub-title': {
        fontSize: calcRem(67),
    },

    '&.default-annual-title': {
        maxWidth: calcRem(500),
        fontWeight: 400,
        fontSize: calcRem(28),
        color: tokens.thewest.colors.palette.black,
        margin: 0,
    },

    ...fluidTitle(theme),

    [breakpoint('xs')]: {
        margin: calcRem(0, 0, theme.margins.sm, 0),
        '&.default-annual-title': {
            fontSize: calcRem(40),
            marginBottom: calcRem(theme.margins.lg),
        },
    },
}))

export const StyledTitleImage = styled('img')<{
    breachStyle?: BreachWallStyle
}>(({ breachStyle }) => ({
    padding: calcRem(32, 0, 0),
    width: '100%',

    [breakpoint('xs')]: {
        padding: calcRem(32),
    },
}))

export const StyledAFLTitleBlock = styled('div')(() => ({
    position: 'relative',
    width: '100%',
    marginBottom: calcRem(100),
    h2: {
        fontFamily: '"rustica", sans-serif;',
        fontStyle: 'italic',
        fontSize: calcRem(120),
        fontWeight: 900,
    },
    [breakpointMax('xs')]: {
        marginTop: calcRem(-114),
        marginBottom: calcRem(110),
        h2: {
            fontSize: calcRem(64),
        },
        p: {
            width: '100%',
            maxWidth: calcRem(225),
            textAlign: 'center',
            fontSize: calcRem(15),
            padding: calcRem(0, 20),
        },
    },
}))

export const StyledSubTitle = styled('p')(() => ({
    position: 'absolute',
    bottom: `-${calcRem(34)}`,
    right: '50%',
    transform: 'translateX(50%) rotate(-4deg)',
    background: tokens.thewest.colors.palette.AFL.aflgold,
    color: tokens.thewest.colors.palette.black,
    width: 'fit-content',
    padding: calcRem(0, 40),
    fontFamily: '"rustica", sans-serif;',
    fontSize: calcRem(24),
    fontWeight: 900,
    [breakpointMax('xs')]: {
        bottom: `-${calcRem(30)}`,
    },
}))

export const StyledFooterImage = styled('img')(() => ({
    width: '100%',
    maxWidth: calcRem(240),
    margin: `${calcRem(30)} ${calcRem(70)}`,
    [breakpointMax('xs')]: {
        width: 'Calc(100% - 50px)',
    },
}))

export const StyledMessage = styled('div')<{ breachStyle?: BreachWallStyle }>(
    ({ theme, breachStyle }) => ({
        margin: 'auto',
        marginBottom: calcRem(theme.margins.sm),
        maxWidth: breachStyle === 'vanishing-cousins' ? '100%' : calcRem(600),
        fontFamily:
            breachStyle === 'vanishing-cousins'
                ? 'futura-pt, sans-serif'
                : undefined,

        p: {
            margin:
                breachStyle === 'vanishing-cousins'
                    ? 'auto'
                    : calcRem(0, theme.margins.sm),
            marginBottom:
                breachStyle === 'vanishing-cousins' ? calcRem(14) : undefined,
            fontSize:
                breachStyle === 'vanishing-cousins' ? calcRem(24) : calcRem(20),
            fontWeight: breachStyle === 'vanishing-cousins' ? 400 : 'normal',
            letterSpacing:
                breachStyle === 'vanishing-cousins' ? calcRem(4) : undefined,
            textAlign: 'center',

            '&.full-width': {
                fontWeight:
                    breachStyle === 'vanishing-cousins' ? 100 : 'normal',
                letterSpacing:
                    breachStyle === 'vanishing-cousins'
                        ? calcRem(2.5)
                        : undefined,
                marginBottom:
                    breachStyle === 'vanishing-cousins'
                        ? calcRem(8)
                        : undefined,
                width: '100%',
            },
        },

        '&.abtest2-container': {
            maxWidth: '100%',
        },
    }),
)

export const StyledPackage = styled('div')(({ theme }) => ({
    flexGrow: 1,
    flexBasis: 400,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: 0,

    [breakpoint('xs')]: {
        margin: calcRem(0, theme.margins.md),
    },
}))

export const StyledPackageBgImage = styled('picture')(() => ({
    position: 'absolute',
    zIndex: 0,
    top: '-40px',
    width: 'Calc(100% - 20px)',
    transform: 'scaleY(1.1)',
    img: {
        width: '100%',
    },

    [breakpointMax('xs')]: {
        height: '100%',
        width: '100%',
        top: '-10px',
        transform: 'scaleY(1) scaleX(1.1)',
        img: {
            height: '90%',
        },
    },
}))

export const StyledPackage2022 = styled.div<{
    breachStyle: BreachWallStyle
}>(({ breachStyle }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'center',
    marginTop: 0,
    position: 'relative',

    '&.afl50-package-container': {
        justifyContent: 'center',
    },

    [breakpoint('xs')]: {
        marginTop: 0,
    },
}))

export const StyledPackagesNew2024 = styled.div<{
    breachStyle: BreachWallStyle
}>(({ breachStyle }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'center',
    marginTop: calcRem(20),
    paddingBottom: calcRem(40),
    position: 'relative',

    '&.default-annual-packages': {
        marginTop: 0,
    },

    '&.afl50-package-container': {
        justifyContent: 'center',
    },

    [breakpoint('xs')]: {
        marginTop: 0,
    },
}))

export const StyledButton = styled('button')(({ theme }) => ({
    display: 'inline-block',
    border: `1px solid ${colors.white}`,
    padding: calcRem(theme.margins.sm, theme.margins.xl),
    marginTop: calcRem(theme.margins.lg),
    background: 'none',
    color: colors.white,
    borderRadius: calcRem(2),
    fontFamily: theme.fonts.sansSerif,
    fontWeight: 700,
    fontSize: calcRem(13),
    textDecoration: 'none',
    textTransform: 'uppercase',
    cursor: 'pointer',
    ...transition({ properties: ['background', 'color'] }),

    '&:hover': {
        backgroundColor: colors.white,
        color: themedValue(theme, {
            thewest: colors.thewest.greyGorilla,
            perthnow: colors.perthnow.blueKyanite,
            sevennews: colors.sevennews.red,
            fallback: undefined,
        }),
    },

    '&:focus': {
        backgroundColor: themedValue(theme, {
            thewest: colors.thewest.greyGorilla,
            perthnow: colors.perthnow.pinkThulite,
            sevennews: colors.sevennews.red,
            fallback: undefined,
        }),
        borderColor: themedValue(theme, {
            thewest: colors.thewest.greyGorilla,
            perthnow: colors.perthnow.pinkThulite,
            sevennews: colors.sevennews.red,
            fallback: undefined,
        }),
        color: 'currentColor',
        outline: 'none',
        textDecoration: 'underline',
    },
}))

export const StyledTerms = styled('p')(({ theme }) => ({
    color: colors.white,
    margin: 0,
    marginTop: calcRem(theme.margins.sm),
    fontSize: calcRem(11),
}))

export const StyledLiveTrailerWrap = styled('div')(({ theme }) => ({
    boxShadow: '0 0 16px rgba(0,0,0,0.5)',
    margin: `${calcRem(30)} auto`,
}))

export const StyledBlackFridayTape = styled('img')<{
    breachStyle?: BreachWallStyle
}>(({ breachStyle }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: calcRem(24),
    position: 'absolute',

    [breakpoint('xs')]: {
        height: calcRem(52),
    },

    '&.left-tape': {
        top: 0,
        left: 0,
        transform: 'rotate(-12deg) translate(-9%, 0%)',

        // desktop transform
        [breakpoint('xs')]: {
            transform: 'rotate(-60deg) translate(-5.25%, -615%)',
        },
    },
    '&.right-tape': {
        top: 0,
        right: 0,
        transform: 'rotate(33deg) translate(40%, -200%)',

        // desktop transform
        [breakpoint('xs')]: {
            transform: 'rotate(18deg) translate(38%, -199%)',
        },
    },
    '&.bottom-tape': {
        width: '105%',
        transform: 'rotate(5deg) translate(-0.5%, -70%)',

        // We don't want to show the bottom ribbon on anything other than
        // mobile version, so we do this to hide it
        [breakpoint('xs')]: {
            display: 'none',
        },

        bottom: 0,
    },
}))

export const StyledHelpMessage = styled('p')(({ theme }) => ({
    margin: calcRem(theme.margins.sm, 0),
}))

export const StyledTextLink = styled(TextLink)<{ reverseColor: boolean }>(
    ({ reverseColor }) => ({
        color: reverseColor ? colors.white : undefined,
    }),
)
