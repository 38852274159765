import styled from '@emotion/styled'
import {
    getComponentTheme,
    breakpointSwitch,
    calcRem,
    breakpoint,
} from '../../../__styling'

export const StyledElectorateWidgetContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(16),

    [breakpoint('lg')]: {
        gap: calcRem(40),
    },
})

export const StyledTitleContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: calcRem(24),
    alignSelf: 'stretch',
    padding: calcRem(0),
})

export const StyledElectorateTitle = styled('h1')(({ theme }) => {
    const electionTheme = getComponentTheme(theme, 'electionTheme')

    return [
        {
            textAlign: 'right',
            margin: 0,
        },
        electionTheme.title &&
            breakpointSwitch(electionTheme.title, (styles) => styles),
    ]
})
