import React from 'react'
import { StyledTextLink } from '../../user-registration/breach/components/BreachWallContent/BreachWallContent.styled'
import {
    BottomTextWrap,
    InfoText,
    InfoTextLink,
} from '../../user-registration/breach/components/PuzzlesBreach/PuzzlesBreachwall.styled'
import {
    StyledOrderedList,
    StyledTAndC,
} from '../RegionalsSwgPromoPage/RegionalsSwgPromoPage.styled'
import { SwgCampaignOfferValues } from './CampaignOffer'

export const getUniversityOfferSwgCampaignValues =
    (): SwgCampaignOfferValues => {
        return {
            isDefault: false,
            creative: 'TW_Subscribe_With_Google_Campaign',
            deal: 'swg-promo',
            type: 'Premium - Inline',
            sku: 'everyday_digital_curtin_university',
            price: {
                weekly: 0.25,
                originalPrice: '$28 every 4 weeks ',
                promoPrice: '$1 every 4 weeks for 12 months ',
            },
            BenefitListComponent: (
                <>
                    <h3>How to Subscribe with Google:</h3>
                    <StyledOrderedList>
                        <li>
                            Enter your Student ID and your Curtin University
                            email address
                        </li>
                        <li>
                            You will receive a verification email to your Curtin
                            University inbox, follow the prompts to verify your
                            email address
                        </li>
                        <li>
                            Click the Subscribe with Google button and follow
                            the prompts
                        </li>
                        <li>
                            Subscribing with Google is quick and easy! To set up
                            your account you can use your existing GPAY account
                            OR enter any valid email address with your preferred
                            payment method to subscribe.
                        </li>
                        <li>
                            This payment method will be charged $1 every 4 weeks
                            for 12 months. Total charge $13.
                        </li>
                        <li>
                            For more information read our{' '}
                            <StyledTextLink
                                reverseColor={false}
                                href={`https://subscriber.thewest.com.au/subscribe-with-google-faq`}
                            >
                                FAQs
                            </StyledTextLink>
                        </li>
                    </StyledOrderedList>
                </>
            ),
            TermsAndConditionsComponent: (
                <StyledTAndC>
                    <b>
                        This offer is valid until 31/12/2023 and only valid to
                        current students at Curtin University.
                    </b>{' '}
                    Your new subscription will be charged at $1.00 every 4 weeks
                    for the first 12 months. After 12 months, your subscription
                    will be charged at full price of $7.00 per week. This
                    subscription offer is valid for new subscribers only. New
                    subscribers in the same household as cancelling subscribers
                    (and subscribers who have cancelled any subscription in the
                    40 days prior to the date on which they attempt to take up
                    this offer) are ineligible for this offer. This subscription
                    offer can be terminated by you at any time (subject to
                    digital subscription terms and minimum total commitment of 4
                    weeks subscription) by providing at least 7 days’ notice in
                    writing to West Australian Newspapers Subscriber Services at{' '}
                    <StyledTextLink
                        reverseColor={false}
                        href={'mailto:subscriptions@wanews.com.au'}
                    >
                        subscriptions@wanews.com.au
                    </StyledTextLink>{' '}
                    or by calling 1800 811 855. Offers are subject to our
                    standard Subscription Terms and Conditions available at{' '}
                    <StyledTextLink
                        reverseColor={false}
                        href={
                            'https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=googlepaytos&ldl=en-GB'
                        }
                    >
                        https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=googlepaytos&ldl=en-GB
                    </StyledTextLink>
                    , and subscribers must meet the following technical
                    requirements to enjoy the digital subscription: iOS
                    requirement of 11.0 and Android minimum requirement of 5.0.
                    See Subscribe with Google FAQs at{' '}
                    <StyledTextLink
                        reverseColor={false}
                        href={
                            'https://subscriber.thewest.com.au/subscribe-with-google-faq'
                        }
                    >
                        https://subscriber.thewest.com.au/subscribe-with-google-faq
                    </StyledTextLink>
                    {'. '}
                    The West Australian reserves the right to cancel this offer
                    at any time.
                </StyledTAndC>
            ),
            InfoComponent: (onAllPackagesClicked) => (
                <React.Fragment>
                    <BottomTextWrap>
                        <InfoText>
                            Looking for a different subscription?{' '}
                            <InfoTextLink
                                href={'#'}
                                onEvent={onAllPackagesClicked}
                            >
                                Browse all
                            </InfoTextLink>{' '}
                        </InfoText>
                    </BottomTextWrap>
                    <p>Cancel anytime. 4 weeks minimum.</p>
                </React.Fragment>
            ),
        }
    }
