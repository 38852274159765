import { useQuery } from '@tanstack/react-query'

export const useElectionDayData = (apiEndpoint: string) => {
    const electionDayData = useQuery({
        queryKey: ['election-day-data'],
        queryFn: async () => {
            const url = `${apiEndpoint}/election-day/election-day.json`

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error('Unable to retrieve election day data')
            }

            const res = await response.json()

            return res
        },
        staleTime: 6000,
        cacheTime: 7000,
    })

    return electionDayData
}
