import styled, { CSSObject } from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import {
    createPropFilter,
    WebLink,
    WebLinkProps,
    withClass,
} from '@news-mono/web-common'
import { IconArrowCircle } from '../../icons'
import { IconAnnouncement } from '../../icons/IconAnnouncement/IconAnnouncement' //mynote can add an icon like this here
import { IconChevronRight } from '../../icons/IconChevronRight/IconChevronRight'
import { IconLightning } from '../../icons/IconLightning/IconLightning'
import { IconPremium } from '../../icons/IconPremium/IconPremium'
import {
    Pick11Logo,
    StateElectionLogo,
    TheGameLogo,
} from '../../logos/TheGameLogo/TheGameLogo'

import { TheWest190SmallLogo } from '../../logos/TheWest190Small/TheWest190Small'
import { fonts } from '../../__styling'
import { colors } from '../../__styling/settings/colors'
import { easing } from '../../__styling/settings/easing'
import { FontScales } from '../../__styling/settings/fontScale'
import { metrics } from '../../__styling/settings/metrics'
import { breakpoint, breakpointBetween } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { Theme } from '../../__styling/themes'
import { GameOnParisLogo } from '../../logos/GameOnParis/GameOnParisLogo'

export interface BreakingNewsTheme {
    background: string
    color: string
    titleColor: string
    liveColor: string
    markColor: string
    textShadow?: string
    height?: number
    fonts?: {
        breakingNewsTitle?: string
        breakingNewsWeight?: number
    }
    hover: {
        background: string
        color: string
        titleColor: string
        liveColor: string
        markColor: string
        textShadow?: string
    }
    focus: {
        background: string
        color: string
        outlineColor: string
        liveColor: string
        titleColor: string
        markColor: string
    }
}

/**
 * csk: Claremon Serial Killings
 * pick11: The Game - Cricket Pick 11
 */
export const BreakingNewsThemeNames = [
    'default',
    'announcement',
    'cricket',
    'thegame',
    'csk',
    'pick11',
    'blackfriday',
    'grand-final',
    'grand-final-freo',
    'promo-strap',
    '190-years-of-the-west',
    'paris-2024',
    'wa-election-2025',
    'federal-election-2025',
]
export type BreakingNewsThemeName = typeof BreakingNewsThemeNames[number]
export type BreakingNewsThemes = Record<
    BreakingNewsThemeName,
    BreakingNewsTheme
>

export const getBreakingNewsThemes = (theme: Theme): BreakingNewsThemes => ({
    default: {
        background: colors.thewest.ladybird,
        color: colors.white,
        liveColor: colors.white,
        titleColor: colors.white,
        markColor: colors.white,

        hover: {
            background: colors.black,
            color: colors.white,
            titleColor: colors.white,
            liveColor: colors.white,
            markColor: colors.white,
            textShadow: 'none',
        },
        focus: {
            background: colors.white,
            color: theme.sectionValues.primaryColor,
            outlineColor: theme.sectionValues.primaryColor,
            liveColor: theme.sectionValues.primaryColor,
            titleColor: theme.sectionValues.primaryColor,
            markColor: theme.sectionValues.primaryColor,
        },
    },
    announcement: {
        background: colors.thewest.greyGorilla,
        color: colors.white,
        liveColor: colors.white,
        titleColor: colors.white,
        markColor: colors.white,

        hover: {
            background: theme.sectionValues.primaryColor,
            color: colors.white,
            titleColor: colors.white,
            liveColor: colors.white,
            markColor: colors.white,
        },
        focus: {
            background: colors.thewest.greyKoala,
            color: colors.white,
            outlineColor: colors.thewest.greyKoala,
            liveColor: colors.white,
            titleColor: colors.white,
            markColor: colors.white,
        },
    },
    'wa-election-2025': {
        background: colors.thewest.greySeal,
        color: '#2B2D2E', //colors.black,
        liveColor: colors.black,
        titleColor: colors.black,
        markColor: colors.black,

        hover: {
            background: colors.thewest.greyHippo,
            color: colors.white,
            titleColor: colors.white,
            liveColor: colors.white,
            markColor: colors.white,
        },
        focus: {
            background: colors.thewest.greyKoala,
            color: colors.white,
            outlineColor: colors.thewest.greyKoala,
            liveColor: colors.white,
            titleColor: colors.white,
            markColor: colors.white,
        },
    },
    'federal-election-2025': {
        background: colors.thewest.greySeal,
        color: '#2B2D2E', //colors.black,
        liveColor: colors.black,
        titleColor: colors.black,
        markColor: colors.black,

        hover: {
            background: colors.thewest.greyHippo,
            color: colors.white,
            titleColor: colors.white,
            liveColor: colors.white,
            markColor: colors.white,
        },
        focus: {
            background: colors.thewest.greyKoala,
            color: colors.white,
            outlineColor: colors.thewest.greyKoala,
            liveColor: colors.white,
            titleColor: colors.white,
            markColor: colors.white,
        },
    },

    'grand-final': {
        background: colors.thewest.grandfinalBlue,
        color: colors.white,
        liveColor: colors.white,
        titleColor: colors.white,
        markColor: colors.white,
        hover: {
            background: colors.thewest.grandfinalBlack,
            color: colors.white,
            titleColor: colors.white,
            liveColor: colors.white,
            markColor: colors.white,
            textShadow: 'none',
        },
        focus: {
            background: colors.thewest.grandfinalBlue,
            color: colors.white,
            outlineColor: colors.thewest.grandfinalBlue,
            liveColor: colors.white,
            titleColor: colors.white,
            markColor: colors.white,
        },
    },
    'grand-final-freo': {
        background: colors.thewest.freoGrandFinalPurple,
        color: colors.white,
        liveColor: colors.white,
        titleColor: colors.white,
        markColor: colors.white,
        hover: {
            background: colors.thewest.freoGrandFinalPurpleBlack,
            color: colors.white,
            titleColor: colors.white,
            liveColor: colors.white,
            markColor: colors.white,
            textShadow: 'none',
        },
        focus: {
            background: colors.thewest.freoGrandFinalPurpleBlack,
            color: colors.white,
            outlineColor: colors.thewest.freoGrandFinalPurpleBlack,
            liveColor: colors.white,
            titleColor: colors.white,
            markColor: colors.white,
        },
    },
    cricket: {
        background: `${
            colors.thewest.cricketGreen
        } url(${require('./assets/grass.jpg')}) repeat-x`,
        color: colors.white,
        liveColor: colors.white,
        titleColor: colors.white,
        markColor: colors.white,
        textShadow: `-1px -1px 0 ${colors.black50}, 1px -1px 0 ${colors.black50}, -1px 1px 0 ${colors.thewest.greyElephant},
        1px 1px 0 ${colors.thewest.greyElephant}, 0 1px 2px ${colors.black80}`,

        hover: {
            background: colors.thewest.greyGorilla,
            color: colors.white,
            titleColor: colors.white,
            liveColor: colors.white,
            markColor: colors.white,
        },
        focus: {
            background: colors.thewest.greyKoala,
            color: colors.white,
            outlineColor: colors.thewest.greyKoala,
            liveColor: colors.white,
            titleColor: colors.white,
            markColor: colors.white,
        },
    },
    thegame: {
        background: `${colors.thewest.indigo} linear-gradient(${colors.thewest.java}, ${colors.thewest.indigo})`,
        color: colors.white,
        titleColor: colors.white,
        liveColor: colors.white,
        markColor: colors.white,

        hover: {
            background: colors.thewest.indigo,
            color: colors.white,
            titleColor: colors.white,
            liveColor: colors.white,
            markColor: colors.white,
        },
        focus: {
            background: colors.thewest.greyKoala,
            color: colors.white,
            outlineColor: colors.thewest.greyKoala,
            liveColor: colors.white,
            titleColor: colors.white,
            markColor: colors.white,
        },
    },
    'paris-2024': {
        background: colors.thewest.pastelGreen,
        color: colors.white,
        liveColor: colors.white,
        titleColor: colors.white,
        markColor: colors.white,

        hover: {
            background: colors.thewest.pastelGreen,
            color: colors.white,
            liveColor: colors.white,
            titleColor: colors.white,
            markColor: colors.white,
        },
        focus: {
            background: colors.thewest.pastelGreen,
            color: colors.white,
            outlineColor: colors.white,
            liveColor: colors.white,
            titleColor: colors.white,
            markColor: colors.white,
        },
    },
    csk: {
        background: colors.thewest.greyRhino,
        color: colors.white,
        titleColor: colors.white,
        liveColor: colors.white,
        markColor: colors.white,
        textShadow: `0 1px 2px ${colors.black50}`,
        height: 60,

        hover: {
            background: colors.thewest.sunshineYellow,
            color: colors.thewest.greyGorilla,
            titleColor: colors.thewest.greyGorilla,
            liveColor: colors.thewest.greyGorilla,
            markColor: colors.thewest.greyGorilla,
            textShadow: 'none',
        },
        focus: {
            background: colors.thewest.greyKoala,
            color: colors.white,
            outlineColor: colors.thewest.greyKoala,
            liveColor: colors.white,
            titleColor: colors.white,
            markColor: colors.white,
        },
    },
    pick11: {
        background: `${colors.thewest.canaryYellow} linear-gradient(90deg, ${colors.thewest.canaryYellow} 0%, ${colors.thewest.canaryYellow} 80%, ${colors.thewest.fantaOrange} 100%)`,
        color: colors.black,
        titleColor: colors.black,
        liveColor: colors.black,
        markColor: colors.black,
        height: 60,

        hover: {
            background: colors.thewest.engineRed,
            color: colors.white,
            titleColor: colors.white,
            liveColor: colors.white,
            markColor: colors.white,
            textShadow: 'none',
        },
        focus: {
            background: colors.thewest.greyKoala,
            color: colors.white,
            outlineColor: colors.thewest.greyKoala,
            liveColor: colors.white,
            titleColor: colors.white,
            markColor: colors.white,
        },
    },
    blackfriday: {
        background: colors.black,
        color: colors.white,
        titleColor: colors.white,
        liveColor: colors.white,
        markColor: colors.white,
        height: 50,

        fonts: {
            breakingNewsTitle: fonts.thewest.serif,
            breakingNewsWeight: 600,
        },

        hover: {
            background: colors.thewest.sunshineYellow,
            color: colors.black,
            titleColor: colors.black,
            liveColor: colors.black,
            markColor: colors.black,
            textShadow: 'none',
        },
        focus: {
            background: colors.black,
            color: colors.thewest.sunshineYellow,
            outlineColor: colors.thewest.sunshineYellow,
            liveColor: colors.thewest.sunshineYellow,
            titleColor: colors.thewest.sunshineYellow,
            markColor: colors.thewest.sunshineYellow,
        },
    },
    'promo-strap': {
        background: colors.thewest.westblue,
        color: colors.white,
        liveColor: colors.white,
        titleColor: colors.white,
        markColor: colors.white,

        hover: {
            background: colors.thewest.westblueTint,
            color: colors.white,
            titleColor: colors.white,
            liveColor: colors.white,
            markColor: colors.white,
            textShadow: 'none',
        },
        focus: {
            background: colors.white,
            color: theme.sectionValues.primaryColor,
            outlineColor: theme.sectionValues.primaryColor,
            liveColor: theme.sectionValues.primaryColor,
            titleColor: theme.sectionValues.primaryColor,
            markColor: theme.sectionValues.primaryColor,
        },
    },
    '190-years-of-the-west': {
        background: tokens.thewest.colors.palette.anniversaryPurpleGradient,
        color: tokens.thewest.colors.palette.white,
        liveColor: tokens.thewest.colors.palette.white,
        titleColor: tokens.thewest.colors.palette.white,
        markColor: tokens.thewest.colors.palette.white,

        hover: {
            background: `linear-gradient(${tokens.thewest.colors.palette.anniversaryPurple}, ${tokens.thewest.colors.palette.anniversaryPurple})`,
            color: tokens.thewest.colors.palette.white,
            titleColor: tokens.thewest.colors.palette.white,
            liveColor: tokens.thewest.colors.palette.white,
            markColor: tokens.thewest.colors.palette.white,
            textShadow: 'none',
        },
        focus: {
            background: colors.white,
            color: theme.sectionValues.primaryColor,
            outlineColor: theme.sectionValues.primaryColor,
            liveColor: theme.sectionValues.primaryColor,
            titleColor: theme.sectionValues.primaryColor,
            markColor: tokens.thewest.colors.palette.anniversaryPurple,
        },
    },
})

export type ThemeProp = {
    breakingNewsTheme: BreakingNewsThemeName
    hideOnMobileAndApp?: boolean
    removeBottomMargin?: boolean
}

const breakingNewsPadding = metrics.globalPadding * 0.75
const breakingNewsTitleClassName = 'breakingnews__title'
const breakingNewsMarkClassName = 'breakingnews__mark'
const breakingNewsLiveClassName = 'breakingnews__live'

const cskBackgroundStyles = (theme: BreakingNewsThemeName) => {
    if (theme !== 'csk') {
        return
    }

    const cskHorizDesktop = require('./assets/csk/CSK-horiz-desktop-default@2x.png')
    const cskHorizDesktopHover = require('./assets/csk/CSK-horiz-desktop-hover@2x.png')
    const cskHorizMobileDefault = require('./assets/csk/CSK-horiz-mobile-default@2x.png')
    const cskHorizMobileHover = require('./assets/csk/CSK-horiz-mobile-hover@2x.png')
    const cskHorizTablet = require('./assets/csk/CSK-horiz-tablet-default@2x.png')
    const cskHorizTabletHover = require('./assets/csk/CSK-horiz-tablet-hover@2x.png')

    return {
        backgroundImage: `url(${cskHorizMobileDefault})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right',
        backgroundSize: 'contain',
        height: calcRem(60),

        [breakpointBetween('xs', 'md')]: {
            backgroundImage: `url(${cskHorizTablet})`,
            paddingRight: 340, // prevent title text flowing over background image
        },

        [breakpoint('md')]: {
            backgroundImage: `url(${cskHorizDesktop})`,
            paddingRight: 250,
        },

        '&:hover': {
            backgroundImage: `url(${cskHorizMobileHover})`,

            [breakpointBetween('xs', 'md')]: {
                backgroundImage: `url(${cskHorizTabletHover})`,
            },

            [breakpoint('md')]: {
                backgroundImage: `url(${cskHorizDesktopHover})`,
            },
        },

        '&:focus': {
            backgroundImage: `url(${cskHorizMobileDefault})`,
            backgroundColor: colors.thewest.greyKoala,

            [breakpointBetween('xs', 'md')]: {
                backgroundImage: `url(${cskHorizTablet})`,
            },

            [breakpoint('md')]: {
                backgroundImage: `url(${cskHorizDesktop})`,
            },
        },
    }
}

export const StyledBreakingNews = styled(WebLink, {
    shouldForwardProp: createPropFilter<WebLinkProps>()([
        'breakingNewsTheme',
        'removeBottomMargin',
        'hideOnMobileAndApp',
    ]),
})<ThemeProp>((props) => {
    const theme = getBreakingNewsThemes(props.theme)[props.breakingNewsTheme]
    const height = theme.height ? theme.height : 50
    const bottomMargin = props.removeBottomMargin
        ? 0
        : metrics.thewest.margins.md

    return {
        lineHeight: 1.2,

        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        background: theme.background,
        backgroundSize: 'cover',
        color: theme.color,
        textDecoration: 'none',
        transition: `color ${easing.easeOut.fast}, background ${easing.easeOut.fast}`,
        padding: `${calcRem(8)} ${calcRem(9)}`,
        margin: calcRem(
            0,
            -metrics.thewest.margins.outerMargin,
            metrics.thewest.margins.sm,
        ),
        display: props.hideOnMobileAndApp ? 'none' : 'flex',
        [breakpoint('sm')]: {
            padding: `${calcRem(8)} ${calcRem(18)}`,
            margin: calcRem(0, 0, bottomMargin),
            display: 'flex',
        },

        [breakpoint('md')]: {
            height: calcRem(height),
            marginLeft: calcRem(metrics.thewest.margins.outerMargin),
            marginRight: calcRem(metrics.thewest.margins.outerMargin),
        },

        '&:hover': {
            background: theme.hover.background,
            color: theme.hover.color,

            [`& .${breakingNewsTitleClassName}`]: {
                color: theme.hover.titleColor,
            },

            [`& .${breakingNewsMarkClassName}`]: {
                fill: theme.hover.markColor,
            },

            [`& .${breakingNewsLiveClassName}`]: {
                color: theme.hover.liveColor,
            },
        },

        '&:focus': {
            outline: `2px solid ${theme.focus.outlineColor}`,
            background: theme.focus.background,
            color: theme.focus.color,
            textDecoration: 'underline',

            [`& .${breakingNewsTitleClassName}`]: {
                color: theme.focus.titleColor,
            },

            [`& .${breakingNewsMarkClassName}`]: {
                fill: theme.focus.markColor,
            },

            [`& .${breakingNewsLiveClassName}`]: {
                color: theme.focus.liveColor,
            },
        },

        ...cskBackgroundStyles(props.breakingNewsTheme),
    }
})

export interface StyledBreakingNewsTitleProps {
    fontScale: FontScales
}

export const StyledBreakingNewsTitle = withClass(breakingNewsTitleClassName)(
    styled('h2')<ThemeProp & StyledBreakingNewsTitleProps>((props) => {
        const theme = getBreakingNewsThemes(props.theme)[
            props.breakingNewsTheme
        ]

        return {
            fontSize: calcRem(Math.ceil(18 * props.fontScale)),
            fontFamily:
                theme.fonts?.breakingNewsTitle ||
                props.theme.fonts.cards.headlineAlternative,
            fontWeight: theme.fonts?.breakingNewsWeight || 600,
            margin: 0,
            paddingTop: 0,
            paddingLeft: calcRem(9),
            color: theme.titleColor,
            transition: `font-size ${easing.easeOut.fast}, fill ${easing.easeOut.fast}`,
            display: 'flex',
            flexGrow: 1,
            flexBasis: '100%',
            paddingRight: calcRem(breakingNewsPadding),
            overflow: 'hidden',
            textShadow: theme.textShadow,

            '&:hover': {
                textShadow: theme.hover.textShadow,
            },

            [breakpoint('md')]: {
                fontSize: calcRem(Math.ceil(24 * props.fontScale)),
                paddingLeft: calcRem(18),
                paddingBottom: 0,
            },
        }
    }),
)

const commonMarkCss: CSSObject = {
    transition: `fill ${easing.easeOut.fast}`,
    alignSelf: 'flex-start', // align with top line of text on phone
    marginTop: calcRem(5),

    [breakpoint('sm')]: {
        alignSelf: 'center', // vert align center on ipad/desktop
        marginTop: '0',
    },
}

export const StyledBreakingNewsMark = withClass(breakingNewsMarkClassName)(
    styled(IconLightning)<ThemeProp>(
        commonMarkCss,
        {
            width: calcRem(22),
            height: calcRem(28),
        },
        (props) => {
            return {
                fill: getBreakingNewsThemes(props.theme)[
                    props.breakingNewsTheme || 'default'
                ].markColor,
            }
        },
    ),
)

export const StyledAnnouncementMark = withClass(breakingNewsMarkClassName)(
    styled(IconAnnouncement)<ThemeProp>(
        commonMarkCss,
        {
            width: calcRem(24),
            height: calcRem(21),
        },
        (props) => {
            return {
                fill: getBreakingNewsThemes(props.theme)[
                    props.breakingNewsTheme || 'default'
                ].markColor,
            }
        },
    ),
)

export const StyledPromoStrapMark = styled(IconPremium)({
    width: calcRem(25),
    backgroundColor: colors.white,
    borderRadius: 30,
})
export const StyledIconChevronRight = styled(IconChevronRight)({
    marginLeft: calcRem(3),
    width: calcRem(8),
    height: calcRem(13),
    color: 'inherit',
    fill: 'currentColor',
    transform: 'translateY(2px)', // fix alignment issue
    display: 'none',

    [breakpoint('sm')]: {
        height: calcRem(14),
        width: calcRem(10),
        transform: 'translateY(1px)', // fix alignment issue
        display: 'inline',
    },
})

export const StyledSevenCricketLogo = styled('img')({
    boxSizing: 'content-box',
    width: calcRem(201),
    height: calcRem(51),
    marginRight: calcRem(metrics.thewest.margins.sm),
    position: 'absolute',
})

export const StyledLogoContainer = styled('div')({
    overflow: 'hidden',
    width: calcRem(45),
    height: calcRem(51),
    position: 'relative',
    alignSelf: 'flex-start',
    flex: `0 0 ${calcRem(45)}`,

    [breakpoint('sm')]: {
        width: calcRem(201),
        alignSelf: 'center',
        flexBasis: calcRem(201),
        transform: `translateY(2px)`, // Offset due to the shadow in the image
    },
})

export const StyledWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 auto',
})

export const StyledCricketPlayerWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
})

export const StyledGrandfinalWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: calcRem(theme.margins.sm),
}))

export const StyledGrandFinalText = styled('span')(({ theme }) => ({
    display: 'none',

    [breakpoint('xxs')]: {
        fontSize: calcRem(18),
        lineHeight: calcRem(18),
        display: 'inline',
        textTransform: 'uppercase',
        fontFamily: theme.fonts.sansSerifCond,
        fontWeight: 700,
    },
    [breakpoint('md')]: {
        fontSize: calcRem(20),
        lineHeight: calcRem(20),
    },
    [breakpoint('lg')]: {
        lineHeight: calcRem(theme.margins.lg),
        fontSize: calcRem(theme.margins.lg),
    },
}))

export const StyledIconArrowCircle = styled(IconArrowCircle)({
    [breakpoint('xxs')]: {
        width: calcRem(16),
        height: calcRem(16),
    },

    [breakpoint('md')]: {
        width: calcRem(20),
        height: calcRem(20),
    },

    [breakpoint('lg')]: {
        height: calcRem(26),
        width: calcRem(26),
    },
})

export const StyledTheGameLogo = styled(TheGameLogo)({
    width: calcRem(44),
    height: calcRem(42),
    marginLeft: calcRem(3),
    color: 'inherit',
    fill: 'currentColor',
})

export const StyledGameOnParisWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
})
export const StyledGameOnParisLogo = styled(GameOnParisLogo)({
    width: calcRem(170),
    height: calcRem(85),
})

export const StyledStateAndFedElectionWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
})

export const StyledStateAndFedElectionImage = styled('img')({
    height: '24px' /* Set the height to 24px */,
    width: 'auto' /* Maintain aspect ratio by adjusting width */,
    maxWidth: '100%' /* Ensure it doesn't exceed the container width */,
})

export const StyledStateElectionLogo = styled(StateElectionLogo)({
    height: calcRem(30),
    marginLeft: calcRem(3),
    marginRight: calcRem(10),
    color: 'inherit',
    fill: 'currentColor',
})

export const StyledPick11Logo = styled(Pick11Logo)({
    width: calcRem(54),
    height: calcRem(52),
    marginLeft: calcRem(3),
    color: 'inherit',
    fill: 'currentColor',
})

export const StyledCricketPlayerImage = styled('img')({
    boxSizing: 'content-box',
    display: 'none',
    width: calcRem(260),
    height: calcRem(60),
    marginRight: calcRem(metrics.thewest.margins.sm),

    [breakpoint('md')]: {
        display: 'block',
    },
})

export const StyledFederalElection2022Logo = styled('h3')(({ theme }) => ({
    textTransform: 'uppercase',
    color: '#fff',
    fontFamily: theme.fonts.sansSerifCond,
    fontSize: calcRem(19),
    fontWeight: 'bold',
    width: calcRem(130),
    margin: 0,
    [breakpoint('lg')]: {
        width: 'unset',
        fontSize: calcRem(32),
    },
}))

export const StyledTheWest190SmallLogo = styled(TheWest190SmallLogo)({
    width: calcRem(161),
    height: calcRem(36),
    // marginLeft: calcRem(3),
    color: 'inherit',
    fill: 'currentColor',
})
