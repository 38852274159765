import React from 'react'
import { StyledTextLink } from '../../user-registration/breach/components/BreachWallContent/BreachWallContent.styled'
import {
    BottomTextWrap,
    InfoText,
    InfoTextLink,
} from '../../user-registration/breach/components/PuzzlesBreach/PuzzlesBreachwall.styled'
import {
    StyledOrderedList,
    StyledTAndC,
} from '../RegionalsSwgPromoPage/RegionalsSwgPromoPage.styled'
import { SwgCampaignOfferValues } from './CampaignOffer'

export const getTeacherOfferSwgCampaignValues = (): SwgCampaignOfferValues => {
    return {
        isDefault: false,
        creative: 'TW_Subscribe_With_Google_Campaign',
        deal: 'swg-promo',
        type: 'Premium - Inline',
        sku: 'everyday_digital_teacher',
        price: {
            weekly: 0,
            originalPrice: '$7 per week',
            promoPrice: '$0 for the first 12 months*',
        },
        BenefitListComponent: (
            <>
                <h3>How to Subscribe with Google?</h3>
                <StyledOrderedList>
                    <li>Enter your TRBWA (Teacher Registration Number)</li>
                    <li>
                        Click the Subscribe with Google button and follow the
                        prompts
                    </li>
                    <li>
                        Subscribing with Google is quick and easy! To set up
                        your account you can use your existing GPAY account OR
                        enter any valid email address with your preferred
                        payment method to subscribe.
                    </li>
                    <li>
                        This payment method will not be charged for the first 12
                        months, after 12 months you will be charged a discounted
                        rate of $5.90 per week.
                    </li>
                    <li>
                        For more information read our{' '}
                        <StyledTextLink
                            reverseColor={false}
                            href={`https://subscriber.thewest.com.au/subscribe-with-google-faq`}
                        >
                            FAQs
                        </StyledTextLink>
                    </li>
                    <li>
                        This payment method will be charged $1 every 4 weeks for
                        12 months. Total charge $13.
                    </li>
                </StyledOrderedList>
            </>
        ),
        TermsAndConditionsComponent: (
            <StyledTAndC>
                <b>
                    This offer is valid until 12/04/2023 and only valid to the
                    named recipient of this email.
                </b>{' '}
                Your new subscription will be charged at $0.00 for the first 52
                weeks. After 52 weeks, your subscription will be charged at a
                discounted rate of $5.90 per week. This subscription offer can
                be terminated by you at any time (subject to digital
                subscription terms and minimum total commitment of 4 weeks
                subscription) by providing at least 7 days’ notice in writing to
                West Australian Newspapers Subscriber Services at{' '}
                <StyledTextLink
                    reverseColor={false}
                    href={'mailto:subscriptions@wanews.com.au'}
                >
                    subscriptions@wanews.com.au
                </StyledTextLink>{' '}
                or by calling 1800 811 855. Offers are subject to our standard
                Subscription Terms and Conditions available at{' '}
                <StyledTextLink
                    reverseColor={false}
                    href={
                        'https://subscriber.thewest.com.au/subscription-terms'
                    }
                >
                    https://subscriber.thewest.com.au/subscription-terms
                </StyledTextLink>
                , and subscribers must meet the following technical requirements
                to enjoy the digital subscription: iOS requirement of 11.0 and
                Android minimum requirement of 5.0. Payment is by Direct Debit
                at 4 week or 12 week intervals in accordance with our Direct
                Debit Agreement, with the first payment payable on day 1 of your
                subscription term.
            </StyledTAndC>
        ),
        InfoComponent: (onAllPackagesClicked) => (
            <React.Fragment>
                <BottomTextWrap>
                    <InfoText>
                        Looking for a different subscription?{' '}
                        <InfoTextLink href={'#'} onEvent={onAllPackagesClicked}>
                            Browse all
                        </InfoTextLink>{' '}
                    </InfoText>
                </BottomTextWrap>
                <p>
                    *Subscribe today with Google. Cancel anytime 4 weeks
                    minimum.
                </p>
            </React.Fragment>
        ),
    }
}
