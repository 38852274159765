import { useSelector } from 'react-redux'
import { AppState } from '../store'
import { useMemo } from 'react'
import { getElectionToggles } from '@news-mono/common'
import {
    FeatureState,
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TogglesReduxState } from '.'

/**
 * Returns a reactive hook for the toggle value for a specific election
 */
export const useElectionToggleValue = (
    electionId: string,
    toggle: keyof ReturnType<typeof getElectionToggles>,
) => {
    const featureToggles = useSelector((state: AppState) => state.toggles)

    const result = useMemo(
        () => getElectionFeatureValue(featureToggles, electionId, toggle),
        [electionId, featureToggles, toggle],
    )

    return result
}

/**
 * Returns a reactive hook for the toggle state for a specific election
 */
export const useElectionToggle = (
    electionId: string,
    toggle: keyof ReturnType<typeof getElectionToggles>,
) => {
    const featureToggles = useSelector((state: AppState) => state.toggles)

    const result = useMemo(
        () => isElectionFeatureEnabled(featureToggles, electionId, toggle),
        [electionId, featureToggles, toggle],
    )

    return result
}

/**
 * Returns true if a given state is a FeatureState object
 */
const isFeatureState = (
    state: TogglesReduxState | FeatureState,
): state is FeatureState => {
    return Object.entries(state).every(
        ([key, value]) =>
            typeof key === 'string' &&
            typeof value === 'object' &&
            value !== null &&
            'value' in value,
    )
}

/**
 * Returns the toggle state for a specific election
 */
export const isElectionFeatureEnabled = (
    toggles: TogglesReduxState | FeatureState,
    electionId: string,
    toggle: keyof ReturnType<typeof getElectionToggles>,
) => {
    const featureState = isFeatureState(toggles)
        ? toggles
        : toFeatureState(toggles)

    const toggleName = getElectionToggles(electionId)[toggle]
    return isFeatureEnabled(featureState, toggleName, false)
}

/**
 * Returns the toggle value for a specific election
 */
export const getElectionFeatureValue = (
    toggles: TogglesReduxState | FeatureState,
    electionId: string,
    toggle: keyof ReturnType<typeof getElectionToggles>,
) => {
    const featureState = isFeatureState(toggles)
        ? toggles
        : toFeatureState(toggles)

    const toggleName = getElectionToggles(electionId)[toggle]
    return featureState[toggleName]?.value.raw
}
