export function getSocialImageMeta(src: string, height: string, width: string) {
    return [
        {
            property: 'og:image',
            content: src,
        },
        { name: 'twitter:image', content: src },
        {
            property: 'og:image:height',
            content: height,
        },
        {
            property: 'og:image:width',
            content: width,
        },
    ]
}
