import {
    AllEvents,
    Block,
    OrderedListBlock,
    UnorderedListBlock,
} from '@news-mono/web-common'
import React from 'react'
import { Logger } from 'typescript-log'
import { ArticleBlock } from '../../../../../publication/ArticleBlockContent/ArticleBlock'
import { StyledContainer, StyledMask } from './ArticlePreview.styled'

export interface ArticlePreviewProps {
    blocks: Block[]
    numberOfBlocksToPreview: number
    onEvent: (event: AllEvents) => void
    log: Logger
}

export const ArticlePreview: React.FC<ArticlePreviewProps> = ({
    blocks,
    numberOfBlocksToPreview,
    onEvent,
    log,
}) => {
    const sampleBlocks = blocks
        .filter(
            (block) =>
                block.kind === 'text' ||
                ((block.kind === 'unordered-list' ||
                    block.kind === 'ordered-list') &&
                    (
                        block as UnorderedListBlock | OrderedListBlock
                    ).items.every((listItem) =>
                        listItem.blocks.every(
                            (liBlock) => liBlock.kind === 'text',
                        ),
                    )),
        )
        .slice(0, numberOfBlocksToPreview)

    const content = sampleBlocks.map<JSX.Element>((block, index) => {
        return (
            <ArticleBlock
                key={index}
                block={block}
                onEvent={onEvent}
                log={log}
                adUnitPath=""
                getVideoQueue={undefined}
                showInlineVideoTitles={undefined}
                publicationPathname={undefined}
            />
        )
    })

    if (sampleBlocks.length === 0) return null

    return (
        <StyledContainer>
            {content}
            <StyledMask />
        </StyledContainer>
    )
}
