import { TheWestSection } from '@news-mono/common'
import { StaticRoute, StaticRoutes } from '@news-mono/web-common'
import { getSwgLandingPage } from '../regionals/get-swg-landing-page'

const bannerImage = {
    x1: require('./assets/teacher-comp-banner-x1.jpg'),
    x2: require('./assets/teacher-comp-banner-x2.jpg'),
}

const createTeachersOfferPage: StaticRoute<TheWestSection> = (services) => {
    if (!services.store.getState().toggles['teachers-offering']) return null
    return getSwgLandingPage({
        section: 'default',
        getAdTargeting: services.getAdTargeting,
        toggles: services.store.getState().toggles,
        options: {
            heading: 'FREE digital subscription for 12 months',
            bannerImage,
            modifier: 'teachers-offer',
            headerFontSize: 59,
            noIndex: true,
        },
    })
}

export const getTeachersOfferRouteInfo: StaticRoutes<TheWestSection> = {
    '/teachers-offer': createTeachersOfferPage,
}
