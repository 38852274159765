import styled from '@emotion/styled'
import { ComponentProps } from 'react'
import { textMixin } from '../../mixins/TextMixin/TextMixin'
import { breakpoint, calcRem, TheWestThemeConfig } from '../../__styling'
import { TNElectionNavigation } from '../TNElectionNavigation/TNElectionNavigation'
import { StyledLink } from '../TNElectionNavigation/TNElectionNavigation.styled'

export type TWElectionNavigationProps = ComponentProps<
    typeof TNElectionNavigation
>

export const TWElectionNavigation = styled(TNElectionNavigation)(
    ({ theme }) => ({
        width: 'auto',
        padding: calcRem(8),

        borderColor: theme.colors.borders.tertiary,

        [breakpoint('sm')]: {
            padding: calcRem(16),
        },

        [breakpoint('lg')]: {
            padding: calcRem(16, 0),
        },

        [`${StyledLink}`]: [
            textMixin<TheWestThemeConfig>(theme, 'label/M/regular'),
            {
                '&.active': textMixin<TheWestThemeConfig>(
                    theme,
                    'label/M/bold',
                ),
            },
        ],
    }),
)
