import React from 'react'
import {
    StyledStateAndFedElectionImage,
    StyledStateAndFedElectionWrapper,
} from '../BreakingNews.styled'
import { useFeature } from '@news-mono/web-common'

const logoImage = require('../../../logos/WAStateElection/WAStateElection2025LogoLarge.svg')

const shouldDisplayLogo = (
    isElectionPageRoute: boolean,
    isHomePageRoute: boolean,
    isWaElectionHomepageCollection: boolean,
) => {
    // hide the logo when on they are to appear under /politics/wa-election-2025
    if (isElectionPageRoute) {
        return false
    }

    // hide the logo when on homepage and 'wa-election-homepage-collection' is toggled on
    if (isHomePageRoute && isWaElectionHomepageCollection) {
        return false
    }

    //Show Logo
    return true
}

type WaElection2025ThemeProps = {
    isElectionPageRoute?: boolean
    isHomePageRoute?: boolean
}

export default function WaElection2025Theme({
    isElectionPageRoute = false,
    isHomePageRoute = false,
}: WaElection2025ThemeProps) {
    const isWaElectionHomepageCollection = useFeature(
        'wa-election-homepage-collection',
    )

    const isLogoVisible = shouldDisplayLogo(
        isElectionPageRoute,
        isHomePageRoute,
        isWaElectionHomepageCollection,
    )

    return (
        <StyledStateAndFedElectionWrapper>
            {isLogoVisible && (
                <StyledStateAndFedElectionImage
                    src={logoImage}
                    title="2025 WA Election"
                />
            )}
        </StyledStateAndFedElectionWrapper>
    )
}
