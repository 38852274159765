import React from 'react'

export interface IconSpinnerProps {
    className?: string
    color?: string
    width?: number
    height?: number
}

export const IconSpinner: React.FC<IconSpinnerProps> = ({
    className,
    color = '#fff',
    width,
    height,
}) => {
    return (
        <svg
            className={className}
            viewBox="-3 -3 45 45"
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            width={width}
        >
            <defs>
                <linearGradient
                    x1="8.042%"
                    y1="0%"
                    x2="65.682%"
                    y2="23.865%"
                    id="icon-spinner-linear-gradient"
                >
                    <stop stopColor={color} stopOpacity="0" offset="0%" />
                    <stop
                        stopColor={color}
                        stopOpacity=".631"
                        offset="63.146%"
                    />
                    <stop stopColor={color} offset="100%" />
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(1 1)">
                    <path
                        d="M36 18c0-9.94-8.06-18-18-18"
                        stroke="url(#icon-spinner-linear-gradient)"
                        strokeWidth="4"
                    >
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="0.6s"
                            repeatCount="indefinite"
                        />
                    </path>
                </g>
            </g>
        </svg>
    )
}
