import styled from '@emotion/styled'
import { calcRem, TheNightlyThemeConfig } from '../../__styling'
import { tokens } from '@news-mono/design-tokens'
import { textMixin } from '../../mixins/TextMixin/TextMixin'
import { ActiveState } from './Filter'

const { neutral } = tokens.thenightly.colors.palette

export const FilterContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(8),
})

export const StyledLabel = styled.label(({ theme }) =>
    textMixin<TheNightlyThemeConfig>(theme, 'item-small'),
)

export const LinkList = styled.nav({
    display: 'flex',
    margin: 0,
    padding: 0,
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: calcRem(8),
    width: '100%',
})

export const FilterItem = styled.button<{ state?: ActiveState }>(
    ({ theme, state }) => {
        return [
            {
                // Reset default button styles
                border: 'none',
                background: 'none',

                cursor: 'pointer',
                padding: calcRem(4, 12),
                borderRadius: calcRem(40),
                outline: `${calcRem(1)} solid ${neutral[30]}`,
                outlineOffset: calcRem(-1),

                ...(state === 'active' && {
                    backgroundColor: neutral[100],
                    outlineColor: neutral[100],
                    color: neutral[0],
                }),

                ['&:is(:focus,:active)']: {
                    outlineColor:
                        state === 'active' ? neutral[30] : neutral[100],
                },
            },
            textMixin<TheNightlyThemeConfig>(theme, 'body-alt'),
        ]
    },
)
