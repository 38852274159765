import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { textMixin } from '../../../mixins/TextMixin/TextMixin'
import { breakpoint, calcRem, TheWestThemeConfig } from '../../../__styling'
import { placeholderAnimation } from '../components/placeholderAnimation'
import { PartyColors } from '../data'

export const PartyTotalsWidgetContainer = styled.div(({ theme }) => [
    {
        display: 'flex',
        flexDirection: 'column',
        gap: calcRem(40),
    },
])

export const PartyTotalsDescriptionContainer = styled.div(({ theme }) => [
    {
        display: 'flex',
        flexDirection: 'column',
        gap: calcRem(24),
        marginTop: calcRem(24),

        p: textMixin<TheWestThemeConfig>(theme, 'body') && {
            margin: 0,
        },
    },
])

export const PartyTotalsContainer = styled.div(({ theme }) => [
    {
        display: 'flex',
        flexDirection: 'column',
        gap: calcRem(16),
    },
])
export const PartyTotalsHeadingContainer = styled.div(({ theme }) => [
    {
        display: 'flex',
        flexDirection: 'column',
        gap: calcRem(16),

        h2: textMixin<TheWestThemeConfig>(theme, 'head-3'),
        p: textMixin<TheWestThemeConfig>(theme, 'body'),

        'h2,p': {
            margin: 0,
        },
        [breakpoint('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            gap: 0,
        },
    },
])
export const PartyTotalsTable = styled.table(({ theme }) => [
    {
        width: '100%',
        borderCollapse: 'collapse',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: calcRem(8),

        'thead,tbody': {
            tr: {
                display: 'grid',
                gridTemplateColumns: '40% repeat(2, 1fr)',
                width: '100%',
            },
            '.swing,.seatsChanged': {
                display: 'none',
            },
            [breakpoint('sm')]: {
                '.swing,.seatsChanged': {
                    display: 'block',
                },
                tr: {
                    // 'PartyName' takes up 40%, 'SeatsWon' & 'SeatsChanged; need a bit more space so take up 1.5fr, 'Vote' & 'Swing' take up 1fr
                    gridTemplateColumns: '40% repeat(2, 1.5fr) repeat(2, 1fr)',
                },
            },
        },
        thead: {
            tr: textMixin<TheWestThemeConfig>(theme, 'medium/16') && {
                padding: calcRem(0, 16),
                fontWeight: 700,
                alignItems: 'end',
            },
            th: {
                textAlign: 'right',
                // This is not widely available, but it's not a big deal if it doesn't work on some older browsers
                // https://developer.mozilla.org/en-US/docs/Web/CSS/text-wrap#browser_compatibility
                textWrap: 'balance',
            },
        },
        tbody: {
            tr: textMixin<TheWestThemeConfig>(theme, 'medium/16'),
            display: 'flex',
            flexDirection: 'column',
            gap: calcRem(8),
        },

        [breakpoint('sm')]: {
            gap: calcRem(16),
        },
    },
    theme.kind === 'thenightly' && {
        'thead,tbody': {
            tr: {
                gridTemplateColumns: '30% repeat(2, 30%)',
                gap: '5%',
            },

            [breakpoint('sm')]: {
                tr: {
                    gridTemplateColumns: 'repeat(5, 16%)',
                },
            },
        },
    },
])
export const PartyTotalsTableData = styled.tr<{
    colors: PartyColors
    seatPercent: number
    predictedPercent: number
}>(({ theme, colors, seatPercent, predictedPercent }) => [
    {
        // General tr styles
        padding: calcRem(12, 16),
        background: tokens.thenightly.colors.palette.neutral[10],
        position: 'relative',

        // Styles for all the table data
        td: {
            alignSelf: 'center',
            textAlign: 'right',
        },
        '.partyName': {
            textAlign: 'left',
        },
        // This is the vote meter, it's a bar that fills up based on the seatPercent prop
        '::after': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: '100%',
            content: `''`,
            width: `${seatPercent}%`,
            background: colors.pale20,
            borderRight: `${calcRem(2)} solid rgba(0, 0, 0, 0.1)`, // The designs list this as 1px and black but it didn't look right, so took a stab

            transition: 'width 0.5s',
        },
        // This is the swing meter, it overlaps the seatPercent meter
        '::before': {
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: '100%',
            content: `''`,
            width: `${predictedPercent}%`,
            background: colors.pale10,
            borderRight: `${calcRem(2)} solid rgba(0, 0, 0, 0.1)`,

            transition: 'width 0.5s',
        },
        '.voteCount': textMixin<TheWestThemeConfig>(theme, 'medium/13'),
    },
])

export const TableDataLoadingPlaceholder = styled.tr(
    {
        height: 64,
    },
    placeholderAnimation,
)
export const TableHeadingLoadingPlaceholder = styled.p(
    {
        width: 280,
        height: 24,
        borderRadius: calcRem(4),
    },
    placeholderAnimation,
)

export const ElectionTppEstCardLoadingPlaceholder = styled('div')({
    width: '100%',
    height: calcRem(32),
    [breakpoint('sm')]: {
        height: calcRem(36),
    },
    ...placeholderAnimation,
})
