import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'web-common'
import { useAuthActions } from '../../../authentication'
import { Container } from '../Container/Container'
import { Title, Text, TextContainer } from '../Text/Text'
import { CreateAccountButton, LoginLink } from './LoginContent.styled'

export const LoginContent = () => {
    const { onRegisterClick, onLoginClick } = useAuthActions()
    const renditionType =
        useSelector((state: AppState) => state.render.renditionType) === 'app'
            ? 'app'
            : 'web'

    return (
        <Container>
            <TextContainer>
                <Title>Join the conversation</Title>
                <Text>Login or create a new account to make comments</Text>
            </TextContainer>
            <CreateAccountButton
                onClick={() =>
                    onRegisterClick('article-comments', renditionType)
                }
            >
                Create FREE account
            </CreateAccountButton>
            <Text>
                Already have a PerthNow or The West Australian account?{' '}
                <LoginLink onClick={() => onLoginClick('article-comments')}>
                    Log in
                </LoginLink>
            </Text>
        </Container>
    )
}
