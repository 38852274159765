import React from 'react'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { PoliticianPoll } from '../PollieRater/helpers/types'
import { PollieRaterForm, PollieRaterSignup } from './Components'
import {
    StyledErrorBlock,
    StyledErrorButton,
    StyledErrorText,
    StyledPoliRaterErrorContainer,
    StyledPolliDescription,
    StyledPollieImage,
    StyledPollieRaterContainer,
    StyledPollieRaterDivider,
    StyledPollieRaterListingContainer,
    StyledPollieRaterLoadingContainer,
    StyledPollieTextContainer,
    StyledRatingLabel,
    StyledRatingsContainer,
    StyledRatingScore,
    StyledTextBlock,
} from './PollieRaterListing.styled'
import { AllEvents } from '@news-mono/web-common'

export interface PollieRaterListingProps {
    isError: boolean
    isLoading: boolean // These are used to trigger the error view if required.
    politicians: Array<PoliticianPoll>
    contentfulEndpoint: string
    refetch: (
        options?: RefetchOptions | undefined,
    ) => Promise<QueryObserverResult<any, unknown>>
    refetchPollRating: (
        options?: RefetchOptions | undefined,
    ) => Promise<QueryObserverResult<any, unknown>>
    isLoggedIn: boolean
    onEvent: (event: AllEvents) => void
    electionId: string
}

const PLACEHOLDER_COUNT = 4

export const PollieRaterListing: React.FC<PollieRaterListingProps> = ({
    contentfulEndpoint,
    isError,
    isLoading,
    politicians,
    refetch,
    refetchPollRating,
    isLoggedIn,
    onEvent,
    electionId,
}) => {
    // Loading State!
    if (isLoading) {
        return (
            <StyledPollieRaterListingContainer>
                {[...Array(PLACEHOLDER_COUNT)].map((_, i) => (
                    <>
                        <StyledPollieRaterLoadingContainer key={i} />
                        {i < PLACEHOLDER_COUNT - 1 && (
                            <StyledPollieRaterDivider />
                        )}
                    </>
                ))}
            </StyledPollieRaterListingContainer>
        )
    }

    // Error State!
    if (isError) {
        return (
            <StyledPoliRaterErrorContainer>
                <StyledErrorBlock>
                    <StyledTextBlock>
                        <StyledErrorText>
                            Something went wrong!
                            <br />
                            Try reloading the Pollie Rater.
                        </StyledErrorText>
                    </StyledTextBlock>
                    <StyledErrorButton onClick={() => refetch()}>
                        Reload
                    </StyledErrorButton>
                </StyledErrorBlock>
            </StyledPoliRaterErrorContainer>
        )
    }

    if (politicians && politicians.length > 0) {
        return (
            <StyledPollieRaterListingContainer>
                {politicians.map((politician, i) => (
                    <>
                        <StyledPollieRaterContainer key={politician.id}>
                            {politician.profilePicture && (
                                <StyledPollieImage
                                    src={`${contentfulEndpoint}/pollie-rater/${politician.profilePicture}`}
                                    alt="Politician Profile Picture"
                                />
                            )}
                            <StyledPollieTextContainer>
                                <div>
                                    <h2>{politician.name}</h2>
                                    <h3>
                                        {politician.party} -{' '}
                                        <span>{politician.position}</span>
                                    </h3>
                                    <StyledPolliDescription>
                                        {politician.description}
                                    </StyledPolliDescription>
                                </div>
                                {isLoggedIn ? (
                                    <PollieRaterForm
                                        userScore={
                                            politician.userScore
                                                ? politician.userScore
                                                : 5
                                        }
                                        politician={politician}
                                        refetchPollRating={() =>
                                            refetchPollRating()
                                        }
                                        onEvent={onEvent}
                                        electionId={electionId}
                                    />
                                ) : (
                                    <PollieRaterSignup
                                        politicianName={politician.name}
                                    />
                                )}
                            </StyledPollieTextContainer>
                            <StyledRatingsContainer>
                                <div>
                                    <StyledRatingScore>
                                        <em>{politician.expertRating}</em>/10
                                    </StyledRatingScore>{' '}
                                    <StyledRatingLabel>
                                        Expert Rating
                                    </StyledRatingLabel>
                                </div>
                                <div>
                                    <StyledRatingScore>
                                        <em>
                                            {politician.userScore
                                                ? politician.averageScore
                                                : '?'}
                                        </em>
                                        /10
                                    </StyledRatingScore>{' '}
                                    <StyledRatingLabel>
                                        Average Voter Rating
                                    </StyledRatingLabel>
                                </div>
                            </StyledRatingsContainer>
                        </StyledPollieRaterContainer>
                        {i < politicians.length - 1 && (
                            <StyledPollieRaterDivider />
                        )}
                    </>
                ))}
            </StyledPollieRaterListingContainer>
        )
    }

    return null
}
