import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import React from 'react'
import { HideOnMobile, HideOnDesktop } from './Logo.styled'
import { useFeature, WebLink } from '@news-mono/web-common'

const horizontal = require('../../logos/WAStateElection/WAStateElection2025LogoLarge.svg')
const stacked = require('../../logos/WAStateElection/WAStateElection2025LogoStacked.svg')
export interface WAStateElection2025LogoProps {
    className?: string
    large?: boolean
    size: 'small' | 'large' | 'stacked' | 'switch'
    fireClickEvent?: () => void
}

export function WAStateElection2025Logo({
    size,
    fireClickEvent,
}: WAStateElection2025LogoProps) {
    const altText = '2025 WA State Election'

    let link = '/politics/elections/state/wa/2025'

    const waStateElectionPageEnabled = useFeature(
        'wa-election-landing-page-route',
    )

    if (waStateElectionPageEnabled) {
        link = '/politics/wa-election-2025'
    }

    if (size === 'switch') {
        return (
            <>
                <HideOnMobile>
                    <img
                        style={{ width: '221px' }}
                        alt={altText}
                        src={horizontal}
                    ></img>
                </HideOnMobile>
                <HideOnDesktop>
                    <img alt={altText} src={stacked}></img>
                </HideOnDesktop>
            </>
        )
    }
    let logo: ReactJSXElement
    switch (size) {
        case 'small':
            logo = (
                <img
                    style={{ width: '100%', maxWidth: '221px' }}
                    alt={altText}
                    src={horizontal}
                ></img>
            )
            break
        case 'large':
            logo = (
                <img
                    style={{ width: '100%', maxWidth: '443px' }}
                    alt={altText}
                    src={horizontal}
                ></img>
            )
            break
        case 'stacked':
            logo = <img alt={altText} src={stacked}></img>
            break
    }
    return (
        <WebLink
            aria-label="Go to the WA Election 2025 Homepage"
            to={link}
            onClick={fireClickEvent}
        >
            {logo}
        </WebLink>
    )
}
