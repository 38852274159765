import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    TWElectionNavigation,
    TWElectionNavigationProps,
} from './TWElectionNavigation'

export const TWElectionNavigationRegistration = createRegisterableComponent(
    'election-navigation',
    (props: TWElectionNavigationProps, { onEvent }) => {
        return <TWElectionNavigation onEvent={onEvent} {...props} />
    },
)
