import {
    AllEvents,
    ArticleLikePublication,
    isArticleLikeType,
} from '@news-mono/web-common'
import React from 'react'
import { LazyFederalElectionInArticleBanner } from '../../../banners/FederalElectionInArticleBanner/FederalElectionInArticleBanner.lazy'
import {
    InlineContentTypes,
    InlineFederalElectionBanner,
} from '../SharedPublication.routing'

export interface getInlinePositionedFederalElectionBannerProps {
    publication: ArticleLikePublication
    inlinePublicationContent: InlineContentTypes[]
    onEvent: (event: AllEvents) => void
}

export function isInlineCallToAction(
    content: InlineContentTypes,
): content is InlineFederalElectionBanner {
    return content.kind === 'inline-federal-election-banner'
}

export function getInlinePositionedFederalElectionCta({
    publication,
    inlinePublicationContent,
    onEvent,
}: getInlinePositionedFederalElectionBannerProps) {
    if (!isArticleLikeType(publication)) {
        return []
    }

    const validTopics = ['politics/federal-election', 'politics']

    if (
        !validTopics.includes(publication.primaryTopic.id) &&
        !publication.secondaryTopics.find((topic) =>
            validTopics.includes(topic.id),
        )
    ) {
        return []
    }

    return inlinePublicationContent
        .filter(isInlineCallToAction)
        .map((content: InlineFederalElectionBanner) => {
            return {
                position: content.insertAfter,
                element: (
                    <LazyFederalElectionInArticleBanner onEvent={onEvent} />
                ),
            }
        })
}
