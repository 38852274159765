import React from 'react'
import {
    StyledStateAndFedElectionImage,
    StyledStateAndFedElectionWrapper,
} from '../BreakingNews.styled'
import { useFeature } from '@news-mono/web-common'

const logo = require('../../../logos/FederalElection2025Logo/FederalElection2025LogoLarge.svg')

const shouldDisplayLogo = (
    isElectionPageRoute: boolean,
    isHomePageRoute: boolean,
    isFederalElectionHomepageCollection: boolean,
) => {
    // hide the logo when on they are to appear under /politics/federal-election-2025
    if (isElectionPageRoute) {
        return false
    }
    //hide the logo when on homepage and 'wa-election-homepage-collection' is toggled on
    if (isHomePageRoute && isFederalElectionHomepageCollection) {
        return false
    }
    return true
}

type FederalElection2025ThemeProps = {
    isElectionPageRoute?: boolean
    isHomePageRoute?: boolean
}

export default function FederalElection2025Theme({
    isElectionPageRoute = false,
    isHomePageRoute = false,
}: FederalElection2025ThemeProps) {
    const isFederalElectionHomepageCollection = useFeature(
        'federal-election-homepage-collection',
    )
    const isLogoVisible = shouldDisplayLogo(
        isElectionPageRoute,
        isHomePageRoute,
        isFederalElectionHomepageCollection,
    )

    return (
        <StyledStateAndFedElectionWrapper>
            {isLogoVisible && (
                <StyledStateAndFedElectionImage
                    src={logo}
                    title="2025 Federdal Election"
                />
            )}
        </StyledStateAndFedElectionWrapper>
    )
}
