import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, calcRem } from '../../../__styling'

export const TheSeatEmbedWidgetContainer = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',

    borderRadius: calcRem(12),
    background: tokens.thenightly.colors.palette.neutral[10],

    // Mobile Styling
    padding: calcRem(24, 16),
    gap: calcRem(16),

    // Large Styling
    [breakpoint('lg')]: {
        padding: calcRem(24),
        gap: calcRem(24),
    },
})

export const TheSeatEmbedWidgetTitleContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
})
