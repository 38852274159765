import React, { useContext } from 'react'
import { ElectionTitle, StyledElectionTitleContainer } from '../components'
import { getSeatsData, transformAreaData } from '../data'
import { TheSeatEmbedWidget } from '../TheSeatEmbedWidget/TheSeatEmbedWidget'
import {
    ConfigurationContext,
    ElectionDefinition,
    queries,
} from '@news-mono/web-common'
import { useQuery } from '@tanstack/react-query'

/**
 * @returns a collection component to display all electorate embeds in one list
 */
interface TheSeatEmbedCollectionWidgetProps {
    electionDefinition: ElectionDefinition
    imageBaseUrl?: string
}

export const TheSeatEmbedCollectionWidget = ({
    electionDefinition,
    imageBaseUrl,
}: TheSeatEmbedCollectionWidgetProps) => {
    const config = useContext(ConfigurationContext)
    const result = useQuery(
        queries['election-api'].definition({
            initialDefinition: electionDefinition,
            electionAPI: config.electionApi,
            caller: config.apiCallerHeader,
        }),
    )

    const seatData =
        result.isSuccess && result.data.electionData.data
            ? getSeatsData(
                  transformAreaData(result.data.electionData.data),
                  imageBaseUrl,
                  64,
              )
            : undefined

    return (
        <>
            <StyledElectionTitleContainer>
                <ElectionTitle>The Seat Embeds</ElectionTitle>
            </StyledElectionTitleContainer>
            {seatData !== undefined
                ? seatData
                      .slice(0, 6)
                      .map((seat) => (
                          <TheSeatEmbedWidget
                              key={seat.seatId}
                              providedData={seat}
                              imageBaseUrl={imageBaseUrl}
                              electionDefinition={electionDefinition}
                          />
                      ))
                : Array(6) // Render 6 skeletons while loading
                      .fill(0)
                      .map((_, index) => (
                          <TheSeatEmbedWidget
                              key={`placeholder-${index}`}
                              imageBaseUrl={imageBaseUrl}
                              electionDefinition={electionDefinition}
                          />
                      ))}
        </>
    )
}
