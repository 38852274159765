import styled from '@emotion/styled'
import React, { FormEvent, useCallback } from 'react'
import { IconCross, IconSearchTN } from '../../../icons'
import {
    getComponentTheme,
    calcRem,
    breakpoint,
    colors,
} from '../../../__styling'
import { DataLayerEventName, SearchEvent } from '@news-mono/web-common'
import { tokens } from '@news-mono/design-tokens'
import { ElectionFilterValue } from '../../../contexts'

type FormProps = {
    onEvent?: (event: SearchEvent) => void
    electionFilter: ElectionFilterValue<string>
}

export const ElectionSearchInput = ({ onEvent, electionFilter }: FormProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null)
    const { value: searchParam, setValue: setSearchParam } = electionFilter
    const [localSearchTerm, setLocalSearchTerm] = React.useState(searchParam)

    const sanitiseSearchTerm = (value: string) => {
        return value.replace(/[^a-zA-Z0-9\s\-'’]/g, '') // only allow alphanumeric, spaces, hyphens, and apostrophes
    }

    React.useEffect(() => {
        if (searchParam && searchParam !== '') {
            setLocalSearchTerm(sanitiseSearchTerm(searchParam))
        }
    }, [searchParam])

    const handleSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault()

            const formData = new FormData(e.target as HTMLFormElement)
            let inputValue =
                formData.get('search')?.toString().toLowerCase().trim() || ''

            inputValue = sanitiseSearchTerm(inputValue)

            setLocalSearchTerm(inputValue)
            setSearchParam(inputValue)

            if (!inputValue) {
                setSearchParam('')
                return
            }

            onEvent?.({
                type: DataLayerEventName.search,
                originator: 'ElectionSearch',
                payload: {
                    searchTerm: inputValue,
                },
            })
        },
        [onEvent, setSearchParam],
    )

    React.useEffect(() => {
        if (searchParam && inputRef.current) {
            inputRef.current.value = searchParam
        }
    }, [searchParam])

    const handleClear = () => {
        setLocalSearchTerm('')
        setSearchParam('')
        inputRef.current?.focus()
    }

    const handleInputChange = (i: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = i.target.value

        const sanitisedValue = sanitiseSearchTerm(rawValue)

        if (sanitisedValue !== rawValue) {
            i.preventDefault()
        }

        setLocalSearchTerm(sanitisedValue)

        if (sanitisedValue.trim() === '') {
            handleClear()
        }
    }

    return (
        <StyledElectionInput onSubmit={handleSubmit}>
            <StyledSearchButton type="submit">
                <IconSearchTN
                    fillColour={tokens.thenightly.colors.palette.neutral[70]}
                />
            </StyledSearchButton>
            <input
                placeholder="Search postcode, electorate or candidate"
                type="text"
                name="search"
                minLength={1}
                maxLength={100}
                ref={inputRef}
                value={localSearchTerm}
                onChange={handleInputChange}
            />
            <ElectionSearchClearButton
                type="reset"
                hasSearchValue={localSearchTerm.trim() !== ''}
                onClick={handleClear}
            >
                <ElectionSearchCrossIcon label="Clear Search" />
            </ElectionSearchClearButton>
        </StyledElectionInput>
    )
}

const StyledElectionInput = styled.form(({ theme }) => {
    const electionTheme = getComponentTheme(theme, 'electionTheme')

    return {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: calcRem(16),
        background: electionTheme.input.backgroundColor,
        color: electionTheme.input.textColor,
        borderRadius: calcRem(8),
        padding: calcRem(8, 16),
        outline: `1px solid ${electionTheme.input.outlineColor}`,

        input: {
            background: 'inherit',
            border: 'none',
            outline: 'none',
            flexGrow: 1,
            fontFamily: electionTheme.input.fontFamily,
            fontSize: electionTheme.input.fontSize,
            lineHeight: electionTheme.input.lineHeight,
            letterSpacing: electionTheme.input.letterSpacing,
            height: calcRem(40),
        },

        ['&:is(:hover, :focus-within)']: {
            outline: `1px solid ${electionTheme.input.outlineHoverColor}`,
        },
        transition: 'all 0.2s',
    }
})

const StyledSearchButton = styled.button(({ theme }) => {
    return {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        padding: 0,
        display: 'grid',
        placeItems: 'center',
    }
})

interface ElectionClearButtonProps {
    hasSearchValue: boolean
}

const ElectionSearchClearButton = styled('button')<ElectionClearButtonProps>(
    ({ hasSearchValue, theme }) => [
        {
            padding: calcRem(2, 6),
            background: 'transparent',
            cursor: 'pointer',
            border: 'none',
            lineHeight: 0,
            marginRight: calcRem(5),

            '&:hover path, &:focus path': {
                fill: theme.sectionValues.primaryColor,
            },

            [breakpoint('md')]: {
                marginRight: 0,
            },
        },
        !hasSearchValue && {
            display: 'none',
        },
    ],
)

const ElectionSearchCrossIcon = styled(IconCross)({
    height: calcRem(10),
    width: calcRem(10),
    fill: colors.thewest.greyGorilla,
})
