import loadable from '@loadable/component'
import React from 'react'
import { FeatureToggle } from '../../feature-toggling'
import { PollieRaterInArticleBannerRegistrationProps } from './PollieRaterInArticleBanner.routing'
import { PollieRaterInArticleBannerProps } from './PollieRaterInArticleBanner'

const LazyPollieRaterInArticleBannerComponent = loadable(
    () => import('./PollieRaterInArticleBanner'),
)

export const LazyPollieRaterInArticleBanner = (
    props: PollieRaterInArticleBannerProps,
) => {
    return (
        <FeatureToggle
            feature="pollie-rater-drivers"
            on={() => <LazyPollieRaterInArticleBannerComponent {...props} />}
        />
    )
}
