import React from 'react'

export interface FootySwgCheckMarkProps {
    className?: string
    width?: number
    height?: number
    strokeColor?: string
}

export const FootySwgCheckMark = ({
    className,
    width = 20,
    height = 20,
    strokeColor = '#043DE2',
}: FootySwgCheckMarkProps) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            style={{ minWidth: width, minHeight: height }}
            fill="none"
        >
            <path
                d="M10.0003 18.8332C14.6027 18.8332 18.3337 15.1022 18.3337 10.4998C18.3337 5.89746 14.6027 2.1665 10.0003 2.1665C5.39795 2.1665 1.66699 5.89746 1.66699 10.4998C1.66699 15.1022 5.39795 18.8332 10.0003 18.8332Z"
                stroke={strokeColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.8891 7.5835L8.54188 12.9307L6.11133 10.5002"
                stroke={strokeColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
