import {
    AllEvents,
    ConfigurationContext,
    DataLayerEventName,
} from '@news-mono/web-common'
import React, { useContext, useState } from 'react'
import { StyledPollieRaterActionButton } from '../../buttons/PollieRaterButton/PollieRaterButton.styled'
import { PoliticianPoll, useUpsertPollData } from '../../PollieRater/helpers'
import {
    Spinner,
    StyledPollieFormContainer,
    StyledPollieInputLinesContainer,
    StyledPollieSlider,
    StyledPollieSliderContainer,
    StyledPollieSliderLabel,
    StyledPollieSliderTooltip,
    StyledPollieToolTipGrid,
} from './PollieRaterForm.styled'

export function PollieRaterForm({
    userScore,
    politician,
    refetchPollRating,
    onEvent,
    electionId,
}: {
    userScore: number
    politician: PoliticianPoll
    refetchPollRating: () => void
    onEvent: (event: AllEvents) => void
    electionId: string
}) {
    const [pollieRating, setPollieRating] = useState(userScore)
    const { userPollServiceEndpoint } = useContext(ConfigurationContext)

    const {
        isLoading,
        isSuccess,
        mutate: updatePollRating,
    } = useUpsertPollData(
        userPollServiceEndpoint,
        politician.id,
        pollieRating,
        electionId,
    )

    function handleRatingChange(e: React.ChangeEvent<HTMLInputElement>) {
        setPollieRating(parseInt(e.target.value))
    }

    function saveUserRating() {
        try {
            updatePollRating()
            // Update user score to reflect the change after the stream has finished it's work (approx. time of 2s)
            setTimeout(() => {
                refetchPollRating()
            }, 2000)

            onEvent({
                type: DataLayerEventName.pollieRatingSubmit,
                originator: 'PollieRaterForm',
                timeStamp: Date.now(),
                payload: {
                    politician: politician.name,
                    rating: pollieRating,
                    party: politician.party,
                },
            })
        } catch (err) {
            console.error('Error saving user rating:', err)
        }
    }

    return (
        <StyledPollieFormContainer>
            <StyledPollieSliderContainer>
                <StyledPollieToolTipGrid>
                    <StyledPollieSliderLabel backgroundPosition={1}>
                        <span>1</span>
                    </StyledPollieSliderLabel>
                    <StyledPollieSliderLabel backgroundPosition={10}>
                        <span>10</span>
                    </StyledPollieSliderLabel>
                    <StyledPollieSliderTooltip
                        value={pollieRating}
                        isDisabled={isLoading}
                    >
                        <span>{pollieRating}</span>
                    </StyledPollieSliderTooltip>
                </StyledPollieToolTipGrid>
                <StyledPollieInputLinesContainer>
                    {Array(19)
                        .fill('')
                        .map((_, index) => (
                            <div key={index} />
                        ))}
                </StyledPollieInputLinesContainer>

                <StyledPollieSlider
                    disabled={isLoading}
                    type="range"
                    min="1"
                    max="10"
                    backgroundPosition={pollieRating}
                    value={pollieRating}
                    onChange={handleRatingChange}
                    aria-valuemin={1}
                    aria-valuemax={10}
                    aria-valuenow={pollieRating}
                    aria-label="Pollie Rater Slider Input"
                />
            </StyledPollieSliderContainer>
            <StyledPollieRaterActionButton
                disabled={isSuccess}
                onClick={() => !isLoading && saveUserRating()}
                aria-label="Pollie Rater Submit Button"
                type="submit"
            >
                {isLoading ? <Spinner /> : isSuccess ? 'Submitted' : 'Submit'}
            </StyledPollieRaterActionButton>
        </StyledPollieFormContainer>
    )
}
