import React from 'react'
import {
    StyledModal,
    StyledModalMain,
    StyledSubscribeButton,
} from './LatestEdition.styled'

export interface SubscriberModalProps {
    showModal: boolean
    setShowModal: (showModal: boolean) => void
    handleClickOutsideModal: (e: React.MouseEvent<HTMLDivElement>) => void
    innerModalRef: React.MutableRefObject<null>
}

export const SubscriberModal: React.FC<SubscriberModalProps> = ({
    showModal,
    setShowModal,
    handleClickOutsideModal,
    innerModalRef,
}) => {
    const defaultPrice = '$8 per week'

    return (
        <StyledModal showModal={showModal} onClick={handleClickOutsideModal}>
            <StyledModalMain className="modal-main" ref={innerModalRef}>
                <StyledSubscribeButton
                    onClick={() => {
                        setShowModal(false)
                        window.open('/editions/subscribe')
                    }}
                >
                    Subscribe from{' '}
                    <text style={{ textDecoration: 'underline' }}>
                        {defaultPrice}
                    </text>
                </StyledSubscribeButton>
            </StyledModalMain>
        </StyledModal>
    )
}
