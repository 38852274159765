import { SectionedSocialLinks } from './TheWestFooterNetworkLinks'

export const socialLinks: SectionedSocialLinks = {
    default: {
        facebookMessenger: 'https://m.me/thewestaustralian',
        facebook: 'https://www.facebook.com/thewestaustralian/',
        twitter: 'https://x.com/westaustralian',
        instagram: 'https://www.instagram.com/thewestaustralian',
        youtube: 'https://www.youtube.com/channel/UCby2ufdAO3PbD7x33hc8VvQ',
        rss: 'https://thewest.com.au/rss-feeds',
        email: 'mailto:cos@wanews.com.au',
    },
    'albany-advertiser': {
        facebook: 'https://www.facebook.com/albanyadvertiser/',
        facebookMessenger: 'https://m.me/albanyadvertiser',
        email: 'mailto:greatsouthern@wanews.com.au',
        street: '165 York Street',
        city: 'Albany',
        state: 'WA',
        postcode: 6330,
        phone: '(08) 9892 8300',
    },
    'great-southern-herald': {
        facebook: 'https://www.facebook.com/GreatSouthernHerald/',
        facebookMessenger: 'https://m.me/GreatSouthernHerald',
        email: 'mailto:greatsouthern@wanews.com.au',
        street: '49 Clive Street',
        city: 'Katanning',
        state: 'WA',
        postcode: 6317,
        phone: '(08) 6332 1120',
    },
    'augusta-margaret-river-times': {
        facebook: 'https://www.facebook.com/AMRTimes/',
        facebookMessenger: 'https://m.me/AMRTimes',
        email: 'mailto:editor@amrtimes.com.au',
        street: '7B, 119 Bussell Hwy',
        city: 'Margaret River',
        state: 'WA',
        postcode: 6285,
        phone: '(08) 6332 1130',
    },
    'busselton-dunsborough-times': {
        facebook: 'https://www.facebook.com/BusseltonDunsboroughTimes/',
        facebookMessenger: 'https://m.me/BusseltonDunsboroughTimes',
        email: 'mailto:editor@bdtimes.com.au',
        street: 'Unit 9, 8-10 Prince Street',
        city: 'Busselton',
        state: 'WA',
        postcode: 6280,
        phone: '(08) 9752 5000',
    },
    'broome-advertiser': {
        facebook: 'https://www.facebook.com/broomeadvertiser/',
        facebookMessenger: 'https://m.me/broomeadvertiser',
        email: 'mailto:news@broomead.com.au',
        street: '27-29 Dampier Terrace, Chinatown',
        city: 'Broome',
        state: 'WA',
        postcode: 6725,
        phone: '(08) 9191 9700',
    },
    'the-kimberley-echo': {
        facebook: 'https://www.facebook.com/KimberleyEcho/',
        facebookMessenger: 'https://m.me/KimberleyEcho',
        email: 'mailto:news@kimberleyecho.com',
        street: '2 Pruinosa Street',
        city: 'Kununurra',
        state: 'WA',
        postcode: 6743,
        phone: '(08) 6332 1170',
    },
    'geraldton-guardian': {
        facebook: 'https://www.facebook.com/geraldtonguardian/',
        facebookMessenger: 'https://m.me/geraldtonguardian',
        email: 'mailto:news@geraldtonnewspapers.com.au',
        street: '72 Chapman Road',
        city: 'Geraldton',
        state: 'WA',
        postcode: 6530,
        phone: '(08) 9956 1000',
    },
    'midwest-times': {
        facebook: 'https://www.facebook.com/MidwestTimesNorthernGuardian/',
        facebookMessenger: 'https://m.me/MidwestTimesNorthernGuardian',
        email: 'mailto:news@geraldtonnewspapers.com.au',
        street: '72 Chapman Road',
        city: 'Geraldton',
        state: 'WA',
        postcode: 6530,
        phone: '(08) 9956 1000',
    },
    'bunbury-herald': {
        facebook: 'https://www.facebook.com/BunburyHerald/',
        facebookMessenger: 'https://m.me/SouthWesternTimes',
        email: 'mailto:editor@swtimes.com.au',
        street: '19 Proffit Street',
        city: 'Bunbury',
        state: 'WA',
        postcode: 6230,
        phone: '(08) 9780 0800',
    },
    'harvey-waroona-reporter': {
        facebook: 'https://www.facebook.com/HarveyWaroonaReporter/',
        facebookMessenger: 'https://m.me/HarveyWaroonaReporter',
        email: 'mailto:editor@harveyreporter.com.au',
        street: '19 Proffit Street',
        city: 'Bunbury',
        state: 'WA',
        postcode: 6230,
        phone: '(08) 6332 1660',
    },
    'manjimup-bridgetown-times': {
        facebook: 'https://www.facebook.com/mbtimes/',
        facebookMessenger: 'https://m.me/mbtimes',
        email: 'mailto:editor@mbtimes.com.au',
        street: '108-110 Giblett St',
        city: 'Manjimup',
        state: 'WA',
        postcode: 6258,
        phone: '(08) 6332 1640',
    },
    'south-western-times': {
        facebook: 'https://www.facebook.com/SouthWesternTimes/',
        facebookMessenger: 'https://m.me/SouthWesternTimes',
        email: 'mailto:editor@swtimes.com.au',
        street: '19 Proffit Street',
        city: 'Bunbury',
        state: 'WA',
        postcode: 6230,
        phone: '(08) 9780 0800',
    },
    'kalgoorlie-miner': {
        facebook: 'https://www.facebook.com/kalgoorlieminer/',
        facebookMessenger: 'https://m.me/kalgoorlieminer',
        email: 'mailto:news@kalminer.com.au',
        street: '127 Hannan Street',
        city: 'Kalgoorlie',
        state: 'WA',
        postcode: 6743,
        phone: '(08) 9022 0555',
    },
    'narrogin-observer': {
        facebook: 'https://www.facebook.com/narroginobserver/',
        facebookMessenger: 'https://m.me/narroginobserver',
        email: 'mailto:greatsouthern@wanews.com.au',
        street: '49 Clive Street',
        city: 'Katanning',
        state: 'WA',
        postcode: 6317,
        phone: '(08) 6332 1141',
    },
    'north-west-telegraph': {
        facebook: 'https://www.facebook.com/NorthWestTelegraph/',
        facebookMessenger: 'https://m.me/NorthWestTelegraph',
        email: 'mailto:news@northwesttelegraph.com.au',
        street: 'Court Place',
        city: 'South Hedland',
        state: 'WA',
        postcode: 6722,
        phone: '(08) 6332 1180',
    },
    'pilbara-news': {
        facebook: 'https://www.facebook.com/PilbaraNews/',
        facebookMessenger: 'https://m.me/PilbaraNews',
        email: 'mailto:news@pilbaramedia.com.au',
        street: '31 Bond Place',
        city: 'Karratha',
        state: 'WA',
        postcode: 6714,
        phone: '(08) 6332 1400',
    },
    'sound-telegraph': {
        facebook: 'https://www.facebook.com/SoundTelegraph/',
        facebookMessenger: 'https://m.me/SoundTelegraph',
        email: 'mailto:editor@soundtelegraph.com.au',
        street: '2/96 Pinjarra Road',
        city: 'Mandurah',
        state: 'WA',
        postcode: 6210,
        phone: '(08) 9592 0200',
    },
    countryman: {
        facebook: 'https://www.facebook.com/CountrymanAU/',
        facebookMessenger: '',
        email: 'mailto:countryman@countryman.com.au',
        street: 'Newspaper House, 50 Hasler Road',
        city: 'Osborne Park',
        state: 'WA',
        postcode: 6017,
        phone: '(08) 9482 9708',
    },
}
