import {
    AreaCode,
    areaCodeSchema,
} from '@west-australian-newspapers/election-api-types'

/** The feature toggle prefixes. E.g. 'federal-', 'wa-'. */
type ElectionPrefix = `${Lowercase<AreaCode>}-`

const specificElectionFeatures = [
    `election-electorate-results-route`,
    `election-landing-page-the-seats`,
    `election-landing-page-the-race`,
    `election-landing-page-route`,
    `election-landing-page-the-swing`,
    `election-landing-page-map`,
    `election-homepage-the-seats`,
    `election-homepage-the-race`,
    `election-homepage-collection`,
    `election-homepage-collection-position`,
    `election-politics-collection`,
    `election-politics-collection-position`,
    `election-sitewide-navigation`,
    `election-homepage-head-to-head`,
    `election-homepage-the-swing`,
    `election-homepage-map`,
    `election-navigation`,
    `election-sitewide-navigation`,
    `election-section-head-to-head`,
    `election-party-totals-route`,
    `election-pollie-rater`,
    `election-homepage-pollie-rater-promo`,
] as const

/** Features that need to be controlled individually to each state/federal election. */
type SpecificElectionFeatures = typeof specificElectionFeatures[number]

export type ElectionFeatures =
    | 'pollie-rater-drivers'
    | 'homepage-election-component-id'
    | 'federal-election-2022-pollie-rater'
    | 'federal-election-2022-pollie-rater-strap-driver'
    | 'federal-election-2022-live-blog-drivers'
    | 'election-widget-test-page'
    | 'election-widget-preview-page'
    | 'custom-election-embeds'
    /** These values below determine the elections to show on non-election pages */
    | 'article-header-wan-election-id'
    | 'homepage-election-wan-election-id'
    | 'politics-curated-section-wan-election-id'
    | 'election-data-disclaimer'

    /** All combinations of prefixes and specific-election features. ~80 toggles */
    | `${ElectionPrefix}${SpecificElectionFeatures}`

/**
 * Returns the toggle names for a specific election.
 */
export const getElectionToggles = (
    electionId: string,
): Record<SpecificElectionFeatures, string> => {
    // Set toggles to base toggles.
    const electionToggles = Object.fromEntries(
        specificElectionFeatures.map((feature) => [
            feature,
            `${electionId}-${feature}`,
        ]),
    ) as Record<SpecificElectionFeatures, string>

    // override toggles if that specific election's toggles are disabled.
    if (electionId.startsWith('federal')) {
        for (const feature of specificElectionFeatures) {
            const toggleName = `federal-${feature}`
            electionToggles[feature] = toggleName
        }
    } else {
        const result = areaCodeSchema.safeParse(
            electionId.split('-')[0].toUpperCase(),
        )

        if (result.success) {
            const state = result.data.toLowerCase() as Lowercase<AreaCode>

            for (const feature of specificElectionFeatures) {
                const toggleName = `${state}-${feature}`
                electionToggles[feature] = toggleName
            }
        }
    }

    return electionToggles
}
