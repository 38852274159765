import { retrieveAccessToken } from '@news-mono/web-common'
import {
    checkForExistingItem,
    ContentModels,
    LinkType,
    PartyItem,
    PoliticianItem,
    PollieRaterData,
} from '@west-australian-newspapers/contentful-retrieval-utilities'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
    isPartyItem,
    isPoliticianItem,
    Party,
    Politician,
    PoliticianPoll,
    PollScore,
} from './types'

export const usePollieRaterData = (apiEndpoint: string) => {
    const pollieRaterData = useQuery<PollieRaterData>({
        queryKey: ['pollie-rater-data'],
        queryFn: async () => {
            const dataLocation = `${apiEndpoint}/pollie-rater/pollie-rater.json`
            const response = await fetch(dataLocation, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error('Unable to retrieve Pollie Rater data')
            }

            const res = await response.json()
            return res
        },

        // Reading the docs, it seems that staleTime === maxAge, cacheTime === time before cache is garbage collected
        staleTime: 120000, // 2 * 60 * 1000
        cacheTime: 120000, // 2 * 60 * 1000
        // Since we want the user to manually refresh, we shouldn't refresh on window focus.
        refetchOnWindowFocus: false,
    })

    return pollieRaterData
}

interface ConvertedMembers {
    [id: string]: number
}

export const orderFilteredPoliticians = (
    politicians: Politician[],
    members: LinkType[],
) => {
    // Remember that the politicians have been flitered by the party here.
    // The first thing we need to do is convert the members array into an object for quicker access.
    const convertedMembers = {} as ConvertedMembers

    for (let i = 0; i < members.length; i++) {
        Object.assign(convertedMembers, {
            [members[i].sys.id]: i,
        })
    }

    return politicians.sort(sortPoliticians(convertedMembers))
}

export const sortPoliticians = (convertedMembers: ConvertedMembers) => {
    return (a: Politician, b: Politician) => {
        // Remember we need to use a nullish coalesecent here as otherwise 0 counts as falsy.
        // If we return 1, that means the firstPolitician is sorted after the second one (b then a).
        // Opposite if we return -1 (a then b).
        const firstPolitician =
            convertedMembers[a.id] ?? Number.MAX_SAFE_INTEGER

        const secondPolitician =
            convertedMembers[b.id] ?? Number.MAX_SAFE_INTEGER

        // Push all undefined politicians (i.e: People in the party but were not added to the members array) to the back.
        return firstPolitician - secondPolitician
    }
}

export const useGetUserPollData = (apiEndpoint: string, electionId: string) => {
    const userPollData = useQuery<PollScore[]>({
        queryKey: ['user-poll-data', electionId],
        queryFn: async () => {
            const accessTokenState = retrieveAccessToken()
            if (!accessTokenState) {
                throw new Error('Not authorised')
            }
            const dataLocation = `${apiEndpoint}/poll/${electionId}`
            const response = await fetch(dataLocation, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessTokenState.accessToken}`,
                    Accept: 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error('Unable to retrieve Pollie Rater data')
            }

            const res = await response.json()
            return res
        },
    })

    return userPollData
}

export const useUpsertPollData = (
    apiEndpoint: string,
    id: string,
    score: number,
    electionId: string,
) => {
    const userPollData = useMutation({
        mutationKey: ['user-poll-data', electionId],
        mutationFn: async () => {
            const accessTokenState = retrieveAccessToken()
            if (!accessTokenState) {
                throw new Error('Not authorised')
            }
            const dataLocation = `${apiEndpoint}/poll/${electionId}/${id}`
            const response = await fetch(dataLocation, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessTokenState.accessToken}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify({
                    score: score,
                }),
            })

            if (!response.ok) {
                throw new Error('Unable to retrieve Pollie Rater data')
            }

            const res = await response.json()
            return res
        },
    })

    return userPollData
}

export const getPoliticians = (selectedParty: Party, data: PollieRaterData) => {
    const currentPoliticians: Array<PoliticianItem> = [
        ...data.items.filter(isPoliticianItem),
    ]

    try {
        // Now for each politician, retrieve their linked fields from the PollieRaterData
        const politicians: Politician[] = currentPoliticians
            .map((politician) => {
                // We need to typeguard politician item here.
                return {
                    id: politician.details.id,
                    name: politician.details.name,
                    party: politician.details.party
                        ? returnLinkedItem(
                              data,
                              politician.details.party.sys.id,
                              'party',
                          ) || ''
                        : undefined,
                    position: politician.details.position,
                    description: politician.details.description,
                    expertRating: politician.details.expertRating,
                    electorate: politician.details.electorate
                        ? returnLinkedItem(
                              data,
                              politician.details.electorate.sys.id,
                              'electorate',
                          ) || ''
                        : undefined,
                    incumbent: politician.details.incumbent,
                    profilePicture: politician.details.profilePicture
                        ? returnLinkedItem(
                              data,
                              politician.details.profilePicture.sys.id,
                              'profilePicture',
                          ) || ''
                        : undefined,
                }
            })
            .filter((politician) => politician.party === selectedParty.name)

        return orderFilteredPoliticians(politicians, selectedParty.members)
    } catch (e) {
        console.error({ e }, 'Error formatting politician data')
    }

    return
}

export const getParties = (data: PollieRaterData) => {
    // Get the currently active parties
    const currentParties: Array<PartyItem> = [...data.items.filter(isPartyItem)]

    const parties: Array<Party> = currentParties
        .map((party) => {
            return {
                name: party.details.name,
                id: party.details.id,
                members: party.details.members,
                order: party.details.order,
            }
        })
        .sort((a: Party, b: Party) => a.order - b.order)

    return parties
}

export const returnLinkedItem = (
    data: PollieRaterData,
    id: string,
    itemType: ContentModels,
) => {
    const item = checkForExistingItem(data, itemType, id)

    if (!item) {
        return
    }

    switch (item.contentModel) {
        case 'party': {
            return item.details.name
        }

        case 'electorate': {
            return item.details.name
        }

        case 'profilePicture': {
            return item.details.file.url
        }
    }
}

export const mapScoresToPoliticians = (
    politicians: Politician[],
    pollScores: PollScore[] | undefined,
): PoliticianPoll[] => {
    const mappedPoliticians: PoliticianPoll[] = politicians.map(
        (politician) => {
            const matchedScore = pollScores?.find(
                (pollScore) => pollScore.id === politician.id,
            )
            return {
                ...politician,
                userScore:
                    matchedScore && matchedScore.userScore
                        ? matchedScore.userScore
                        : null,
                averageScore:
                    matchedScore && matchedScore.averageScore
                        ? matchedScore.averageScore
                        : 5,
            }
        },
    )
    return mappedPoliticians
}
