import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { placeholderAnimation } from '../widgets/election/components/placeholderAnimation'
import { calcRem, visuallyhidden } from '../__styling'
import { IconCheckmark } from '../icons/IconCheckmark/IconCheckmark'

export const StyledFilterOptionContainer = styled.fieldset({
    display: 'flex',
    gap: calcRem(8),
    flexWrap: 'wrap',

    border: 'none',
    margin: 0,
    padding: 0,
})

export const StyledFilterOptionLabel = styled('label')(({ theme }) => ({
    display: 'flex',
    gap: calcRem(8),
    alignItems: 'center',
    borderRadius: calcRem(8),
    fontFamily: theme.fonts.sansSerif,
    background: 'transparent',
    color: theme.colors.text.primary,
    border: `1px solid ${theme.colors.text.primary}`,
    borderColor: theme.colors.text.primary,
    padding: calcRem(8),
    cursor: 'pointer',
    outline: 'none',
}))

export const StyledFilterOptionCheck = styled(IconCheckmark)(({ theme }) => ({
    // Hide by default
    display: 'none',
}))

export const StyledFilterOptionInput = styled('input')(({ theme }) => ({
    ...visuallyhidden,
    // When hover/focused, apply styles to the related label.
    [`&:hover + ${StyledFilterOptionLabel}`]: {
        background: tokens.thewest.colors.palette.greyDolphin,
        color: theme.colors.text.primary,
        borderColor: theme.colors.text.primary,
    },
    // When checked, apply styles to following label.
    [`&:checked + ${StyledFilterOptionLabel}`]: {
        background: `linear-gradient(90.01deg, #1E04E2 0.01%, #B03D28 99.72%)`,
        color: theme.colors.palette.white,
        borderColor: theme.colors.palette.white,
        lineHeight: 1.1,

        [`& > ${StyledFilterOptionCheck}`]: {
            // Show check
            display: 'unset',
        },
    },
    [`&:focus + ${StyledFilterOptionLabel}`]: {
        borderColor: theme.colors.text.primary,
    },
}))

export const StyledPollieRaterHeader = styled.form(({ theme }) => ({
    marginTop: theme.margins.xxl,
    marginBottom: calcRem(24),
}))

export const StyledTextContainer = styled('div')(({ theme }) => ({
    marginBottom: theme.margins.xl,
    fontSize: calcRem(17),
    lineHeight: calcRem(24),
    fontFamily: theme.fonts.serif,

    '&::after': {
        content: "''",
        position: 'absolute',
        display: 'block',
        borderBottom: `3px solid ${theme.colors.brand}`,
        width: calcRem(37),
        bottom: calcRem(-8),
    },
}))

export const StyledPollieRaterLegend = styled('div')(({ theme }) => ({
    fontSize: calcRem(22),
    lineHeight: calcRem(24),
    fontFamily: theme.fonts.serif,
    fontWeight: 700,

    paddingBottom: calcRem(8),
}))

export const StyledPollieRaterFilterOptionLoading = styled.div(
    {
        height: 36,
        width: 81,
        borderRadius: calcRem(8),
    },
    placeholderAnimation,
)
