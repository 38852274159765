import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import {
    ContentDataDefinitionLoader,
    createCollectionAvailableEvent,
    createRegisterableComponentWithData,
} from '@news-mono/web-common'
import React from 'react'
import { CardRouteProps } from '../../cards/card-props.routing'
import { CardOrientationOptions, Portrait } from '../../cards/Portrait/Portrait'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import {
    getVideoQueue,
    VideoQueue,
} from '../../templates/Publication/lib/get-video-queue'
import { shouldExcludeVideoSeries } from '../../templates/Publication/lib/should-include-video-series'
import { ThemeOverrider } from '../../themes/ThemeOverrider/ThemeOverrider'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { useProduct } from '../../__product'
import { ThemeOverrideTypes } from '../../__styling'
import { ThemeMargins } from '../../__styling/settings/metrics'
import { MediaMode } from '../CardMedia/CardMedia'
import { TeaserMode } from '../CardText/CardTeaser'
import { KickerMode } from '../Kicker/Kicker'

export const PortraitRegistration = createRegisterableComponentWithData(
    'portrait',
    ContentDataDefinitionLoader,
    (
        props: CardRouteProps &
            ResponsiveContainer & {
                isSponsoredCollection?: boolean
                verticalSpacing?: keyof ThemeMargins
                cardOrientation?: CardOrientationOptions
                willPlayVideoInline?: boolean
                hasPadding?: boolean
                mediaMode?: MediaMode
                teaserMode: TeaserMode
                fullWidthOnMobile?: boolean
                kickerMode?: KickerMode
                themeOverride?: ThemeOverrideTypes
                hideEditorialTypes?: boolean
                allowLiveBlogMilestoneTeaser?: boolean
            },
        data,
        services,
    ) => {
        const product = useProduct()
        if (data.loaded && data.result.publications.length === 0) {
            // Can happen if we are driven by a curation and publication(s) have been killed
            services.log.warn(
                'Portrait card received empty list of publications',
            )
            return null
        }

        const results = data.loaded && data.result.publications[0]
        if (results && results.cardType !== 'publication') {
            return null
        }

        const willPlayVideoInline =
            isFeatureEnabled(
                toFeatureState(services.store.getState().toggles),
                'inline-card-video',
            ) && props.willPlayVideoInline

        return (
            <ThemeOverrider override={props.themeOverride}>
                <ImpressionAvailable
                    loading={!data.loaded}
                    available={() => {
                        if (!data.loaded) {
                            console.warn(
                                'Available should never be called when loading is true',
                            )
                            return
                        }

                        services.onEvent(
                            createCollectionAvailableEvent(
                                data.result.publications,
                                'Portrait',
                                product,
                                services.onEvent,
                            ),
                        )
                    }}
                >
                    {(ref) => (
                        <Portrait
                            innerRef={ref}
                            item={
                                results
                                    ? { loaded: true, result: results }
                                    : { loaded: false }
                            }
                            fixedRatio={props.fixedRatio}
                            fontScale={props.fontScale}
                            onEvent={services.onEvent}
                            hasBackground={props.hasBackground}
                            hasPadding={props.hasPadding}
                            teaserMode={props.teaserMode}
                            hideByline={props.hideByline}
                            kickerMode={props.kickerMode}
                            isLarge={props.isLarge}
                            isSponsoredCollection={props.isSponsoredCollection}
                            verticalSpacing={props.verticalSpacing}
                            cardOrientation={props.cardOrientation}
                            cardNumber={1}
                            canPlayVideoInline={willPlayVideoInline}
                            getVideoQueue={() =>
                                results
                                    ? getVideoQueue(
                                          services,
                                          results.id,
                                          shouldExcludeVideoSeries(
                                              results,
                                              services.store.getState().toggles,
                                          ),
                                      )
                                    : Promise.resolve<VideoQueue>({
                                          type: 'not-playlist-video',
                                          items: [],
                                      })
                            }
                            adUnitPath={services.adState.targeting.adUnitPath}
                            timestamp={props.timestamp}
                            mediaMode={props.mediaMode}
                            fullWidthOnMobile={props.fullWidthOnMobile}
                            disableImageLazyLoad={props.disableImageLazyLoad}
                            containerWidthRatios={props.containerWidthRatios}
                            hideEditorialType={props.hideEditorialTypes}
                            allowLiveBlogMilestoneTeaser={
                                props.allowLiveBlogMilestoneTeaser
                            }
                        />
                    )}
                </ImpressionAvailable>
            </ThemeOverrider>
        )
    },
)
