import {
    AllEvents,
    ConfigurationContext,
    DataLayerEventName,
    ElectionDefinition,
    queries,
} from '@news-mono/web-common'
import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import React, { PropsWithChildren, useCallback, useContext } from 'react'
import { useImpressionAvailable } from '../../../__helpers/impression-available-helper'
import { CustomPageSharing } from '../../../buttons/SharingCustomPage/CustomPageSharing'
import { ElectionDataFetchError, ElectionFilter } from '../components'
import {
    ExtendedInterknowlogyData,
    ExtendedInterknowlogyParty,
    filterPartiesByState,
    getPartyColors,
    transformAreaData,
} from '../data'
import { ElectionTppEstCard } from '../ElectionTppEstCard/ElectionTppEstCard'
import { TheSeatsDivider } from '../TheSeatsWidget/TheSeatsWidget.styled'
import {
    ElectionTppEstCardLoadingPlaceholder,
    PartyTotalsContainer,
    PartyTotalsDescriptionContainer,
    PartyTotalsHeadingContainer,
    PartyTotalsTable,
    PartyTotalsTableData,
    PartyTotalsWidgetContainer,
    TableDataLoadingPlaceholder,
    TableHeadingLoadingPlaceholder,
} from './PartyTotals.styled'
import { ElectionPredictionDisclaimer } from '../components/ElectionPredictionDisclaimer'
import {
    ElectionFilterContext,
    ElectionStateFilterOptions,
} from '../../../contexts'
import { Theme, useTheme } from '@emotion/react'
import { getAllFilteredElectionParties } from '../data/election-data-filtering'
import { electionsDebug } from '../../../__helpers/elections-debug'
import { LoggerContext } from '../../../diagnostics/LoggerContext'

type PartyTotalsProps = {
    onEvent: (event: AllEvents) => void
    electionDefinition: ElectionDefinition
    description: string
}

export const PartyTotalsComponent = ({
    onEvent,
    electionDefinition,
    description,
}: PartyTotalsProps) => {
    const theme = useTheme()
    const { state: stateFilter } = useContext(ElectionFilterContext)

    const config = useContext(ConfigurationContext)
    const logger = useContext(LoggerContext)
    const result = useQuery(
        queries['election-api'].definition({
            initialDefinition: electionDefinition,
            electionAPI: config.electionApi,
            caller: config.apiCallerHeader,
        }),
    )

    const transformedData =
        result.isSuccess && result.data.electionData.data
            ? transformAreaData(result.data?.electionData.data)
            : undefined
    const partyData =
        transformedData && transformedData.areas.length > 0
            ? getPartyData(transformedData, stateFilter.value)
            : undefined

    // Loading state
    if (result.isLoading) {
        return (
            <PartyTotalsWrapper
                headingComponent={<TableHeadingLoadingPlaceholder />}
                beforeHeadingComponent={
                    <ElectionTppEstCardLoadingPlaceholder />
                }
            >
                <PartyTotalsTable>
                    {getTableHead(theme)}
                    <tbody>
                        {[...Array(3)].map((_, i) => (
                            <TableDataLoadingPlaceholder />
                        ))}
                    </tbody>
                </PartyTotalsTable>
            </PartyTotalsWrapper>
        )
    }

    // Something went wrong, show an error page
    if (
        result.isError ||
        !partyData ||
        partyData.length == 0 ||
        !result.data.electionData.data ||
        !result.data.electionData.config
    ) {
        logger.warn(
            {
                resultError: result.isError,
                partyDataLength: partyData?.length,
                electionDataPresent: !result?.data?.electionData.data,
                electionConfigPresent: !result?.data?.electionData.config,
                electionId: electionDefinition.electionId,
            },
            'PartyTotals election data error',
        )
        return (
            <PartyTotalsWrapper>
                <ElectionDataFetchError />
            </PartyTotalsWrapper>
        )
    }

    // Show the loaded state
    const { areas, lastModified } = result.data.electionData.data
    const { electionType, seatsTotal } = result.data.electionData.config
    electionsDebug('partyData', partyData)

    return (
        <PartyTotalsWidgetContainer>
            <PartyTotalsDescriptionContainer>
                <p>{description}</p>
                <CustomPageSharing
                    text={description}
                    shareOptions={[
                        'facebook',
                        'linkedin',
                        'twitter',
                        'email',
                        'clipboard',
                    ]}
                    loading={false}
                    onEvent={onEvent}
                />
            </PartyTotalsDescriptionContainer>

            {/* If we're showing the filter buttons, display them :D */}
            {electionType === 'federal' && (
                <ElectionFilter
                    headingText="State"
                    dataLayerEventOriginator="ElectionStateFilter"
                    onEvent={onEvent}
                    dataLayerEventName={DataLayerEventName.searchFilter}
                    electionFilter={stateFilter}
                    hideOnDesktop={theme.kind === 'thenightly'}
                />
            )}
            <TheSeatsDivider hideOnDesktop />
            <ElectionTppEstCard
                title="Two-party preferred estimate"
                data={partyData}
                config={result.data.electionData.config}
            />
            <PartyTotalsWrapper
                headingComponent={
                    <p>
                        {areas[0].pctCounted.toFixed(0)}% votes counted -
                        updated{' '}
                        {format(
                            lastModified !== null
                                ? new Date(lastModified)
                                : Date.now(),
                            'h:mm a',
                        )}
                    </p>
                }
                onEvent={onEvent}
            >
                <PartyTotalsTable>
                    {getTableHead(theme)}
                    <tbody>
                        {partyData.map((party, i) => {
                            // To fill the graph, a party would have to have the maximum number of seats for the election
                            // i.e. so for the bar to be fill a party would need 100% of the seats (this is how ABC did it 🙈)
                            const seatsAsPercent =
                                (party.seatsWon / seatsTotal) * 100
                            const seatsPredicted = party.seatsPredicted ?? 0
                            const seatsPredictedAsPercent =
                                ((party.seatsWon + seatsPredicted) /
                                    seatsTotal) *
                                100

                            return (
                                <PartyTotalsTableData
                                    key={i}
                                    colors={getPartyColors(party.partyCode)}
                                    seatPercent={Math.min(seatsAsPercent, 100)}
                                    predictedPercent={Math.min(
                                        seatsPredictedAsPercent,
                                        100,
                                    )}
                                >
                                    {getTableData(theme, party)}
                                </PartyTotalsTableData>
                            )
                        })}
                    </tbody>
                </PartyTotalsTable>
            </PartyTotalsWrapper>
            <ElectionPredictionDisclaimer />
        </PartyTotalsWidgetContainer>
    )
}

type PartyTotalsWrapperProps = {
    headingComponent?: React.ReactNode
    beforeHeadingComponent?: React.ReactNode
    onEvent?: (event: AllEvents) => void
}
const PartyTotalsWrapper = ({
    children,
    headingComponent,
    beforeHeadingComponent,
    onEvent,
}: PropsWithChildren<PartyTotalsWrapperProps>) => {
    const impressionAvailableRef = useImpressionAvailable({
        loading: false,
        available: useCallback(() => {
            onEvent?.({
                type: DataLayerEventName.navAvailable,
                originator: 'ElectionPartyTotalsComponent',
                payload: {
                    navName: 'ElectionPartyTotals',
                },
            })
        }, [onEvent]),
    })

    return (
        <PartyTotalsContainer ref={impressionAvailableRef}>
            {beforeHeadingComponent}
            <PartyTotalsHeadingContainer>
                <h2>Seats won & seats likely</h2>
                {headingComponent}
            </PartyTotalsHeadingContainer>
            {children}
        </PartyTotalsContainer>
    )
}

/** This functions removes all parties that are a part of the coalition but retains the overarching Coalition party added in `transformAreaData` (`getInterknowlogyData.ts`)
 * It also sorts the parties by vote percentage (highest first) and has ability to filter by state
 */
const getPartyData = (
    data: ExtendedInterknowlogyData,
    filter: ElectionStateFilterOptions[number],
) => {
    const combinePartyData = getAllFilteredElectionParties(data, filter)
    const coalition = combinePartyData.find(
        (party) => party.partyName === 'Coalition',
    )
    const nonCoalitionParties = combinePartyData.filter(
        (party) => party !== coalition,
    )

    // If the coalition party is present, add it to the front of the list
    const parties = coalition
        ? [coalition, ...nonCoalitionParties]
        : nonCoalitionParties

    // Sort the parties by vote percentage
    const sortedParties = parties.sort((a, b) => b.votePct - a.votePct)
    // If the filter is 'all', return all parties, otherwise filter by the state
    return filterPartiesByState(sortedParties, filter)
}

const getTableHead = (theme: Theme) => {
    switch (theme.kind) {
        case 'thenightly':
            return (
                <thead>
                    <tr>
                        <th className="partyName"></th>
                        <th className="vote">Vote</th>
                        <th className="swing">Swing</th>
                        <th className="seatsWon">Seats won (+&nbsp;likely)</th>
                        <th className="seatsChanged">Seats changed</th>
                    </tr>
                </thead>
            )
        default:
            return (
                <thead>
                    <tr>
                        <th className="partyName"></th>
                        <th className="seatsWon">Seats won (+&nbsp;likely)</th>
                        <th className="seatsChanged">Seats changed</th>
                        <th className="vote">Vote</th>
                        <th className="swing">Swing</th>
                    </tr>
                </thead>
            )
    }
}

const getTableData = (theme: Theme, party: ExtendedInterknowlogyParty) => {
    const seatsPredicted = party.seatsPredicted ?? 0

    const { seatsGained, seatsLost, seatsChanged } = party
    let seatsChangedCount = seatsChanged

    // calculate seats changed via the gained and lost seats
    // these numbers should exist permanently now so can remove zod optional type
    if (seatsGained !== undefined && seatsLost !== undefined) {
        seatsChangedCount = seatsGained - seatsLost
    }

    switch (theme.kind) {
        case 'thenightly':
            return (
                <>
                    <td className="partyName">{party.partyName}</td>
                    <td className="vote">
                        <div>{party.votePct.toFixed(1)}%</div>
                        <div className="voteCount">
                            {/* Intl.NumberFormat adds the commas as per the design */}
                            {Intl.NumberFormat('en-AU').format(party.voteCount)}
                        </div>
                    </td>
                    <td className="swing">
                        {/* If the Swing Pct is null, fallback to a dash, was decided previously iirc */}
                        {party.swingPct ? `${party.swingPct.toFixed(1)}%` : '-'}
                    </td>
                    <td className="seatsWon">
                        {party.seatsWon} (+{seatsPredicted})
                    </td>
                    <td className="seatsChanged">{seatsChangedCount}</td>
                </>
            )
        default:
            return (
                <>
                    <td className="partyName">{party.partyName}</td>
                    <td className="seatsWon">
                        {party.seatsWon} (+{seatsPredicted})
                    </td>
                    <td className="seatsChanged">{seatsChangedCount}</td>
                    <td className="vote">
                        <div>{party.votePct.toFixed(1)}%</div>
                        <div className="voteCount">
                            {/* Intl.NumberFormat adds the commas as per the design */}
                            {Intl.NumberFormat('en-AU').format(party.voteCount)}
                        </div>
                    </td>
                    <td className="swing">
                        {/* If the Swing Pct is null, fallback to a dash, was decided previously iirc */}
                        {party.swingPct ? `${party.swingPct.toFixed(1)}%` : '-'}
                    </td>
                </>
            )
    }
}
