import React from 'react'
import {
    StyledLabel,
    FilterContainer,
    FilterItem,
    LinkList,
} from './Filter.styled'
import { useHistory } from 'react-router'
import { useQueryParams } from 'web-common/src/hooks/useQueryParams'
import { URLSearchParams } from 'url'
import { TopicCount } from '@west-australian-newspapers/publication-types'
import { FilterEventProps, getEventName } from '../filter-events-util'
import { FilterQueryParam } from '../FilterQueryParam'

export type ActiveState = 'active' | 'default'

interface FilterProps extends FilterEventProps {
    /** The text to show above the filter, describes the purpose of the filter */
    headingText: string
    /** Shows a count next to the filterItem (i.e. Politics (99) ) */
    topicCounts?: TopicCount[]
    /** The query parameter name to show in the URL */
    queryFilterParam: string
    /** The Originator field used in the onEvent function */
    dataLayerEventOriginator: string
    /** The items to render in this component. `displayName` will appear on the page, `paramName` appears in the query params */
    filterItems: FilterQueryParam[]
    /** Whether to allow multiple selection, defaults to true */
    allowMultipleSelection?: boolean
    /** The name of the param which is the default state, defaults to "All" */
    defaultParamName?: string
}

const getFilterString = (
    queryParams: URLSearchParams,
    queryFilterParam: string,
    currItem: string,
): string | undefined => {
    const selectedQueryParams = queryParams.get(queryFilterParam)
    const queryParamArray = selectedQueryParams
        ? selectedQueryParams.split(',')
        : []

    // Check if the current item is in the query params
    const currItemIndex = queryParamArray.indexOf(currItem.toLowerCase())

    // Add or remove the item
    currItemIndex !== -1
        ? queryParamArray.splice(currItemIndex, 1)
        : queryParamArray.push(currItem.toLowerCase())

    return queryParamArray.length > 0 ? queryParamArray.join() : undefined
}

type DisplayStringProps = {
    topic: string
    filterItems: TopicCount[]
}
/** Returns the text to show in the pill item, only used in TopicFilter */
const getDisplayString = ({
    topic,
    filterItems,
}: DisplayStringProps): string => {
    const count = filterItems
        ? filterItems.find(
              (topicCount) => topicCount.topic === topic.toLowerCase(),
          )?.count
        : undefined
    const countString = count !== undefined ? ` (${count})` : ''
    return `${topic}${countString}`
}

export const Filter = ({
    headingText,
    topicCounts,
    queryFilterParam,
    dataLayerEventOriginator,
    onEvent,
    dataLayerEventName,
    filterItems,
    allowMultipleSelection = true,
    defaultParamName = 'all',
}: FilterProps) => {
    const history = useHistory()
    const { queryParams, pathname } = useQueryParams()

    /** Updates query params, history and fires required Data Layer event */
    const onLinkClicked = (listItem: string, defaultParamName: string) => {
        // remove the page parameter
        queryParams.delete('page')

        if (listItem === defaultParamName) {
            // We dont want to show any query params with the default `All` option selected
            queryParams.delete(queryFilterParam)
        } else {
            let queryStr: string | undefined
            if (allowMultipleSelection) {
                queryStr = getFilterString(
                    queryParams,
                    queryFilterParam,
                    listItem,
                )
            } else {
                queryStr = listItem
            }
            queryStr
                ? queryParams.set(queryFilterParam, queryStr)
                : queryParams.delete(queryFilterParam)
        }

        history.push({
            pathname: pathname,
            search: queryParams.toString(),
        })

        const eventName = getEventName(queryFilterParam)

        if (eventName) {
            onEvent({
                type: dataLayerEventName,
                originator: dataLayerEventOriginator,
                payload: {
                    name: eventName,
                    value: queryParams.get(queryFilterParam) ?? listItem,
                },
            })
        }
    }

    const getSelectedState = (
        item: string,
        defaultParamName: string,
    ): ActiveState => {
        if (item === defaultParamName) {
            return queryParams.get(queryFilterParam) ? 'default' : 'active'
        } else {
            const itemFilter = queryParams.get(queryFilterParam)
            const itemArray = itemFilter ? itemFilter.split(',') : []
            return itemArray.indexOf(item) !== -1 ? 'active' : 'default'
        }
    }

    return (
        <FilterContainer>
            <StyledLabel>{headingText}</StyledLabel>
            <LinkList>
                {filterItems.map((item, i) => (
                    <FilterItem
                        key={i}
                        state={getSelectedState(
                            item.paramName,
                            defaultParamName,
                        )}
                        onClick={() =>
                            onLinkClicked(item.paramName, defaultParamName)
                        }
                    >
                        {topicCounts
                            ? getDisplayString({
                                  topic: item.displayName,
                                  filterItems: topicCounts,
                              })
                            : item.displayName}
                    </FilterItem>
                ))}
            </LinkList>
        </FilterContainer>
    )
}
