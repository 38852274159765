import React, { PropsWithChildren } from 'react'
import { ButtonLink } from '../buttons/ButtonLink/ButtonLink'
import { Party } from '../PollieRater/helpers'
import { useAuthActions } from '../user-registration'
import {
    StyledFilterOptionLabel,
    StyledFilterOptionContainer,
    StyledFilterOptionInput,
    StyledPollieRaterFilterOptionLoading,
    StyledPollieRaterHeader,
    StyledPollieRaterLegend,
    StyledTextContainer,
    StyledFilterOptionCheck,
} from './PollieRaterFilter.styled'
import { tokens } from '@news-mono/design-tokens'

interface FilterOptionProps {
    value: string
    name: string
    isDefault?: boolean
    onChange?: (value: string) => void
}

function FilterOption({ value, isDefault, onChange }: FilterOptionProps) {
    return (
        <>
            <StyledFilterOptionInput
                type="radio"
                name="pollie-rater-filter"
                id={value}
                value={value}
                defaultChecked={isDefault}
                onChange={(event) => onChange?.(event.target.value)}
            />
            <StyledFilterOptionLabel htmlFor={value}>
                <StyledFilterOptionCheck
                    fill={tokens.thewest.colors.palette.white}
                />
                {value}
            </StyledFilterOptionLabel>
        </>
    )
}

export interface PollieRaterFilterInterface {
    parties: Array<Party>
    initialPartyName: string
    isLoggedIn: boolean
    isLoading: boolean
    onChange?: (value: string) => void
}

export const PollieRaterFilter: React.FC<PollieRaterFilterInterface> = ({
    parties,
    initialPartyName,
    isLoggedIn,
    isLoading,
    onChange,
}) => {
    return (
        <PollieRaterFilterForm isLoggedIn={isLoggedIn}>
            {isLoading
                ? // Loading state. 4 Placeholders.
                  [...Array(4)].map((_, i) => {
                      return <StyledPollieRaterFilterOptionLoading key={i} />
                  })
                : parties.map((party) => {
                      return (
                          <FilterOption
                              isDefault={party.name === initialPartyName}
                              key={party.id}
                              value={party.name}
                              name={party.name}
                              onChange={(value) => {
                                  onChange?.(value)
                              }}
                          />
                      )
                  })}
        </PollieRaterFilterForm>
    )
}

const PollieRaterFilterForm = ({
    children,
    isLoggedIn,
}: PropsWithChildren<{ isLoggedIn: boolean }>) => {
    const { onRegisterClick } = useAuthActions()

    return (
        <StyledPollieRaterHeader>
            <StyledPollieRaterLegend>Select a party</StyledPollieRaterLegend>
            <StyledTextContainer>
                <span>
                    Select a party below and rate the politicians, then see what
                    other West Aussies think of them!
                </span>
                {!isLoggedIn && (
                    <span>
                        {''} You’ll need to register for a {''}
                        <ButtonLink
                            onClick={() => onRegisterClick('pollie-rater-link')}
                        >
                            free account
                        </ButtonLink>{' '}
                        to submit your rating.
                    </span>
                )}
            </StyledTextContainer>
            <StyledFilterOptionContainer>
                {children}
            </StyledFilterOptionContainer>
        </StyledPollieRaterHeader>
    )
}
