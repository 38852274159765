import {
    GuestbookMessage,
    PaginatedContentfulMessage,
} from '@west-australian-newspapers/contentful-retrieval-utilities'
import React, { useEffect, useState } from 'react'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { Logger } from 'typescript-log'
import { GuestMessage } from './components/GuestMessage'
import {
    StyledContainer,
    StyledGuestBookErrorBlock,
    StyledGuestBookErrorButton,
    StyledGuestbookErrorContainer,
    StyledGuestBookLoadMoreButton,
    StyledSpinner,
    StyledSpinnerContainer,
    StyledWebLink,
} from './GuestBook.styled'
import { mapData, useGuestBookData } from './helpers'
import { GuestBookItem } from './types'

export interface Props {
    onEvent: (event: any) => void
    logger: Logger
    campaign: string // must match campaign string from Contentful
    introContent?: JSX.Element | null
}

// The amount of tributes to load/skip to at one time.
const LOAD_AMOUNT = 40

export const GuestBook: React.FC<Props> = (props) => {
    const { campaign, logger, introContent } = props
    const [guestBookData, setGuestBookData] = useState<Array<GuestBookItem>>()
    const [skipAmount, setSkipAmount] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [loadedAmount, setLoadedAmount] = useState(0)

    const {
        isLoading,
        isError,
        isSuccess,
        data,
        refetch,
    }: {
        isLoading: boolean
        isError: boolean
        isSuccess: boolean
        data: PaginatedContentfulMessage<GuestbookMessage> | undefined
        refetch: (
            options?: RefetchOptions | undefined,
        ) => Promise<QueryObserverResult<any, unknown>>
    } = useGuestBookData(
        campaign,
        logger,
        skipAmount.toString(),
        LOAD_AMOUNT.toString(),
    )

    // Data transformation section
    useEffect(() => {
        if (!isLoading && !isError && data) {
            const mappedData = mapData(data)
            if (mappedData) {
                setGuestBookData((prev) =>
                    prev ? [...prev, ...mappedData] : mappedData,
                )
            }
            setTotalAmount(data.total)
            setLoadedAmount((prev) => prev + data.items.length)
        }
    }, [data, isError, isLoading])

    const onLoadMore = async () => {
        setSkipAmount((prev) => prev + LOAD_AMOUNT)
    }

    // This is the static portion that we can display even while we are fetching data
    const staticPortion = introContent && <div>{introContent}</div>

    if (isLoading) {
        return (
            <React.Fragment>
                {staticPortion}
                <StyledContainer>
                    <StyledSpinnerContainer>
                        <StyledSpinner />
                        <span>Loading tributes...</span>
                    </StyledSpinnerContainer>
                </StyledContainer>
            </React.Fragment>
        )
    }

    if (!isLoading && isError) {
        return (
            <StyledGuestbookErrorContainer>
                <StyledGuestBookErrorBlock>
                    <h2>Failed to load content</h2>
                    <span>
                        Click the button to try again. If the error continues,{' '}
                        <StyledWebLink to={'/contact'}>
                            contact support
                        </StyledWebLink>
                    </span>
                    <StyledGuestBookErrorButton onClick={() => refetch()}>
                        Reload content
                    </StyledGuestBookErrorButton>
                </StyledGuestBookErrorBlock>
            </StyledGuestbookErrorContainer>
        )
    }

    if (!isLoading && isSuccess) {
        return (
            <React.Fragment>
                <div>
                    {staticPortion}
                    {guestBookData &&
                        guestBookData.map((guestbook, key) => {
                            return (
                                <GuestMessage
                                    message={
                                        <React.Fragment>
                                            <p>{guestbook.message}</p>
                                        </React.Fragment>
                                    }
                                    author={guestbook.author}
                                    key={key}
                                />
                            )
                        })}
                </div>
                {loadedAmount < totalAmount && (
                    <StyledGuestBookLoadMoreButton
                        isLoading={isLoading}
                        onClick={() => onLoadMore()}
                    />
                )}
            </React.Fragment>
        )
    }

    return <></>
}
