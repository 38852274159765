import React, { ReactNode } from 'react'
import { FIFTY_OFF_EVERYDAY_DIGITAL } from '../../user-registration/breach/common'
import { StyledTextLink } from '../../user-registration/breach/components/BreachWallContent/BreachWallContent.styled'
import {
    BottomTextWrap,
    InfoText,
    InfoTextLink,
} from '../../user-registration/breach/components/PuzzlesBreach/PuzzlesBreachwall.styled'
import { FootySwgCheckMark } from '../RegionalsSwgPromoPage/Components/FootySwgCheckMark'
import {
    StyledFootyBenefitList,
    StyledFootyBenefitListItem,
    StyledFootyBenefitListText,
} from '../RegionalsSwgPromoPage/RegionalsSwgPromoPage.styled'
import { SwgCampaignOfferValues } from './CampaignOffer'

export const getFooty2025OfferSwgCampaignValues =
    (): SwgCampaignOfferValues => {
        return {
            isDefault: false,
            creative: 'TW_Subscribe_With_Google_Campaign',
            deal: 'swg-promo',
            type: 'Premium - Inline',
            sku: 'everyday_digital_footy_pack',
            price: {
                weekly: FIFTY_OFF_EVERYDAY_DIGITAL,
                originalPrice: '$8 per week',
                promoPrice: '$4/week',
                priceTerms: '*for 8 weeks',
                billingTerms: 'Billed every four weeks',
            },
            BenefitListComponent: (
                <>
                    <h3>What's included with your subscription?</h3>
                    <StyledFootyBenefitList>
                        <FootyBenefitListItem>
                            In-depth coverage of sport, business, politics,
                            entertainment and more
                        </FootyBenefitListItem>
                        <FootyBenefitListItem>
                            Access to the daily digital edition of the
                            newspaper, plus all features and lift outs
                        </FootyBenefitListItem>
                        <FootyBenefitListItem>
                            Download{' '}
                            <StyledTextLink
                                reverseColor={false}
                                href={`https://apps.apple.com/au/app/the-west-australian/id1198233691`}
                            >
                                The West App&nbsp;
                            </StyledTextLink>
                            to receive news alerts straight to your device
                        </FootyBenefitListItem>
                        <FootyBenefitListItem>
                            A membership to West Rewards, our subscriber rewards
                            program offering access to exclusive competitions
                            and experiences
                        </FootyBenefitListItem>
                    </StyledFootyBenefitList>
                </>
            ),
            InfoComponent: (onAllPackagesClicked) => (
                <BottomTextWrap>
                    <InfoText>
                        Looking for a different subscription?{' '}
                        <InfoTextLink href={'#'} onEvent={onAllPackagesClicked}>
                            Browse all packages
                        </InfoTextLink>{' '}
                    </InfoText>
                    <br />
                    <InfoText>
                        Subscribe today with Google. Now available and making it
                        easier to access breaking news. Need help? Call us at{' '}
                        <InfoTextLink href={'tel:1800 811 855'}>
                            1800 811 855
                        </InfoTextLink>
                    </InfoText>
                </BottomTextWrap>
            ),
            TermsAndConditionsComponent: null,
            image: {
                x1: require('../RegionalsSwgPromoPage/assets/The-West-Subscription-footy-icon.png'),
                x2: require('../RegionalsSwgPromoPage/assets/The-West-Subscription-footy-icon.png'),
            },
        }
    }

const FootyBenefitListItem = ({ children }: { children?: ReactNode }) => {
    return (
        <StyledFootyBenefitListItem>
            <FootySwgCheckMark />
            <StyledFootyBenefitListText>{children}</StyledFootyBenefitListText>
        </StyledFootyBenefitListItem>
    )
}
