import styled from '@emotion/styled'
import { withClass } from '@news-mono/web-common'
import {
    ContainerWidth,
    metrics,
    ThemeMargins,
} from '../../__styling/settings/metrics'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { SidebarOptions } from './Athena'

export interface AthenaProps {
    sidebarOptions: SidebarOptions
    verticalSpacing?: keyof ThemeMargins
    containerWidth?: ContainerWidth
    hideSidebar: boolean
    hasVerticalPadding?: boolean
    hasHorizontalPadding?: boolean
    sidebarSide: 'right' | 'left'
}

let sidebarWidth = 300
let gridGapSize = calcRem(21)

export const StyledAthena = styled('div')<AthenaProps>(
    ({
        theme,
        verticalSpacing,
        containerWidth,
        sidebarOptions,
        hideSidebar,
        hasVerticalPadding = true,
        hasHorizontalPadding = true,
        sidebarSide,
    }) => {
        //DTEC-426 - remove sidebar on app rendition of puzzles
        if (hideSidebar) {
            sidebarWidth = 0
            gridGapSize = '0'
        } else {
            sidebarWidth = 300
            gridGapSize = calcRem(21)
        }

        return {
            margin: '0 auto',
            width: '100%',
            padding: calcRem(
                hasVerticalPadding ? theme.margins.outerMargin : 0,
                hasHorizontalPadding ? theme.margins.outerMargin : 0,
            ),
            marginBottom:
                verticalSpacing &&
                calcRem(metrics[theme.kind].margins[verticalSpacing]),
            maxWidth: containerWidth,
            display: 'grid',
            gridTemplateColumns: '100%',
            gridGap: sidebarOptions === 'visibleMobile' ? 0 : gridGapSize,

            [breakpoint(sidebarOptions === 'visibleMobile' ? 'sm' : 'md')]: {
                // prettier-ignore
                gridTemplateColumns: sidebarSide === 'right' ? `minmax(0,1fr) ${calcRem(sidebarWidth)}`:`${calcRem(sidebarWidth)} minmax(0,1fr)`,
                gridGap: gridGapSize,
            },
        }
    },
)

export const StyledMain = styled('div')<{ hasHorizontalGutters: boolean }>(
    ({ hasHorizontalGutters }) => ({
        [breakpoint('md')]: {
            padding: hasHorizontalGutters
                ? calcRem(0, metrics.thewest.margins.lg)
                : 0,
        },
    }),
)

export const StyledMainArticle = StyledMain.withComponent('article')

export interface SidebarProps {
    sidebarOptions: SidebarOptions
    maxHeight?: number | undefined
}

// prettier-ignore
export const StyledSidebar = withClass('hide-print')(styled('aside')<SidebarProps>(({ sidebarOptions, theme, maxHeight }) =>
    ({
        display: sidebarOptions === 'visibleMobile' ? 'block' : 'none',
        maxHeight: maxHeight ?? undefined,

        [breakpoint(sidebarOptions === 'visibleMobile' ? 'sm' : 'md')]: {
            boxSizing: 'content-box',
            display: 'block'
        },
    })
))
