import styled from '@emotion/styled'
import { tokens } from '@news-mono/design-tokens'
import { placeholderAnimation } from '../widgets/election/components/placeholderAnimation'
import { breakpoint, calcRem } from '../__styling'

export const StyledPollieRaterListingContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(24),

    width: '100%',
})

export const StyledPollieRaterContainer = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '140px minmax(0, 1fr)',
    gridTemplateRows: 'auto',
    gridRowGap: calcRem(16),

    [breakpoint('sm')]: {
        display: 'flex',
    },

    '& h2': {
        fontSize: calcRem(22),
        margin: 0,
    },

    '& h3': {
        fontSize: calcRem(14),
        margin: 0,
        fontFamily: theme.fonts.sansSerif,
        fontWeight: 500,
    },
}))

export const StyledPollieRaterDivider = styled.div({
    width: '100%',
    height: calcRem(1),
    backgroundColor: '#D0D0D0',
})

export const StyledPollieImage = styled('img')({
    marginRight: calcRem(24),
    flex: '0 0 120px',
    gridRow: 1,
    gridColumn: 1,
    width: calcRem(120),
    height: calcRem(120),
    overflow: 'hidden',
    objectFit: 'cover',
    borderRadius: '50%',
})

export const StyledPollieTextContainer = styled('div')({
    gridColumn: '1/3',
    width: '100%',
})

export const StyledRatingsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    flex: '1 0 auto',
    gridRow: 1,
    gridColumn: 2,
    paddingTop: calcRem(6),

    '& > div': {
        flex: 1,
        lineHeight: 1,

        [breakpoint('sm')]: {
            flex: 'unset',
        },
    },

    '& > div:first-of-type': {
        paddingRight: calcRem(8),
    },

    [breakpoint('sm')]: {
        borderLeft: `1px solid ${tokens.thewest.colors.palette.greyQuokka}`,
        marginLeft: calcRem(32),
        paddingLeft: calcRem(32),
        paddingRight: calcRem(32 - 8),
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'unset',
    },
}))

export const StyledRatingScore = styled('div')(({ theme }) => ({
    fontSize: calcRem(26),
    lineHeight: calcRem(32),
    fontFamily: theme.fonts.sansSerifCond,

    '& em': {
        color: theme.colors.brand,
        fontSize: calcRem(48),
        fontStyle: 'normal',
    },
}))

export const StyledRatingLabel = styled('span')(({ theme }) => ({
    fontSize: calcRem(13),
    fontFamily: theme.fonts.sansSerifCond,
    fontWeight: 500,
}))

export const StyledPolliDescription = styled('p')({
    whiteSpace: 'pre-line',
})

export const StyledPoliRaterErrorContainer = styled('div')(({ theme }) => ({
    background: tokens.thewest.colors.palette.greyMouse,
}))

export const StyledErrorBlock = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: calcRem(32),
    marginTop: calcRem(80),
    marginBottom: calcRem(80),

    [breakpoint('sm')]: {
        marginLeft: calcRem(64),
    },
})

export const StyledTextBlock = styled('div')({
    marginBottom: calcRem(48),
})

export const StyledErrorText = styled('span')(({ theme }) => ({
    fontSize: calcRem(22),
    fontWeight: 700,
    fontFamily: theme.fonts.serif,
    lineHeight: calcRem(24),
}))

export const StyledErrorButton = styled('button')(({ theme }) => ({
    lineHeight: calcRem(16),
    fontWeight: 700,
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(13),
    textAlign: 'center',
    backgroundColor: theme.colors.actions.button.primary.background.default,
    color: theme.colors.actions.button.primary.text.default,
    padding: calcRem(8, 23.5, 8, 23.5),
    maxWidth: calcRem(88),
    borderRadius: calcRem(2),
    border: 'none',
}))

export const StyledPollieRaterLoadingContainer = styled.div(({ theme }) => {
    return [
        {
            height: 281,
        },
        placeholderAnimation,
    ]
})
