import {
    ContentDataDefinitionLoader,
    createRegisterableComponentWithData,
    FixedRatio,
} from '@news-mono/web-common'
import React from 'react'
import {
    HeroVideoLayoutRatios,
    VideoPlaybackRestrictionTypes,
} from '../../cards/HeroVideoCard/HeroVideoCard'
import { CardOrientationOptions } from '../../cards/Portrait/Portrait'
import {
    calculateExpectedCardCountFromContentData,
    CollectionRouteProps,
} from '../../collections/collections-props.routing'
import { Sierra, SierraLayouts } from '../../collections/Sierra/Sierra'
import { ResponsiveContainer } from '../../content/Picture/responsive'
import { ResponsivePictureLayout } from '../../content/Picture/ResponsivePictureLayouts'

export interface SierraRouteProps
    extends CollectionRouteProps,
        ResponsiveContainer {
    disableImageLazyLoad?: boolean
    fixedRatios?: FixedRatio[]
    cardOrientation?: CardOrientationOptions
    cardLayout: SierraLayouts
    heroImageLayout?: ResponsivePictureLayout
    heroVideoRatio?: HeroVideoLayoutRatios
    restrictedVideoPlaybackMode?: VideoPlaybackRestrictionTypes
    overrideRestrictedVideoPlayback?: boolean
    hideByline?: boolean
    hasHeroBorder?: boolean
    hasPortraitBorder?: boolean
    hasCollectionBorderBottom?: boolean
    isLarge?: boolean
    heroBottomPadding?: number
    gridGap?: number
}

export const SierraRegistration = createRegisterableComponentWithData(
    'sierra',
    ContentDataDefinitionLoader,
    (props: SierraRouteProps, data, services) => {
        return (
            <Sierra
                items={
                    data.loaded
                        ? { loaded: true, result: data.result.publications }
                        : { loaded: false }
                }
                expectedCards={calculateExpectedCardCountFromContentData(
                    data.dataDefinitionArgs,
                )}
                sectionHeader={props.sectionHeader}
                verticalSpacing={props.verticalSpacing}
                disableImageLazyLoad={props.disableImageLazyLoad}
                cardOrientation={props.cardOrientation}
                onEvent={services.onEvent}
                cardLayout={props.cardLayout}
                heroImageLayout={props.heroImageLayout}
                cardServices={services}
                heroVideoOptions={{
                    heroVideoRatio: props.heroVideoRatio,
                }}
                restrictedVideoPlaybackMode={props.restrictedVideoPlaybackMode}
                containerWidthRatios={props.containerWidthRatios}
                overrideRestrictedVideoPlayback={
                    props.overrideRestrictedVideoPlayback
                }
                hasHeroBorder={props.hasHeroBorder}
                hideByline={props.hideByline}
                hasCollectionBorderBottom={props.hasCollectionBorderBottom}
                isLarge={props.isLarge}
                heroBottomPadding={props.heroBottomPadding}
                gridGap={props.gridGap}
            />
        )
    },
)
