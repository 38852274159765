import {
    AllEvents,
    AppState,
    ConfigurationContext,
    ElectionDefinition,
} from '@news-mono/web-common'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { PollieRaterFilter } from '../PollieRaterFilter'
import { PollieRaterListing } from '../PollieRaterListing'
import {
    getParties,
    getPoliticians,
    mapScoresToPoliticians,
    useGetUserPollData,
    usePollieRaterData,
} from './helpers'

export interface PollieRaterProps {
    onEvent: (event: AllEvents) => void
    electionDefinition: ElectionDefinition
}

export const PollieRater: React.FC<PollieRaterProps> = ({
    onEvent,
    electionDefinition,
}: PollieRaterProps) => {
    const { contentfulStaticSiteEndpoint, userPollServiceEndpoint } =
        useContext(ConfigurationContext)

    const { isLoading, isError, data, refetch } = usePollieRaterData(
        contentfulStaticSiteEndpoint,
    )
    const { data: pollScores, refetch: refetchUserPollData } =
        useGetUserPollData(
            userPollServiceEndpoint,
            electionDefinition.electionId,
        )

    const { isLoggedIn, isLoading: authLoading } = useSelector(
        (state: AppState) => state.authentication,
    )

    const [currentPartyName, setCurrentPartyName] = useState<string>()

    // Memoize parties once loaded.
    const parties = useMemo(() => {
        if (isLoading || isError || !data) return []

        return getParties(data)
    }, [data, isError, isLoading])

    // Set initial party once loaded.
    useEffect(() => {
        if (parties?.[0]) {
            setCurrentPartyName((currentPartyName) => {
                // If not undefined, don't bother setting.
                return currentPartyName ?? parties[0].name
            })
        }
    }, [parties])

    // Memoize sorted politicians once loaded.
    const sortedPoliticians = useMemo(() => {
        if (!parties || !data) return []

        const currentParty = parties.find(
            (party) => party.name === currentPartyName,
        )

        if (!currentParty) return []

        const politicians = getPoliticians(currentParty, data)

        if (!politicians) return []

        return mapScoresToPoliticians(politicians, pollScores)
    }, [data, parties, currentPartyName, pollScores])

    return (
        <React.Fragment>
            <PollieRaterFilter
                parties={parties}
                initialPartyName={parties[0]?.name ?? ''}
                onChange={setCurrentPartyName}
                isLoggedIn={isLoggedIn}
                isLoading={isLoading || authLoading}
            />
            <PollieRaterListing
                isError={isError}
                isLoading={isLoading || authLoading}
                politicians={sortedPoliticians}
                refetch={refetch}
                contentfulEndpoint={contentfulStaticSiteEndpoint}
                refetchPollRating={refetchUserPollData}
                onEvent={onEvent}
                electionId={electionDefinition.electionId}
                isLoggedIn={isLoggedIn}
            />
        </React.Fragment>
    )
}
export default PollieRater
